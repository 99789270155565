import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api/services/api.service';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { environment } from 'src/environments/environment';

export type Mode = 'add' | 'edit' | 'delete';

export interface DialogData {
  mode: Mode;
  email?: string;
}

@Component({
    selector: 'app-user-reset-password',
    templateUrl: './user-reset-password.component.html',
    styleUrls: ['./user-reset-password.component.scss'],
    standalone: true,
    imports: [
        ModalLayoutComponent,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        SnackbarComponent
    ],
})
export class UserResetPasswordComponent implements OnInit {
  public form: UntypedFormGroup = Object.create(null);
 disableFlag:boolean = false;
  mode: Mode;
  constructor(
    private fb: UntypedFormBuilder,
    private _api: ApiService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UserResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.mode = data.mode || 'add';
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [{value:null , disabled : true}, Validators.compose([Validators.required, Validators.email])],
    });    
    // this.form.controls['email'].setValue(this.data.email);
    this.form.patchValue(this.data);

  }

  onSubmit() {
    const rawValue = this.form.getRawValue();
    if(rawValue.email !== this.data.email ){
      this.form.markAllAsTouched()
      return ;
    } 
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
           this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else if (validationErrors.error) {
        const control = this.form.get('email');
        if (control) {
          this.form.markAllAsTouched();
          control.setErrors({
            message: validationErrors?.error.user[0],
          });
        }
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${validationErrors?.error.user[0]}` }})
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
      }

      return of(null);
    };
     
      const resetPasswordUrl = window.origin + '/reset';
      const formValues ={
        email:rawValue?.email,
        resetPasswordUrl:resetPasswordUrl,
        reCaptchaToken: environment.recaptchaSiteKey,
        app: 'LMS'
      }
      this._api.auth
        .forgotPassword(formValues)
        .pipe(catchError((error) => throwError(error)))
        .subscribe(
          () => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: `Email Sent Successfully` }})
            this.dialogRef.close();
            this.disableFlag = false;
          },
          (error) => {
            this.disableFlag = false;
            validationErrorHandler(error);
          }
        );
  
  }
}
