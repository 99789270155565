import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/api/services/payment/payment.service';
import { map, take } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ModalLayoutComponent } from '../../modals/modal-layout/modal-layout.component';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { JsonReaderService } from 'src/app/shared/services/json-reader.service';
import { 
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
    selector: 'app-annual-plan',
    templateUrl: './annual-plan.component.html',
    styleUrls: ['./annual-plan.component.scss'],
    standalone: true,
    imports: [
        ModalLayoutComponent,
        NgIf,
        MatExpansionModule,
        NgFor,
        MatIconModule,
        NgClass,
        MatLegacySelectModule,
        MatLegacyFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
    ],
})
export class AnnualPlanComponent implements OnInit {
  pricingPlans$ = this.paymentService.getPricingPlans();
  planList: any = null;
  form: UntypedFormGroup = Object.create(null);
  selectedPlan: any;
  entityGuid: any = window.localStorage.getItem('entityGuid');
  maxUserPlanAmount = 1000;
  singleCertPrice!: number;
  buttonIds!: any;

  constructor(
    private fb: UntypedFormBuilder,
    private paymentService: PaymentService,
    private changeDetectorRef: ChangeDetectorRef,
    private jsonReaderService: JsonReaderService,
  ) {
    this.jsonReaderService.getJSON().subscribe((data: any) => {
      this.buttonIds = data;
    });
  }

  ngOnInit(): void {

    this.paymentService.getCertPrice().subscribe((price: any) => {
      this.singleCertPrice = price;
    });

    this.pricingPlans$
      .pipe(
        map((res: any) => {
          return res.map((plan: any) => {
            plan.selected = plan.isFeatured;
            return plan;
          });
        })
      )
      .subscribe((res: any) => {
        this.planList = res;
        this.selectedPlan = res.find((plan: any) => plan.selected === true);
         this.form.patchValue(this.selectedPlan)
        this.maxUserPlanAmount = res[res.length - 1].numberOfSeats;
      });


      this.form = this.fb.group(
        {
          productId: this.fb.control(null),
        }
      );
  }

  selectPlanMobile(event: any) {
    this.selectedPlan = this.planList.find((plan: any) => plan.priceId === event);
      this.planList.forEach((p: any) => {
        p.selected = false;
      });

       this.paymentService.postCreateSession(this.selectedPlan.priceId, this.entityGuid).subscribe((res: any) => {
        window.location.href = res;
      });
  }


  selectPlan(event: any) {
    this.selectedPlan = this.planList.find((plan: any) => plan.productId === event.value);
      this.planList.forEach((p: any) => {
        p.selected = false;
      });
      this.selectedPlan.selected = true;
  }

  getPlanClicked() {

    const productId = this.form.get('productId')?.value;
    let product = this.planList.find((plan: any) => plan.productId === productId);
    this.paymentService.postCreateSession(product.priceId, this.entityGuid).subscribe((res: any) => {
      window.location.href = res;
    });
  }

  getBuyNowButtonId() {
    if (!this.buttonIds) {
      return '';
    }
    return this.buttonIds.subscribeAnnualPlan;
  }
}

