import { Component, OnInit } from '@angular/core';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-org-admin-banner',
    templateUrl: './org-admin-banner.component.html',
    styleUrls: ['./org-admin-banner.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class OrgAdminBannerComponent implements OnInit {
  displayBanner = false;
  title = '';
  message = '';
  buttonText = '';
  buttonAction = '';
  linkText = '';
  link = '';
  hasSubscription = false;
  disableUpgradeButton = false;

  constructor(
    private subscriptionQuery: SubscriptionQuery,
    private modalService: ModalService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscriptionQuery.select().subscribe((subscription) => {
      if (subscription?.usage) {
        this.getUsageDetails(subscription?.usage);
      }
    });
  }

  getUsageDetails(usage: any) {
    this.displayBanner = true;

    // In trial period and no purchases.
    if (!usage.isTrialOver && usage.certificateQuota == 0 && usage.numberOfSeats == 0) {
      this.title = 'Your Organization Free Trial';
      this.message = `Welcome to your organization dashboard. Explore platform features and launch your training program during your free trial period - ${usage.remainingTrialDays} days remaining!`;
      this.buttonText = 'Choose Plan';
      this.buttonAction = 'pricingModal'
    }

    if (usage.requiresUpgrade) {
      this.title = 'Upgrade Your Plan';
      
      // Enterprise requires upgrade
      if (usage.isEnterprise) {
        this.message = 'You have reached your account limit. Archive users to continue in your existing plan or contact our Enterprise Support team.';
        this.buttonText = 'Contact Us';
        this.buttonAction = 'contactUs'
      }

      // SeatBased plan requires upgrade.
      else if (usage.planType === 'SeatBased') {
        this.message = 'You have reached your account limit. Archive users to continue in your existing plan or convert to a certificate-based plan. Need help? ';
        this.buttonText = 'Manage Account';
        this.buttonAction = 'accountAndBilling'
        this.linkText = 'Get support.';
        this.link = 'https://mandatedreportertraining.zendesk.com/hc/en-us/requests/new?ticket_form_id=7189511412123';
      }

      // Subscription/PAYG/Hybrid plan requires upgrade.
      else {
        this.message = 'Select an Annual Plan or Pay-As-You-Go credits to issue more certificates and maintain access to your premium account features.';
        this.buttonText = 'Manage Account';
        this.buttonAction = 'accountAndBilling'
      }
    }

    else {
      this.displayBanner = false;
    }
  }

  
  buttonClicked() {
    switch (this.buttonAction) {
      case 'pricingModal':
        this.pricingModal();
        break;
      case 'accountAndBilling':
        this.accountAndBilling();
        break;
      case 'contactUs':
        this.contactUs();
        break;
    }
  }

  pricingModal() {
    this.modalService.openPricingModal(this.hasSubscription);
  }

  accountAndBilling() {
    this.router.navigate(['/organization/account-billing']);
  }

  contactUs() {
    window.location.href = 'https://mandatedreportertraining.com/lets-talk/';
  }
}