import { components } from "src/app/api/interfaces/swagger-types";
export type EntityAdminDashboard = components['schemas']['EntityAdminDashboardViewModel'];
export type LearnerInviteAcceptRatio = components['schemas']['LearnerInviteAcceptRatio'];
export type LearnerProgressCertificates = components['schemas']['LearnerProgressCertificates'];
export interface DashboardState {
  entityAdminDashboard: EntityAdminDashboard;
  learnerInviteAcceptRatio: LearnerInviteAcceptRatio;
  learnerProgressCertificates: LearnerProgressCertificates;
}

export function createEntityAdminDashboard(): DashboardState {
  return {
    entityAdminDashboard: {} as EntityAdminDashboard,
    learnerInviteAcceptRatio: {} as LearnerInviteAcceptRatio,
    learnerProgressCertificates: {} as LearnerProgressCertificates
  } as DashboardState;
}
