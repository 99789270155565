import { inject, Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { HttpClient } from '@angular/common/http';
import { NewFeatureStore } from './new-feature.store';
import { tap } from 'rxjs';
import { components } from '../../interfaces/swagger-types';

export type NewFeatureRes = components['schemas']['NewFeatureViewModel'];

@Injectable({
  providedIn: 'root',
})

export class NewFeatureService extends BaseAPI {
  newFeatureStore = inject(NewFeatureStore);
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  getNewFeatures() {
    this.newFeatureStore.setLoading(true);
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.get<NewFeatureRes>(`/NewFeature/${entityGuid}`).pipe(
      tap((newFeature) => {
        if (newFeature)
         this.newFeatureStore.setAll(newFeature.features ?? []);
        this.newFeatureStore.setLoading(false);
      }),
    );
  }
}
