import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NewFeatureStore, NewFeatureState } from './newFeature.store';

@Injectable({ providedIn: 'root' })
export class NewFeatureQuery extends QueryEntity<NewFeatureState> {

  constructor(
    protected store: NewFeatureStore,
  ) {
    super(store);
  }
}
