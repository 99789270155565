import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../../../routes/authentication/state/auth.service';
import { AuthApiService } from '../../../../api/services/auth/auth-api.service';
import { SwitchEntityProfileQuery } from './state/switch-entity-profile.query';
import { SwitchEntityProfile } from './state/switch-entity-profile.model';
import { SwitchEntityProfileService } from './state/switch-entity-profile.service';
import { Router, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgClass, NgIf, AsyncPipe } from '@angular/common';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { TruncateTextPipe } from 'src/app/api/pipes/truncate-text/truncate-text.pipe';

export interface User {
  firstName: string;
  lastName: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        NgClass,
        NgIf,
        MatIconModule,
        RouterLink,
        AsyncPipe,
        TruncateTextPipe
    ],
})
export class AppHeaderComponent implements OnInit {
  orgEntities: any[] = [];
  fontSize = 16;
  orgList: any = [];
  @Output() eventEmitter = new EventEmitter();

  constructor(
    public authService: AuthService,
    public auth: AuthApiService,
    public switchEntityProfileQuery: SwitchEntityProfileQuery,
    private switchEntityProfileService: SwitchEntityProfileService,
    private router: Router,
    public organizationService: OrganizationService,
  ) {}

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
  }

  switchEntityPro(org: SwitchEntityProfile) {
    this.switchEntityProfileService.updateSwitchOrganization(org.entityGuid);
    this.closeHeader();

    if (this.isEntityAdmin(org)) {
      this.router.navigateByUrl('/dashboard');
    } else if (this.isCoopAdmin(org)) {
      this.router.navigateByUrl('/coop/dashboard');
    } else {
      this.router.navigateByUrl('/training');
    }
  }

  isEntityAdmin(org: any): boolean {
    return org?.roles?.includes('EntityAdmin');
  }

  isCoopAdmin(org: any): boolean {
    return org?.roles?.includes('CoopAdmin');
  }

  closeHeader() {
    this.eventEmitter.emit();
  }

  getOrgList() {
    var orgList = this.switchEntityProfileQuery.getAll();
    return orgList.filter((org) =>
      (this.isCoopAdmin(org) || org.entityType !== 'Coop'));
  }
}
