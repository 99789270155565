<h1 class="learner pl-4 pt-4">Organization Profile</h1>
<div class="content-container">
  <ng-container *ngIf="switchEntityProfileQuery.entityType$ | async as entityType">
    <form [formGroup]="form" (ngSubmit)="update()">
      <mat-tab-group>
        <mat-tab label="Information">
          <div class="flex flex-wrap justify-between">
            <div class="w-full  pr-0 sm:pr-5 mt-4">
              <h4 class="mb-2 file-placeholder">Profile Image</h4>
              <div class="flex sm:flex-row flex-col w-full mb-2">
                <div class="flex">
                  <div class="pr-4">
                    <img *ngIf="showProfileImagePic; else other_image" [src]="localImageUrl" class="org_image"
                      alt="{{ form?.value?.name }}" />
                    <ng-template #other_image>
                      <img src="assets/images/profile/organization-icon.svg" class="org_image"
                        alt="{{ form?.value?.name }}" />
                    </ng-template>
                  </div>
                  <div>
                    <p>Upload your photo<br>Photos should be at least 300px x 300px</p>
                    <div class="mt-4">
                      <label for="photos" class="mb-1">
                        <input type="file" hidden class="text-black" id="photos"
                          accept="image/png, image/jpeg, image/jpg, image/jfif, image/pjpeg, image/pjp" ng2FileSelect
                          [uploader]="photoUploader" #selectedPicture />
                        <div class="flex items-center">
                          <div class="input-file-btn btn-primary-outlined small text-center mb-1">Browse</div>
                          <div class="lds-ring" *ngIf="loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                        <mat-error *ngIf="requiredImage"> Required </mat-error>
                      </label>
                      <div *ngIf="failImageFlag" class="{{ failImageFlag ? 'mb-4' : '' }}">
                        <mat-error class="image-error">
                          We accept only jpg, png, jpeg, jfif, pjpeg, pjp files and file size must be less than
                          1mb</mat-error>
                      </div>
                      <div *ngIf="failSizeFlag" class="{{ failSizeFlag ? 'mb-4' : '' }}">
                        <mat-error class="image-error"> file size must be less than 1mb </mat-error>
                      </div>
                      <div *ngIf="failFlag" class="{{ failFlag ? 'mb-4' : '' }}">
                        <mat-error class="image-error"> We accept only jpg, png, jpeg, jfif, pjpeg, pjp files
                        </mat-error>
                      </div>
                      <div *ngFor="let item of photoUploader.queue">
                        <div class="mb-1 w-full flex flex-wrap gap-1 items-center">
                          <p class="mb-0">
                            {{ item?.file?.name }}
                          </p>
                          <mat-icon class="cursor-pointer text-secondary"
                            (click)="item.remove(); clearSelectedPicture()">delete</mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full pr-0 sm:pr-5 mt-16">
              <h4 class="mb-2 file-placeholder">Basic Information</h4>
            </div>
            <div class="w-full sm:w-1/2 pr-0 sm:pr-5 ">
              <mat-form-field class="w-full">
                <mat-label>Organization Name</mat-label>
                <input type="text" matInput formControlName="name" />
                <mat-error *ngIf="form.controls['name'].errors?.required">Required</mat-error>
                <mat-error *ngIf="form.controls['name'].hasError('whiteSpace') && form.controls['name'].touched">Must
                  constain at least one alphanumeric character</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full sm:w-1/2" *ngIf="!isIndividual">
              <mat-form-field class="w-full">
                <mat-label>Website</mat-label>
                <input type="text" matInput formControlName="website" />
                <mat-error *ngIf="form.controls['website'].errors?.pattern">Please enter valid Website URL </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="flex flex-wrap justify-between mb-3">
            <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
              <mat-form-field class="w-full">
                <mat-label>Industry</mat-label>
                <mat-select formControlName="industryId" panelClass="mat-select-mt3">
                  <mat-option *ngFor="let industry of industries$ | async" [value]="industry.value">
                    {{ industry.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="form.controls['industryId'].hasError('required') && form.controls['industryId'].touched">Required
                </mat-error>
                <mat-error *ngIf="form.controls['industryId'].errors?.message">{{
                  form.controls['industryId'].getError('message')
                  }}</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full sm:w-1/2">
              <mat-form-field class="w-full">
                <mat-label>State</mat-label>
                <mat-select formControlName="stateId" panelClass="mat-select-mt3">
                  <mat-option *ngFor="let state of states$ | async" [value]="state.key">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="form.controls['stateId'].hasError('required') && form.controls['stateId'].touched">Required
                </mat-error>
                <mat-error *ngIf="form.controls['stateId'].errors?.message">{{
                  form.controls['stateId'].getError('message')
                  }}</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full sm:w-1/2 pr-0 sm:pr-5" *ngIf="!isIndividual">
              <mat-form-field class="w-full">
                <mat-label>HR Contact</mat-label>
                <input type="text" matInput formControlName="hrContact" />
                <mat-error
                  *ngIf="form.controls['hrContact'].hasError('required') && form.controls['hrContact'].touched">Required
                </mat-error>
                <mat-error *ngIf="form.controls['hrContact'].errors?.message">{{
                  form.controls['hrContact'].getError('message')
                  }}</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full sm:w-1/2 pr-0 " *ngIf="!isIndividual">
              <mat-form-field class="w-full">
                <mat-label>HR Email</mat-label>
                <input type="text" matInput formControlName="hrEmail" />
                <mat-error
                  *ngIf="form.controls['hrEmail'].hasError('required') && form.controls['hrEmail'].touched">Required
                </mat-error>
                <mat-error *ngIf="form.controls['hrEmail'].errors?.email && form.controls['hrEmail'].touched">You must
                  include a valid email.
                </mat-error>
                <mat-error *ngIf="form.controls['hrEmail'].errors?.message">{{
                  form.controls['hrEmail'].getError('message')
                  }}</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full sm:w-1/2 pr-0 sm:pr-5" *ngIf="!isIndividual">
              <mat-form-field class="w-full">
                <mat-label>HR Phone</mat-label>
                <input type="text" mask="(000) 000-0000" matInput formControlName="hrPhone" />
                <mat-error
                  *ngIf="form.controls['hrPhone'].hasError('required') && form.controls['hrPhone'].touched">Required
                </mat-error>
                <mat-error *ngIf="form.controls['hrPhone'].errors?.message">{{
                  form.controls['hrPhone'].getError('message')
                  }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Settings">
          <div>
            <div class="mt-8 w-full">
              <h4 class="file-placeholder">Multi-Factor Authentication</h4>
              <p>Use multi-factor authentication (MFA) to add an extra layer of security to your organization's account. By
                turning on this setting, all admins on your account will be required to setup two-step verification via
                authenticator app.</p>
              <div class="mt-2">
                <mat-radio-group formControlName="multiFactorEnabled">
                  <mat-radio-button [value]="false">Off</mat-radio-button>
                  <mat-radio-button [value]="true">On (Recommended)</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="mt-6 w-full">
              <h4 class="file-placeholder">Auto Assign Training</h4>
              <p>Auto assign training to your users. By turning on this setting, training(s) assigned to the user’s group will be auto added to their dashboard when accepting the invitation to join your organization.</p>
              <div class="mt-2">
                <mat-radio-group formControlName="autoAssignCourses">
                  <mat-radio-button [value]="false">Off</mat-radio-button>
                  <mat-radio-button [value]="true">On (Recommended)</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="mt-4 w-full">
              <mat-form-field class="w-full lg:w-1/2">
                <mat-label>Due Date</mat-label>
                <mat-select formControlName="dueDate" panelClass="mat-select-mt3">
                  <mat-option value="none">
                    None
                  </mat-option>
                  <mat-option value="week">
                    1 Week
                  </mat-option>
                  <mat-option value="month">
                    1 Month
                  </mat-option>
                  <mat-option value="year">
                    1 Year
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="form.controls['dueDate'].hasError('required') && form.controls['dueDate'].touched">Required
                </mat-error>
                <mat-error *ngIf="form.controls['dueDate'].errors?.message">{{
                  form.controls['dueDate'].getError('message')
                  }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="mt-8">
        <button type="submit" class="btn-primary medium custom-btn" [disabled]="disableFlag">
          Update Profile
        </button>
      </div>
    </form>
  </ng-container>
</div>