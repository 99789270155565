import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { pluck, tap } from 'rxjs/operators';
import { ApiService } from '../api.service';
export interface LookupResult {
  value: any | undefined;
  name: string | null | undefined;
}

export interface LookupsState {
  industries: LookupResult[];
  professions: LookupResult[];
  jobTitles: LookupResult[];
  trainings: LookupResult[];
  states: LookupResult[];
  groups: LookupResult[];
  status: LookupResult[];
  roles: LookupResult[];
  courses: LookupResult[];
  addedCourses: LookupResult[];
  entityUserStatus: LookupResult[];
  entityGroupStatus: LookupResult[];
  userInvitesStatus: LookupResult[];
  orgRoles: LookupResult[];
  batchProcessStatus: LookupResult[];
  languages: LookupResult[];
  privacyRequestTypes: LookupResult[];
  learnerEmails: LookupResult[];
}

export function createInitialState(): LookupsState {
  return {
    industries: [],
    professions: [],
    jobTitles: [],
    trainings: [],
    states: [],
    groups: [],
    status: [],
    roles: [],
    courses: [],
    addedCourses: [],
    entityUserStatus: [],
    entityGroupStatus: [],
    orgRoles: [],
    userInvitesStatus: [],
    batchProcessStatus: [],
    languages: [],
    privacyRequestTypes: [],
    learnerEmails: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lookups' })
export class LookupsStore extends Store<LookupsState> {
  constructor(private api: ApiService) {
    super(createInitialState());
  }

  industries() {
    this.api.lookups.industries().subscribe((industries: any) => this.update({ industries }));
  }
  professions(industryId: number) {
    this.api.lookups.professions(industryId).subscribe((professions: any) => this.update({ professions }));
  }
  jobTitles(professionId: number) {
    this.api.lookups.jobTitles(professionId).subscribe((jobTitles: any) => this.update({ jobTitles }));
  }
  trainings() {
    this.api.lookups.trainings().subscribe((trainings: any) => this.update({ trainings }));
  }
  states() {
    this.api.lookups.states().subscribe((states: any) => this.update({ states }));
  }
  status() {
    this.api.lookups.status().subscribe((status: any) => this.update({ status }));
  }
  groups() {
    this.api.lookups.groups().subscribe((groups: any) => this.update({ groups }));
  }
  roles() {
    this.api.lookups.roles().subscribe((roles: any) => this.update({ roles }));
  }
  courses() {
    this.api.lookups.courses().subscribe((courses: any) => this.update({ courses }));
  }
  addedCourses() {
    this.api.lookups.addedCourses().subscribe((addedCourses: any) => this.update({ addedCourses }));
  }
  entityUserStatus() {
    this.api.lookups.entityUserStatus().subscribe((entityUserStatus: any) => this.update({ entityUserStatus }));
  }
  entityGroupStatus() {
    this.api.lookups.entityGroupStatus().subscribe((entityGroupStatus: any) => this.update({ entityGroupStatus }));
  }
  orgRoles() {
    this.api.lookups.orgRoles().subscribe((orgRoles: any) => this.update({ orgRoles }));
  }
  orgUserInvitesStatus() {
    this.api.lookups.orgUserInvitesStatus().subscribe((userInvitesStatus: any) => this.update({ userInvitesStatus }));
  }
  batchProcessStatus() {
    this.api.lookups.batchProcessStatus().subscribe((batchProcessStatus: any) => this.update({ batchProcessStatus }));
  }
  languages() {
    this.api.lookups.languages().subscribe((languages: any) => this.update({ languages }));
  }
  privacyRequestTypes() {
    this.api.lookups.privacyRequestTypes().subscribe((privacyRequestTypes: any) => this.update({ privacyRequestTypes }));
  }
  learnerEmails(searchTerm?: any) {
    this.api.lookups.learnerEmails(searchTerm).subscribe((learnerEmails: any) => this.update({ learnerEmails }));
  }
}
