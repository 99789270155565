import { patchState , signalStore, withMethods, withState } from '@ngrx/signals';
import { BatchInvites, initialState } from './batch-invites.model';

export const BatchInvitesStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(batchInvitesSignal: BatchInvites["batchInvitesSignal"]) {
      patchState(store, {batchInvitesSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)