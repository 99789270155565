import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({ providedIn: 'root' })
export class BatchInvitesApiService extends BaseAPI {

  getBatchInvitesReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    let result = this.http.get(`entityAdminBatch/batchinvite-grid/${entityGuid}`, {
      params: requestParams,
    });
    return result;
  }

  exportBatchInvitesReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    return this.http.get(`entityAdminBatch/batchinvite-grid/export/${entityGuid}`, {
      params: requestParams,
    });
  }

  // Allow user to cancel batch invite
  cancelBatchInvite(batchId: number) {
    return this.http.post(`entityAdminBatch/batch-invite-cancel/${batchId}`, {});
  }

  emailBatchFile(batchId: number) {
    return this.http.get(`entityAdminBatch/batch-export/${batchId}`, {});
  }
}
