import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AllCourse } from './all-course.model';
import { AllCoursesStore } from './all-courses.store';
import { ApiService } from '../../../../../../api/services/api.service';

@Injectable({ providedIn: 'root' })
export class AllCoursesService {
  constructor(private allCoursesStore: AllCoursesStore, private api: ApiService) {}

  reloadCourses() {
    return this.api.courseApiService.courses().pipe(
      tap((entities) => {
        const cloneEntities = entities;
        const newEntity = cloneEntities?.map((entity: any) => {
          const { isAssigned, isActive,  states, selected, ...remaining } = entity;
          const statesList = Object.keys(states).map((key) => ({ id: +key, value: states[key] }));
          const newCourse = {
            alreadyCourseActive: isAssigned === true ? true : false,
            status: isActive ? 'Active' : 'Archived',
            selectedState: statesList.length > 1 ? 'NATL' :  statesList.length > 0 ? statesList[0].value : null,
            isActive,
    
            courseSelected: false,
            alreadySelected: selected,
            already: selected ? true : false,
            selected,
            states,
            ...remaining,
          };
          return {
            ...newCourse,
          };
        });

        const same = JSON.stringify(newEntity) === JSON.stringify(this.allCoursesStore.getValue().entities);
        if (!same && newEntity) {
          this.allCoursesStore.set(newEntity);
        }
      })
    );
  }

  updateCoursesActive(id: number) {
    const StoreEntities = this.allCoursesStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      if (value?.id === id) {
        const { alreadySelected, courseSelected, already, ...remaining } = value;
        if (!alreadySelected) {
          const newCourse = { courseSelected: !courseSelected, alreadySelected, already: !already, ...remaining };
          this.allCoursesStore.update(key, newCourse);
        }
      }
    });
  }

  resetAllCourses() {
    const StoreEntities = this.allCoursesStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      const { isAssigned, alreadyCourseActive, alreadySelected, ...remaining } = value;
      let course: any = '';
      if (isAssigned) {
        course = { alreadyCourseActive: true, isAssigned, courseSelected: false, alreadySelected, ...remaining };
      } else {
        course = { alreadyCourseActive: false, isAssigned, courseSelected: false, alreadySelected, ...remaining };
      }

      this.allCoursesStore.update(key, course);
    });
  }
}
