// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  context: 'dev',
  apiURL: 'https://mrtapi-dev.azurewebsites.net',
  // apiURL: 'https://localhost:5001',
  domainName: 'https://mandatedreportertrainingportal-dev.azurewebsites.net',
  versionNumber: '1.6.1|11/05/2024',
  versionPollTime: 10, // in minutes
  recaptchaSiteKey: '6LdUltwpAAAAAG7ji4a7JFk-6OfqghSt3fM00mDm'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
