import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { AuthQuery } from 'src/app/routes/authentication/state/auth.query';
import { FooterComponent } from '../footer/footer.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf } from '@angular/common';
import { BannerComponent } from '../banner/banner/banner.component';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: [],
  standalone: true,
  imports: [NgIf, MatToolbarModule, MatSidenavModule, RouterOutlet, FooterComponent, BannerComponent],
})
export class AppBlankComponent implements OnInit, OnDestroy {
  fontSize = 16;
  currentUrl: boolean = true;
  loadLoginHtml: boolean = true;
  private initialCSSVars: any;
  sub!: Subscription;
  date: number = new Date().getFullYear();
  destroy$: Subject<boolean> = new Subject<boolean>();
  loginPage: boolean = false;

  constructor(
    public router: Router,
    private authQuery: AuthQuery,
  ) {
    this.router.events.subscribe((res) => {
      if (
        this.router.url === '/login-as'
      ) {
        this.loadLoginHtml = false;
      }

      if (
        this.router.url === '/login'
      ) {
        this.loginPage = true;

      } else if(
        this.router.url === '/email-confirmed' ||
        this.router.url === '/forgot'
      ) {
        this.currentUrl = false;
      }

      else {
        this.currentUrl = true;
      }
    });

    this.sub = this.authQuery.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        if (user.roles.includes('Admin') || user.roles.includes('LeadSupport')) {
          this.router.navigateByUrl('/login-as');
        } else {
          this.router.navigateByUrl('/dashboard');
        }
        this.loadLoginHtml = true;
      } else {
        this.loadLoginHtml = false;
      }
    });

    window.onbeforeunload = function () {
      if (localStorage?.theme === 'hc') {
        localStorage.removeItem('theme');
      }
    };

    this.initialCSSVars = getCSSCustomPropIndex().reduce((acc: any, cur: any) => {
      acc[`${cur[0]}`] = cur[1];
      return acc;
    }, {});
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  resetFontSite() {
    this.setRootSize(16);
  }

  decreaseFontSize() {
    this.setRootSize(this.getRootSize() - 2);
  }

  increaseFontSize() {
    this.setRootSize(this.getRootSize() + 2);
  }

  setRootSize(size: number) {
    this.fontSize = size;
    return (document.documentElement.style.fontSize = size + 'px');
  }

  getRootSize(): number {
    const fontSize = document.documentElement.style?.fontSize?.replace(/\D/g, '') || 16;
    // @ts-ignore
    return parseInt(fontSize, 10);
  }

  resetContrast() {
    document.body.classList.remove('theme-hc');
  }

  highContrast() {
    document.body.classList.add('theme-hc');
  }
}

// @ts-ignore
const isSameDomain = (styleSheet) => {
  if (!styleSheet.href) {
    return true;
  }

  return styleSheet.href.indexOf(window.location.origin) === 0;
};

// @ts-ignore
const isStyleRule = (rule) => rule.type === 1;

const getCSSCustomPropIndex = () =>
  // @ts-ignore
  [...document.styleSheets].filter(isSameDomain).reduce(
    (finalArr, sheet) =>
      finalArr.concat(
        [...sheet.cssRules].filter(isStyleRule).reduce((propValArr, rule) => {
          const props = [...rule.style]
            .map((propName) => [propName.trim(), rule.style.getPropertyValue(propName).trim()])
            .filter(([propName]) => propName.indexOf('--') === 0);

          return [...propValArr, ...props];
        }, []),
      ),
    [],
  );
