<h1 class="learner pl-4 pt-4">Groups</h1>
<div class="content-container no-data-overlay-parent">
  <div *ngIf="showZeroOverlay" class="no-data-overlay-child"> 
    <h2>0 Groups</h2>
    <p>You currently have no groups.<br>Click the Add Group button to add a group to your organization.</p>
    <a
      class="btn-light medium cursor-pointer items-center text-center"
      (click)="openDialog({ mode: 'add', componentType: 'users-group' })"
    >Add Group</a>
  </div>
  <app-grid-template [gridOptions]="gridOptions" (actionEvent)="actionEvent($event)"></app-grid-template>
</div>