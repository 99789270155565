import { APP_INITIALIZER, enableProdMode, importProvidersFrom , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { environment } from './environments/environment';
import { akitaConfig, persistState } from '@datorama/akita';
import { AppComponent } from './app/app.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { BannerCustomMessageService } from './app/shared/services/banner-custom-message.service';
import { GuidedTourService, GuidedTourModule } from 'ngx-guided-tour';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApiService } from './app/api/services/api.service';
import { BaseURLInterceptor } from './app/api/interceptors/base-url.interceptor';
import { ErrorInterceptor } from './app/api/interceptors/error.interceptor';
import { JwtInterceptor } from './app/api/interceptors/jwt.interceptor';
import { LocalDateFormatPipe } from './app/shared/pipes/local-date-format/local-date-format.pipe';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthService } from './app/routes/authentication/state/auth.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { MaterialModule } from './app/material/material.module';
import { AppStores } from './app/api/services/stores';


const storage = persistState({
  include: ['auth'],
});

akitaConfig({ resettable: true });

// @ts-ignore
window.environment = environment;

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {
    provide:APP_INITIALIZER,
    useFactory: (authService:AuthService) => () => {
      return new Promise((resolve, reject) => {
        authService.loadUserDetails().subscribe({
          next: (user) => {
          resolve(true);
          },
          error: (error) => resolve(error)
        });
      })
    }, deps: [AuthService], multi: true
    },
    AppStores,
    RecaptchaModule,
    importProvidersFrom(
      AppStores,
      BrowserModule,
      AppRoutingModule,
      FormsModule,
      NgxMaskDirective,
      NgxMaskPipe,
      NgxPermissionsModule.forRoot(),
      GuidedTourModule,
      MatNativeDateModule,
      MaterialModule
    ),
    GuidedTourService,
    BannerCustomMessageService,
    ApiService,
    LocalDateFormatPipe,
    provideNgxMask(),
    provideAnimations(),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseURLInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: 'persistStorage', useValue: storage }
  ],
}).catch((err) => console.error(err));
