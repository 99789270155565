import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../../state/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  redirectTo: boolean = false;
  inviteUrl!: string;
  constructor(private authServ: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const location = window.location.pathname;
    if (location === '/accept-invitation-confirmed' || location === '/email-confirmed') {
      this.authServ.flushAllStorage();
      this.inviteUrl = this.router.url;
      this.redirectTo = true;
    }
    return this.userLoggedIn().pipe(take(1));
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userLoggedIn().pipe(take(1));
  }

  private userLoggedIn() {
    return this.authServ.userLoggedIn$.pipe(
      map((userIsLoggedIn) => {
        if (userIsLoggedIn) {
          return true;
        } else {
          if (!this.redirectTo && window.location.pathname !== '/login') {
            const currentUrl = window.location.pathname + window.location.search;
            this.router.navigateByUrl(`/login?redirecturl=${currentUrl}`);
          }
          return false;
        }
      })
    );
  }
}
