import { inject, Injectable } from '@angular/core';
import { SwitchEntityProfile } from './switch-entity-profile.model';
import { SwitchEntityProfileStore } from './switch-entity-profile.store';
import { SwitchEntityProfileQuery } from './switch-entity-profile.query';
import { PermissionsService } from 'src/app/routes/authentication/components/services/permissions.service';
import { CoursesService } from 'src/app/routes/learner/courses/state/courses.service';
import { UserStateService } from 'src/app/api/services/user-state/user-state.service';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { SubscriptionStore } from 'src/app/subscription/state/subscription.store';
import { EntityCoursesService } from 'src/app/routes/learner/courses/components/state/entity-courses.service';
import { NewFeatureStore } from 'src/app/api/services/new-feature/new-feature.store';
import { NewFeatureService } from 'src/app/api/services/new-feature/new-feature.service';
import { DashboardService } from 'src/app/routes/org/dashboard/state/dashboard.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { AdvertisementStore } from 'src/app/api/services/advertisement/advertisement.store';
import { AdvertisementService } from 'src/app/api/services/advertisement/advertisement.service';

@Injectable({ providedIn: 'root' })
export class SwitchEntityProfileService {
  readonly newFeatureStore = inject(NewFeatureStore);
  readonly advertisementStore = inject(AdvertisementStore);
  private _activeOrg!: SwitchEntityProfile;
  constructor(
    private switchEntityProfileStore: SwitchEntityProfileStore,
    private switchEntityProfileQuery: SwitchEntityProfileQuery,
    private permissionsService: PermissionsService,
    private coursesService: CoursesService,
    private entityCoursesService: EntityCoursesService,
    private userStateService: UserStateService,
    private subscriptionStore: SubscriptionStore,
    private subscriptionQuery: SubscriptionQuery,
    private newFeatureService: NewFeatureService,
    private dashboardService: DashboardService,
    private lookupsStore: LookupsStore,
    private advertisementService: AdvertisementService,
  ) {}

  setOrganizationsStore(orgs: SwitchEntityProfile[], entityGuid, { isLogin = false } = {}) {
    let newOrgAndRoles: SwitchEntityProfile[] = [];
    // if user is logging in OR the switchEntityLastActive has no value,
    // we want to use backend to select which org to set active
    // otherwise we want to use the switchEntityLastActive setting
    if (isLogin || entityGuid === null) {
      newOrgAndRoles = orgs.map((item: any, index: number) => {
        if (item.selected) {
          this._activeOrg = item;
          return {
            id: index + 1,
            ...item,
            setActive: true,
            entityName: item.entityName.charAt(0).toUpperCase() + item.entityName.slice(1),
          } as SwitchEntityProfile;
        } else {
          return {
            id: index + 1,
            ...item,
            setActive: false,
            entityName: item.entityName.charAt(0).toUpperCase() + item.entityName.slice(1),
          } as SwitchEntityProfile;
        }
      });
    } else {
      newOrgAndRoles = orgs.map((item: any, index: number) => {
        if (item.entityGuid === entityGuid) {
          this._activeOrg = item;
          return {
            id: index + 1,
            ...item,
            setActive: true,
          } as SwitchEntityProfile;
        } else {
          return {
            id: index + 1,
            ...item,
            setActive: false,
          } as SwitchEntityProfile;
        }
      });
    }

    this.switchEntityProfileStore.set(newOrgAndRoles);
    this.setActiveOrg(this._activeOrg);
  }

  updateSwitchOrganization(entityGuid: string) {
    const storeValues: SwitchEntityProfile[] = this.switchEntityProfileQuery.getAll();
    const updatedValues = storeValues.map((item: any) => {
      if (item.entityGuid === entityGuid) {
        return {
          ...item,
          selected: true,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });

    this.setOrganizationsStore(updatedValues, null);
  }

  private setActiveOrg(activeOrg: SwitchEntityProfile) {
    this.permissionsService.flushRoles();
    const entityRoleNames = activeOrg.roles.map((entity: any) => {
      return entity;
    });
    let newEntityRoleNames: any;

    if (entityRoleNames.includes('EntityAdmin')) {
      newEntityRoleNames = ['OrgAdmin'];
    } else if (entityRoleNames.includes('CoopAdmin')) {
      newEntityRoleNames = ['CoopAdmin'];
    } else if (!entityRoleNames.includes('EntityAdmin')) {
      newEntityRoleNames = ['Learner'];
    }

    this.permissionsService.applyRoles(newEntityRoleNames);
    window.localStorage.setItem('entityGuid', activeOrg.entityGuid);
    window.localStorage.setItem('entityType', activeOrg.entityType);
    this.switchEntityProfileQuery.entityType$.next(activeOrg.entityType);
    if (entityRoleNames.includes('EntityAdmin')) {
      window.localStorage.setItem('roleType', 'EntityAdmin');
    } else if (entityRoleNames.includes('CoopAdmin')) {
      window.localStorage.setItem('roleType', 'CoopAdmin');
    } else if (!entityRoleNames.includes('EntityAdmin')) {
      window.localStorage.setItem('roleType', 'Learner');
    }
    this.switchEntityProfileStore.setActive(activeOrg.entityGuid);
    const switchEntity = {
      key: 'switchEntityLastActive',
      value: activeOrg?.entityGuid,
    };

    this.subscriptionStore.reset();
    this.advertisementStore.resetStore();
    this.newFeatureStore.resetStore();
    this.userStateService.userState(switchEntity).subscribe(
      () => {
        this.newFeatureService.getNewFeatures().subscribe();
      },
    );
    this.subscriptionQuery.checkSubscriptionUsage();
    this.advertisementService.getAdvertisements().subscribe();
    this.coursesService.getAllEntityCourses().subscribe();
    this.entityCoursesService.getAllDashboardCourses().subscribe();
    this.lookupsStore.groups();
    if (window.localStorage.getItem('roleType') == 'EntityAdmin') {
      this.dashboardService.getDashboardData({entityGuid: activeOrg.entityGuid}).subscribe();
    }
  }
}