import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { CourseApiService } from 'src/app/api/services/courses/course-api.service';
import { LookupsQuery } from 'src/app/api/services/lookups/lookups.query';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { AllCoursesQuery } from '../../../learner/courses/components/courses/state/all-courses.query';
import { AllCoursesService } from '../../../learner/courses/components/courses/state/all-courses.service';
import { CoursesQuery } from '../../../learner/courses/state/courses.query';
import { CoursesService } from '../../../learner/courses/state/courses.service';
import { OrganizationQuery } from '../../organization/org-profile/state/organization.query';
import { OrganizationService } from '../../organization/org-profile/state/organization.service';
import { DialogData, OrgCourseModalComponent } from '../../../../shared/components/modals/org-course-modal/org-course-modal.component';
import { SelectCourseModalComponent } from 'src/app/shared/components/modals/select-course-modal/select-course-modal.component';
import { Subject, throwError } from 'rxjs';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { ActivatedRoute } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-courses',
    templateUrl: './courses.component.html',
    styleUrls: ['./courses.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        MRTGridTemplateComponent
    ],
})
export class CoursesComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  entityGuid!: any;
  actionDisabled: boolean = false;
  statesList: any[] = [];
  showZeroOverlay = false;
  gridOptions: GridOptions = {
    filterData: [
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Trainings', formControlName: 'trainings', type: 'dropdown', lookup: this.lookupsQuery.trainings$ },
      { name: 'State', formControlName: 'state', type: 'dropdown', lookup: this.lookupsQuery.states$ },
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: [{value: 'active', label: 'Active'}, {value: 'archived', label: 'Archived'}] },
    ],
    openFilters: false,
    columns: [
      { header: 'Name', controlName: 'name' },
      { header: 'Training Type', controlName: 'trainingType' },
      { header: 'State', controlName: 'selectedState' },
      { header: 'Status', controlName: 'status' },
      { header: 'Action', controlName: 'action',
        actionColumnitems: [
          { text : { 
              calculateText: (isActive: any) => { return isActive ? 'Archive' : 'Reactivate Training'; }, 
              textColumn: 'isActive'
            },
            buttonAction: 'edit'
          }],
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    frontEndPagination: true,
    displaySearch: false,
  };

  constructor(
    public coursesQuery: CoursesQuery,
    private coursesService: CoursesService,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    public lookupsQuery: LookupsQuery,
    private lookupsStore: LookupsStore,
    private allCoursesQuery: AllCoursesQuery,
    private allCoursesService: AllCoursesService,
    public courseApiService: CourseApiService,
    private modalService: ModalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.reloadAllCourses();
    this.route.queryParams.subscribe((params) => {
      const addTraining = params["addTraining"];
      if (addTraining) {
        this.openSelectCourseDialog(addTraining);
      }
    });
    this.lookupsStore.states();
    this.lookupsStore.trainings();
    this.entityGuid = window.localStorage.getItem('entityGuid');

    this.lookupsQuery.states$.subscribe((states) => {
      this.statesList = states;
    });

    this.organizationService.getOrg(this.entityGuid!).pipe(takeUntil(this.destroy$)).subscribe((org: any) => {
    });
  }

  ngOnDestroy(): void {
    this.allCoursesService.resetAllCourses();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  reloadAllCourses(change?: any) {
    this.gridOptions.isLoading = true;
    this.allCoursesService
      .reloadCourses()
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.gridOptions.isLoading = false;
          return throwError(error);
        })
      )
      .subscribe((data: any) => {
        this.gridOptions.isLoading = false;
        if (data.some((x) =>  x.selected)) {
          this.showZeroOverlay = false;
          this.gridOptions.addButtonName = 'Add Training';
        } else {
          this.showZeroOverlay = true;
        }
      });

    this.allCoursesQuery
      .selectAll({
        filterBy: [
          (entity: any) =>
            change?.trainings
                ? entity.trainingId == change?.trainings
                : true,
          (entity: any) =>
            entity?.states
              ? change?.state
                ? Object.values(entity.states).includes(change?.state)
                : true
              : true,
          (entity: any) =>
            change?.status
              ? change?.status === 'archived'
                ? entity.isActive === false
                : entity.isActive === true
              : true,
        ],
      })
      .pipe(
        map((courses: any) => {
          return courses.filter((course) => course.selected );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (courses: any) => {
          this.gridOptions.data.data = courses;
          this.gridOptions.data.filter = change?.search;
        },
      });
  }

  printstates(states: any[]) {
    let stateList = states.map((x) => x.abbreviation);
    return stateList;
  }

  openDialog(options: DialogData) {
    this.modalService.open(OrgCourseModalComponent, {
      modalType: ModalType.Medium,
      data: options,
    }, () => this.refreshGrid());
  }

  openSelectCourseDialog(options: { addTraining?: string }) {
    const { addTraining } = options;
    this.modalService.open(SelectCourseModalComponent, {
      modalType: ModalType.FullScreen,
      data: { componentType: 'select course', addTraining },
    }, () => this.gridOptions.fetchData = true);
  }

  courseAction(course: any) {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }

    if (course.status === 'Active') {
      this.openDialog({ buttonAction: 'archive-training', course, entityGuid });
    } else {
      this.openDialog({ buttonAction: 'add-training', course, entityGuid });
    }

    this.actionDisabled = true;
  }

  refreshGrid() {
    this.coursesService.getAllEntityCourses().pipe(takeUntil(this.destroy$)).subscribe();
    this.reloadAllCourses();
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.reloadAllCourses(event.data);
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      this.reloadAllCourses(event.data);
    } else if (event.action === 'clearFilters') {
      this.reloadAllCourses();
    } else if (event.action === 'addButton') {
      this.openSelectCourseDialog({});
    }
    // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      this.courseAction(event.data);
    }
  }
}
