import { components } from "src/app/api/interfaces/swagger-types";

export type NewFeature = components['schemas']['NewFeatureDetailViewModel'];

export interface NewFeatures {
  newFeatureSignal: NewFeature[] | [];
  loadingSignal: boolean;
}

export const initialState: NewFeatures = {
  newFeatureSignal: [],
  loadingSignal: false,
};