import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LookupsStore, LookupsState } from './lookups.store';
import { Lookup } from '../../../shared/interfaces/lookup.interfaces';

import { LookupResult } from './lookups.service';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class LookupsQuery extends Query<LookupsState> {
  industries$: Observable<Lookup> = this.select('industries').pipe(map(this.lookupToLookup));
  professions$: Observable<Lookup> = this.select('professions').pipe(map(this.lookupToLookup));
  jobTitles$: Observable<Lookup> = this.select('jobTitles').pipe(map(this.lookupToLookup));
  trainings$: Observable<Lookup> = this.select('trainings').pipe(map(this.lookupToLookup));
  states$: Observable<Lookup> = this.select('states').pipe(map(this.lookupToLookup));
  groups$: Observable<Lookup> = this.select('groups').pipe(map(this.lookupToLookup));
  status$: Observable<Lookup> = this.select('status').pipe(map(this.lookupToLookup));
  roles$: Observable<Lookup> = this.select('roles').pipe(map(this.lookupToLookup));
  orgRoles$: Observable<Lookup> = this.select('orgRoles').pipe(map(this.lookupToLookup));
  courses$: Observable<Lookup> = this.select('courses').pipe(map(this.lookupToLookup));
  addedCourses$: Observable<Lookup> = this.select('addedCourses').pipe(map(this.lookupToLookup));
  entityUserStatus$: Observable<Lookup> = this.select('entityUserStatus').pipe(map(this.lookupToLookup));
  entityGroupStatus$: Observable<Lookup> = this.select('entityGroupStatus').pipe(map(this.lookupToLookup));
  userInvitesStatus$: Observable<Lookup> = this.select('userInvitesStatus').pipe(map(this.lookupToLookup));
  batchProcessStatus$: Observable<Lookup> = this.select('batchProcessStatus').pipe(map(this.lookupToLookup));
  languages$: Observable<Lookup> = this.select('languages').pipe(map(this.lookupToLookup));
  privacyRequestTypes$: Observable<Lookup> = this.select('privacyRequestTypes').pipe(map(this.lookupToLookup));
  learnerEmails$: Observable<Lookup> = this.select('learnerEmails').pipe(map(this.lookupToLookup));

  professionsHasValue$: Observable<boolean> = this.select('professions').pipe(
    map((professions) => {
      return professions.length > 0;
    })
  );

  jobTitleHasValue$: Observable<boolean> = this.select('jobTitles').pipe(
    map((jobTitles) => {
      return jobTitles.length > 0;
    })
  );

  constructor(protected store: LookupsStore) {
    super(store);
  }

  lookupToLookup(lookupItems: LookupResult[]): Lookup {
    return lookupItems.map((lookup) => {
      return { ...lookup, value: lookup.value, label: lookup.name ?? '' };
    });
  }
}
