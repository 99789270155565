import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SwitchEntityProfileQuery } from 'src/app/shared/components/layouts/header/state/switch-entity-profile.query';
import { OrganizationService } from '../org-profile/state/organization.service';
import { NgIf } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgxMaskDirective } from 'ngx-mask';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { CommonModalComponent, CommonModalDialogData } from 'src/app/shared/components/modals/common-modal/common-modal.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { OrgAdminBannerComponent } from 'src/app/shared/components/layouts/banner/org-admin-banner/org-admin-banner.component';
import { BoxyMessageComponent, BoxyMessageOptions } from 'src/app/shared/components/boxy-message/boxy-message.component';


@UntilDestroy()
@Component({
  selector: 'app-public-landing',
  templateUrl: './public-landing.component.html',
  styleUrls: ['./public-landing.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLegacyRadioModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacySlideToggleModule,
    MatIconModule,
    NgIf,
    SnackbarComponent,
    CommonModule,
    NgxMaskDirective,
    SpinnerComponent,
    OrgAdminBannerComponent,
    BoxyMessageComponent,
  ]
})
export class PublicLandingComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  orgData: any = {};
  userSlug!: string;
  slugIsValid = true;
  hasSubscription: boolean = false;
  @Input() boxyMessage: BoxyMessageOptions = {
    title: '',
    message: '',
    linkText: '',
    link: '',
    buttonText: '',
    disableButton: false,
  }
  
  constructor(
    private fb: UntypedFormBuilder,
    public switchEntityProfileQuery: SwitchEntityProfileQuery,
    private organizationService: OrganizationService,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private modalService: ModalService,
    private subscriptionQuery: SubscriptionQuery,
    private spinnerService: SpinnerService,
  ) {}
  
  form = this.fb.group({
    publicUserAssociationToggle: [false],
    publicUserAssociationURL: [{ value: '', disabled: true }],
    slug: ['', Validators.maxLength(50)],
    whiteListedDomains: this.fb.array([])
  });
  
  ngOnInit(): void {
    this.loadOrg().pipe(takeUntil(this.destroy$)).subscribe();
    this.boxyMessage = {
      ... this.boxyMessage,
      title: 'Share Organization Training Web Page',
      message: "This premium feature enables you to use your organization's training web page to allow your team to join without an invitation. Learners with access to the link can join your organization without needing your approval. ",
      linkText: 'Find out more.',
      link: 'https://mandatedreportertraining.zendesk.com/hc/en-us/articles/29726131886107-Join-Organization-Web-Page',
      buttonText: 'View Pricing',
    }
    // check if user has active subscription
    this.subscriptionQuery.select().subscribe((subscription) => {
      if (subscription?.usage) {
        if (!subscription?.usage.hasActiveSubscription) {
          this.hasSubscription = false;
          this.disableFormFields();
        } else {
          this.hasSubscription = true;
        }
      }
    });
  }
  
  disableFormFields() {
    this.form.get('publicUserAssociationToggle')?.disable();
    this.form.get('publicUserAssociationURL')?.disable();
    this.form.get('slug')?.disable();
    this.form.get('whiteListedDomains')?.disable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadOrg() {
    this.spinnerService.whiteBackground();
    this.spinnerService.show();
    return this.switchEntityProfileQuery.activeOrg$.pipe(
      untilDestroyed(this),
      switchMap((user) => {
        return this.organizationService.getOrg(user.entityGuid);
      }),
      tap((data: any) => {
        if (data) {
          this.orgData = data;
          this.userSlug = data.slug;
          this.form.get('publicUserAssociationToggle')?.setValue(data?.publicLandingPageEnabled);
          this.form.get('publicUserAssociationURL')?.setValue(data?.landingPageShortUrl);
          this.form.get('slug')?.setValue(data?.slug);
          this.whiteListedDomains.clear();
          if (data.whiteListedDomains && data.whiteListedDomains.length > 0) {
            data.whiteListedDomains.forEach((domain: string) => {
              this.whiteListedDomains.push(this.fb.control(domain, [Validators.maxLength(50)]));
            });
          }
          this.addBlankDomain();
          if (data?.publicLandingPageEnabled) {
            this.form.get('publicUserAssociationURL')?.enable();
            this.form.get('slug')?.enable();
            this.form.get('whiteListedDomains')?.enable();
          } else {
            this.form.get('publicUserAssociationURL')?.disable();
            this.form.get('slug')?.disable();
            this.form.get('whiteListedDomains')?.disable();
          }
          this.spinnerService.hide();
        }
      }),
    );
  }


  setPublicPageStatus() {
    if (!this.orgData) {
      return;
    }
    this.orgData.publicLandingPageEnabled = this.form.get('publicUserAssociationToggle')?.value;
    this.form.get('publicUserAssociationToggle')?.disable();
    this.organizationService
    .updateOrg(this.orgData)
    .pipe(
      catchError((error) => throwError(error)),
      takeUntil(this.destroy$),
    )
    .subscribe({
      complete: () => {
        this.form.get('publicUserAssociationToggle')?.enable();
        this.loadOrg().pipe(takeUntil(this.destroy$)).subscribe();
      },
      error: (error) => {
        this.form.get('publicUserAssociationToggle')?.enable();
      },
    });
  }


  get whiteListedDomains(): UntypedFormArray {
    return this.form.get('whiteListedDomains') as UntypedFormArray;
  } 
  addDomain() {
    this.whiteListedDomains.push(this.fb.control('',[Validators.maxLength(50)]));
  }
  addBlankDomain() {
    this.whiteListedDomains.push(this.fb.control('', [Validators.maxLength(50)]));
  }
  removeDomain(index: number) {
    if (!this.orgData.publicLandingPageEnabled) {
      return;
    } else {
      this.whiteListedDomains.removeAt(index);
    }
  }


  onBoxyButtonClick() {
    this.modalService.openPricingModal(this.hasSubscription);
  }


  clipBoard() {
      this.clipboard.copy(`${this.form.get('publicUserAssociationURL')?.value}`);
      this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "Copied Link" }})
  }


  isSlugValid() {
    let userSlug = this.orgData.slug;
    let entitySlug = this.form.get('slug')?.value;

    // valid slug characters (alphanumeric, hyphens, underscores)
    let validSlugPattern = /^[a-zA-Z0-9-_]+$/;
    
    if(entitySlug == null || entitySlug == "") {
      this.slugIsValid = true;
      return;
    }

    if (!validSlugPattern.test(entitySlug)) {
      this.slugIsValid = false;
      return;
    }

    if(userSlug != entitySlug ) {
      this.form.get('publicUserAssociationToggle')?.disable();
      this.organizationService.isSlugAvailable(entitySlug).subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success,  message: "Custom URL slug is Available!" }});
          this.slugIsValid = true;
          this.form.get('publicUserAssociationToggle')?.enable();
        },
        error: () => {
          this.slugIsValid = false;
          this.form.get('publicUserAssociationToggle')?.enable();        
        }
      });
    } else {
      this.form.get('publicUserAssociationToggle')?.enable();
      this.slugIsValid = true;
    }
  }


  filterValidDomains() {
    const validDomains = this.whiteListedDomains.controls
      .map((control) => control.value)
      .filter((domain: string) => domain && domain.trim() !== "" && this.isValidEmailDomain(domain));

    return validDomains;
  }

  isValidEmailDomain(domain: string): boolean {
    const emailPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(domain);
  }

  updateSettings() {
    let entitySlug = this.form.get('slug')?.value;
    this.form.get('slug')?.setValue(entitySlug);
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.form.get('publicUserAssociationToggle')?.disable();
    const validationErrorHandler = (validationErrors: ValidationErrors) => {
      if (validationErrors.length) {
        validationErrors.forEach((error) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
           this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } 
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
      }
      return of(null);
    };

    const validDomains = this.filterValidDomains();
    
    let entityGuid: any = '';
    if (window.localStorage.getItem('entityGuid')) {
      entityGuid = window.localStorage.getItem('entityGuid');
    }

    this.orgData = {
      ...this.orgData,
      entityGuid: entityGuid,
      whiteListedDomains: validDomains,
      slug: entitySlug,
      publicLandingPageEnabled: this.form.get('publicUserAssociationToggle')?.value
    };

    this.organizationService
      .updateOrg(this.orgData)
      .pipe(
        catchError((error) => throwError(error)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        complete: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "Organization Updated" }})
          this.form.get('publicUserAssociationToggle')?.enable();
          this.loadOrg().pipe(takeUntil(this.destroy$)).subscribe();
        },
        error: (error) => {
          validationErrorHandler(error);
          this.form.get('publicUserAssociationToggle')?.enable();
        },
    });
    
  }
}
