import { components } from "src/app/api/interfaces/swagger-types";

export type Advertisement = components['schemas']['AdvertisementViewModel'];

export interface Advertisements {
  advertisementSignal: Advertisement[] | null;
  loadingSignal: boolean;
  parentSignal: string | null;
}

export const initialState: Advertisements = {
  advertisementSignal: null,
  loadingSignal: false,
  parentSignal: null,
};