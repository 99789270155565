import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { DashboardState, createEntityAdminDashboard } from './dashboard.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'entity-courses' })
export class DashboardStore extends EntityStore<DashboardState> {
  constructor() {
    super(createEntityAdminDashboard());
  }
}
