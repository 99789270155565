import { inject, Injectable } from "@angular/core";
import { LearnerMetricsStore } from "./learner-metrics/learner-metrics.store";
import { BatchInvitesStore } from "./batch-invites/batch-invites.store";
import { CoopCertsStore } from "./coop/coop-certs.store";
import { AdvertisementStore } from "./advertisement/advertisement.store";
import { NewFeatureStore } from "./new-feature/new-feature.store";

// All stores should be imported here
export const AppStores = [
  LearnerMetricsStore,
  BatchInvitesStore,
  CoopCertsStore,
  AdvertisementStore,
  NewFeatureStore,
];


@Injectable({
  providedIn: 'root',
})
export class Stores {
  stores:any[] = [];
  constructor() {
    AppStores.forEach(store => {
      const storeInject = inject(store);
      this.stores.push(storeInject);
    });
  }
  
  resetStores() {
    this.stores.forEach(store => {
      if (store.resetStore)
      store.resetStore();
    });
  }
}
