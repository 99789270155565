import { CommonModule } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

const CHECKBOX_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CourseCheckboxComponent),
  multi: true
}

@Component({
  selector: 'app-course-checkbox',
  templateUrl: './course-checkbox.component.html',
  styleUrls: ['./course-checkbox.component.scss'],
  standalone: true,
  providers: [CHECKBOX_CONTROL_ACCESSOR],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    CdkConnectedOverlay,
    OverlayModule,
    CdkOverlayOrigin,
  ]
})

export class CourseCheckboxComponent implements OnInit, ControlValueAccessor {
  @Output() checkboxClick = new EventEmitter<number>();
  @Input() course: any;
  @Input() disabled: boolean = false;
  overlay!: CdkConnectedOverlay;
  isMobile: boolean = false;
  private breakpointSubscription!: Subscription;
  private onChange!: Function;
  private onTouched!: Function;
  checked: boolean = false;
  infoToolTipOpen: boolean = false;

  toolTipPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      panelClass: 'custom-tooltip-top-left',
      offsetX: -65,
      offsetY: 22,
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      panelClass: 'custom-tooltip-top-right',
      offsetX: 40,
      offsetY: 22,
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      panelClass: 'custom-tooltip-bottom-left',
      offsetX: -65,
      offsetY: -22,
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      panelClass: 'custom-tooltip-bottom-right',
      offsetX: 40,
      offsetY: -22,
    }
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.breakpointSubscription = this.breakpointObserver
      .observe('(max-width: 768px)')
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  // Emit the checkbox change event and notify Angular's form control
  onCheckboxChange(event: MatCheckboxChange) {
    this.checked = event.checked;
    this.onChange(this.checked);
    this.onTouched();
    this.checkboxClick.emit(this.course);
  }
  
  onTooltipMouseEnter() {
    this.infoToolTipOpen = true;
  }

  hideTooltip() {
    this.infoToolTipOpen = false;
  }

  // These 4 methods are part of the control value accessor
  // Register the callback when the form control value changes
  registerOnChange(fn) {
    this.onChange = fn;
  }
  // Register the callback when the form control is touched
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  // This method is triggered when the parent component sets the form control
  writeValue(value: boolean): void {
    this.checked = value;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
