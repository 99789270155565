<h1 class="text-center text-primary">Add Trainings</h1>

<div class="flex flex-col justify-between h-full">
  <div>
    <p class="text-center my-md hidden lg:block">Use the filters below to easily find the trainings you need. 
      Explore the <a href="https://mandatedreportertraining.com/courses/course-catalog/" target="_blank">Course Catalog</a> for details on trainings.</p>
    <div [formGroup]="filtersForm" class="flex flex-wrap justify-center lg:gap-5 mb-5 mt-8 lg:mt-0">
      <app-user-cascading-dropdowns class="w-full select-course-cascading-dropdowns center-course-cascading-dropdowns" [form]="filtersForm"></app-user-cascading-dropdowns>
    </div>

    <ng-container *ngIf="trainingCoursesFound; else noCoursesFound">
      <div>
        <form [formGroup]="selectCourseForm">
          <div *ngFor="let group of formGroupNames">         
            <div [formGroupName]="group">
              <div *ngIf="getCoursesByGroup(group).length > 0">
                <h5 class="mt-8">{{groupNames[group]}}</h5>
                <div class="checkbox-grid">
                  <div class="mt-3" *ngFor="let course of getCoursesByGroup(group)">
                    <div *ngIf="isCourseFiltered(course.id)">
                      <app-course-checkbox
                        [course]="course"
                        (checkboxClick)="onCheckboxClick(course)"
                        [formControlName]="course.id.toString()"
                      ></app-course-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>

    <ng-template #noCoursesFound>
      <div class="bg-color-lightBlue p-6">
        <h2 *ngIf="!showTypeAndState" class="text-center font-semibold learner">
          No trainings found that match your search criteria.
          Use the filters above to modify your search or contact your
          organization's administrator to assign trainings.
        </h2>
        <h2 *ngIf="showTypeAndState" class="text-center font-semibold learner">
          No trainings found that match your search criteria. Use the filters above to modify your search.
        </h2>
      </div>
    </ng-template>

    <!-- only display when selecting general training and already selected a course that includes general training -->
    <div *ngIf="repeatingGeneralTraining()" class="bg-light-yellow p-6 mt-6">  
      <h2 class="text-center font-semibold learner">Are you sure you want to add 'General Training'? This course is included in '{{ formatRepeatingCoursesList(repeatedGeneralTrainingCourses) }}'.</h2>
    </div>
  </div>
  
  <div class="flex justify-center text-center mb-16 mt-4">
    <button class="btn-primary large" [disabled]="disableAddTrainingBtn"
      (click)="(isEntityAdmin || isCoopAdmin) ? addCoursesOrgAdmin() : addCoursesLearner()">
      <mat-spinner class="mr-5" *ngIf="addTrainingLoading" [diameter]="20"></mat-spinner>Add Trainings
    </button>
  </div>
</div>



