import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { CoursesQuery } from '../../../routes/learner/courses/state/courses.query';
import { CourseApiService } from '../../../api/services/courses/course-api.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CoursesService } from '../../../routes/learner/courses/state/courses.service';
import { LookupsQuery } from 'src/app/api/services/lookups/lookups.query';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { AuthQuery } from '../../../routes/authentication/state/auth.query';
import { UserStateService } from 'src/app/api/services/user-state/user-state.service';
import { AuthService } from 'src/app/routes/authentication/state/auth.service';
import { AllCoursesService } from '../../../routes/learner/courses/components/courses/state/all-courses.service';
import { AllCoursesQuery } from '../../../routes/learner/courses/components/courses/state/all-courses.query';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, CommonModule } from '@angular/common';
import { UserCascadingDropdownsComponent } from 'src/app/shared/components/user-cascading-dropdowns/user-cascading-dropdowns.component';
import { CourseCheckboxComponent } from "./course-checkbox/course-checkbox.component";

@UntilDestroy()
@Component({
    selector: 'app-select-course',
    templateUrl: './select-course.component.html',
    styleUrls: ['./select-course.component.scss'],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatProgressSpinnerModule,
    UserCascadingDropdownsComponent,
    CommonModule,
    CourseCheckboxComponent
],
})
export class SelectCourseComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  courseIdsFilter!: Subscription;
  courseID!: number;
  coursesToAdd: any[] = [];
  availableCourses: any[] = [];
  availableCoursesFiltered: any[] = [];
  disableAddTrainingBtn: boolean = true;
  addTrainingLoading: boolean = false;
  trainingCoursesFound: boolean = false;
  defaultLanguage: number = 1; //English
  isIndividual!: boolean;
  isEntityAdmin!: boolean;
  isCoopAdmin!: boolean;
  showTypeAndState!: boolean;
  currentCourseService!: any;
  currentCourseQuery!: any;
  previousFormValues: any;
  courseIdsFiltered!: any[];
  @Input() coopFilter: boolean = false;
  @Input('coopCourseIds') coopCourseIds$ = new Observable<number[]>();
  repeatedGeneralTrainingCourses: any[] = [];

  filtersForm = this.fb.group({
    stateId: this.fb.control(null),
    industryId: this.fb.control(null),
    professionId: this.fb.control({ value: null, disabled: true }),
    language: this.fb.control(null),
  });

  selectCourseForm = this.fb.group({
    childAbuse: this.fb.group({}),
    elderAbuse: this.fb.group({}),
    other: this.fb.group({})
  });

  formGroupNames!: string[];
  groupNames: { [key: string]: string } = {
    'childAbuse': 'Mandated Reporting of Child Abuse',
    'elderAbuse': 'Mandated Reporting of Elder Abuse',
    'other': 'Other',
  };

  get selectedLanguage() {
    return this.filtersForm.get('language')?.value;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SelectCourseComponent>,
    public courseApiService: CourseApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public organizationService: OrganizationService,
    private coursesService: CoursesService,
    public coursesQuery: CoursesQuery,
    public allCoursesService: AllCoursesService,
    public allCoursesQuery: AllCoursesQuery,
    private lookupsStore: LookupsStore,
    public lookupsQuery: LookupsQuery,
    public lookupsService: LookupsService,
    private authService: AuthService,
    private AuthQuery: AuthQuery,
    private userStateService: UserStateService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.courseIdsFilter) {
      this.courseIdsFilter.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.loadCourses();
  }

  loadCourses() {
    this.isIndividual = localStorage.getItem('entityType') === 'Individual' && !this.data.forcedLearner;
    this.isEntityAdmin = localStorage.getItem('roleType') === 'EntityAdmin' && !this.data.forcedLearner;
    this.isCoopAdmin = localStorage.getItem('roleType') === 'CoopAdmin' && !this.data.forcedLearner;
    
    this.showTypeAndState = this.isEntityAdmin || (this.isIndividual && !this.coopFilter) || this.isCoopAdmin;

    const entityGuid = window.localStorage.getItem('entityGuid');

    if (this.showTypeAndState) {
      this.organizationService
        .getOrg(entityGuid!)
        .pipe(takeUntil(this.destroy$))
        .subscribe((org: any) => {
          this.filtersForm.get('stateId')?.setValue(org.stateId);
        });
    } else {
      this.filtersForm.get('stateId')?.setValue(null);
    }

    if (this.isEntityAdmin || this.isCoopAdmin) {
      this.currentCourseService = this.allCoursesService;
      this.currentCourseQuery = this.allCoursesQuery;
    } else {
      this.currentCourseService = this.coursesService;
      this.currentCourseQuery = this.coursesQuery;
    }

    if (!this.isEntityAdmin && !this.isCoopAdmin) {
      this.AuthQuery.user$.subscribe((user) => {
        const userState = user?.stateSettings?.filter((item: any) => item.key === 'defaultLanguage');

        if (userState[0]) {
          this.defaultLanguage = +userState[0].value;
          this.filtersForm.get('language')?.setValue(this.defaultLanguage);
        } else {
          this.filtersForm.get('language')?.setValue(this.defaultLanguage);
        }
      });
    }

    combineLatest([this.currentCourseQuery.allCourses$, this.coopFilter? this.coopCourseIds$ : this.currentCourseQuery.allCourses$])
      .pipe(
        //@ts-ignore
        map(([courses, coopCourseIds]) => {
          if (this.coopFilter) {
            // @ts-ignore
            courses = courses.filter((course) => coopCourseIds.includes(course.id));
          }
          if (courses?.length === 0) {
            return;
          }
          if (courses?.length > 0) {
            this.trainingCoursesFound = true;
          }
          this.availableCourses = courses;

          this.coursesToAdd = courses
            .map((course) => {
              if (course.courseSelected) {
                return course;
              }
            })
            .filter(Boolean);

          return courses.map((course) => course.courseSelected);
        }),
        tap((courses: any) => {
          this.filterAvailableCourses(this.filtersForm.getRawValue());
          if (courses?.includes(true)) {
            this.disableAddTrainingBtn = false;
          } else {
            this.disableAddTrainingBtn = true;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.filtersForm.valueChanges.subscribe((form) => {
      this.filterAvailableCourses(form);
    });

    // Gouping courses by training type to display them in correct category
    this.availableCourses.forEach(course => {
      if (course.trainingName === 'Child Abuse') {
        const group = this.selectCourseForm.get('childAbuse') as FormGroup;
        if (group) {
          group.addControl(course.id, this.fb.control(
            { value: course.alreadySelected, disabled: course.alreadySelected }
          ));
        }
        // Making a list of courses that include General Training that have already been selected
        if ( 
          course.alreadySelected 
          && (course.moduleNames.includes('General Training') 
          && !course.name.includes('General Training'))
          && !this.repeatedGeneralTrainingCourses.some(c => c.id === course.id)
        ) {
          this.repeatedGeneralTrainingCourses.push(course);
        }
      } else if (course.trainingName === 'Elder Abuse') {
        const group = this.selectCourseForm.get('elderAbuse') as FormGroup;
        if (group) {
          group.addControl(course.id, this.fb.control(
            { value: course.alreadySelected, disabled: course.alreadySelected }
          ));
        }
      } else {
        const group = this.selectCourseForm.get('other') as FormGroup;
        if (group) {
          group.addControl(course.id, this.fb.control(
            { value: course.alreadySelected, disabled: course.alreadySelected }
          ));
        }
      }
     });
     this.formGroupNames = Object.keys(this.selectCourseForm.controls);
  }

  // Get courses for a each training type
  getCoursesByGroup(groupName: string) {
    const group = this.selectCourseForm.get(groupName) as FormGroup;
    if (!group) {
      return [];
    }
    const courseIds = Object.keys(group.controls);
    return this.availableCoursesFiltered.filter(course => courseIds.includes(course.id.toString()));
  }

  filterAvailableCourses(form: any) {
    if (!this.showTypeAndState) {
      this.availableCoursesFiltered = this.availableCourses.filter(
        (course: any) =>
          Object.keys(course.languages!).some((l) => (form.language ? l == form.language : true))
      );
      if (this.availableCoursesFiltered?.length > 0) {
        this.trainingCoursesFound = true;
      } else {
        this.trainingCoursesFound = false;
      }
      return;
    }
    if (form.stateId) {
      if (this.previousFormValues) {
        if (
          this.previousFormValues.stateId === form.stateId &&
          this.previousFormValues.industryId === form.industryId &&
          this.previousFormValues.professionId === form.professionId) {
          if (this.courseIdsFiltered) {
            this.availableCoursesFiltered = this.availableCourses.filter(
              (course: any) =>
                this.courseIdsFiltered.includes(course.id) &&
              Object.keys(course.languages!).some((l) => (form.language ? l == form.language : true))
            );
            if (this.availableCoursesFiltered?.length > 0) {
              this.trainingCoursesFound = true;
            } else {
              this.trainingCoursesFound = false;
            }
          }
          return;
        }
      }
      this.previousFormValues = form;
      const params = {
        StateId: form.stateId ?? '',
        IndustryId: form.industryId ?? '',
        ProfessionId: form.professionId ?? ''
      }
      if (this.courseIdsFilter) {
        this.courseIdsFilter.unsubscribe();
      }
      this.courseIdsFilter = this.lookupsService.courseIdsFilter(params).subscribe((courseIds) => {
        this.courseIdsFiltered = courseIds;
        this.availableCoursesFiltered = this.availableCourses.filter(
          (course: any) =>
            courseIds.includes(course.id) &&
          Object.keys(course.languages!).some((l) => (form.language ? l == form.language : true))
        );
        if (this.availableCoursesFiltered?.length > 0) {
          this.trainingCoursesFound = true;
        } else {
          this.trainingCoursesFound = false;
        }
      });
    }
  }  

  addCoursesLearner() {
    this.addTrainingLoading = true;
    if (this.data.componentType === 'select course') {
      const addCourseCalls = this.coursesToAdd
        .filter((c) => c)
        .map((course) => {
          this.courseID = course.id;
          return { id: course.id, languageId: this.selectedLanguage ?? 1 };
        });

      this.courseApiService
        .addCourse(addCourseCalls)
        .pipe(
          tap(() => {
            this.coursesService.ResetAllCoursesActive();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.userStateService
            .userState({ key: 'defaultLanguage', value: this.selectedLanguage ?? 1 })
            .subscribe(() => this.authService.userState());
          this.coursesService.getAllEntityCourses().subscribe();
          this.dialogRef.close({ event: 'course-added', courseID: this.courseID });
          let viewVideoBio = window.document.querySelector<any>('.view-video-bio');
          if (viewVideoBio)
            viewVideoBio.parentNode.style.zIndex = '1000';
          this.addTrainingLoading = false;
        });
    }
  }

  addCoursesOrgAdmin() {
    this.addTrainingLoading = true;
    if (this.data.componentType === 'select course') {
      const addCourseCalls: number[] = this.coursesToAdd
        .filter((c) => c)
        .map((course) => {
          this.courseID = course.id;
          return course.id;
        });

      this.courseApiService
        .addOrgCourses(addCourseCalls)
        .pipe(
          tap(() => {
            this.allCoursesService.resetAllCourses();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.dialogRef.close({ event: 'course-added', courseID: this.courseID });
          let viewVideoBio = window.document.querySelector<any>('.view-video-bio');
          if (viewVideoBio)
            viewVideoBio.parentNode.style.zIndex = '1000';
          this.addTrainingLoading = false;
        });
    }
  }

  // So we can display only filtered courses
  isCourseFiltered(courseId: number): boolean {
    return this.availableCoursesFiltered.some(course => course.id === courseId);
  }

  onCheckboxClick(course: any) {
    if ((this.isEntityAdmin || this.isCoopAdmin) && window.location.pathname.includes('/trainings')) {
      this.allCoursesService.updateCoursesActive(course.id);
    } else {
      this.coursesService.updateCoursesActive(course.id);
    }
    this.repeatingGeneralTraining();
  }

  // Only applies to Child Abuse trainings
  repeatingGeneralTraining(): boolean {
    let generalTrainingSelected = false;
    this.repeatedGeneralTrainingCourses = this.repeatedGeneralTrainingCourses.filter(course => course.alreadySelected);
    this.coursesToAdd.forEach(course => {
      if (course.trainingName !== 'Child Abuse') { // Not a Child Abuse training
        return;
      }
      if (course.name.includes('General Training')) {
        generalTrainingSelected = true;
        return
      }
      if (course.moduleNames.includes('General Training')) {

        if (course.courseSelected && !this.repeatedGeneralTrainingCourses.some(c => c.id === course.id)) {
          this.repeatedGeneralTrainingCourses.push(course);
        }
      }
    });
    if (generalTrainingSelected && this.repeatedGeneralTrainingCourses.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  formatRepeatingCoursesList(courses: any[]): string {
    if (courses.length === 1) {
      return courses[0].name;
    }
    if (courses.length === 2) {
      return courses[0].name + ' and ' + courses[1].name;
    } else {
    const lastCourse = courses.pop();
    return courses.map(course => course.name).join(', ') + ' and ' + lastCourse.name;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}