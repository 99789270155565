import { patchState, signalStore, withComputed, withMethods, withState, } from '@ngrx/signals';
import { Advertisements, initialState } from './advertisement.model';
import { computed } from '@angular/core';

export const AdvertisementStore = signalStore(
  withState(initialState),

  // Computed signal for the filtered advertisement
  withComputed(({ advertisementSignal, parentSignal }) => ({
    filteredAdSignal: computed(() => {
      const ads = advertisementSignal();
      const parent = parentSignal();
      if (!parent) return null;
      const filteredAd = ads?.find((ad) => ad.page === parent) || null; 
      return filteredAd;
    }),
  })),

  withMethods(({...store}) => ({
    setAll(advertisementSignal: Advertisements["advertisementSignal"]) {
      patchState(store, {advertisementSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    setParent(parentSignal: string) {
      patchState(store, {parentSignal: parentSignal});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)




//needs to be a computed value

  // advertisement$: any = this.advertisementQuery
  //   .selectAll({
  //     filterBy: (ad) => ad.page === this.parent,
  //     limitTo: 1,
  //   })
  //   .pipe(map((ad) => ad[0]));
