<app-modal-layout>
    <div title>
      Check out new features available in your organization.
    </div>
    <div class="justify-center items-center flex flex-col text-center">
      <div class="new-feature-image-container" *ngIf="this.newFeatureStore.newFeatureSignal() as features">
        <img [src]="features[currentFeatureIndex]?.imageUrl" alt="Feature Image" class="max-w-full max-h-full">
      </div>
      <h4 class="m-3">{{features[currentFeatureIndex]?.title}}</h4>
      <div [innerHTML]="features[currentFeatureIndex]?.description" class="new-feature-description"></div>
      <div class="dots-navigation">
        <span *ngFor="let feature of features; let i = index" [class.active]="isActiveDot(i)">
          ●
        </span>
      </div>
      <div class="text-center">
          <button class="btn-primary medium" (click)="nextFeature()">{{featureButtonText}}</button>
      </div>
    </div>

</app-modal-layout>
