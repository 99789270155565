import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { LookupsQuery } from 'src/app/lookups/lookups.query';
import { LookupsStore } from 'src/app/lookups/lookups.store';
import { LearningMetricsService } from './state/learning-metrics.service';
import { LearningMetricsQuery } from './state/learning-metrics.query';
import { EntityCoursesService } from '../../../learner/courses/components/state/entity-courses.service';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { GridOptions } from 'src/app/api/interfaces/grid-options';
import { GridTemplateComponent } from 'src/app/shared/components/layouts/grid-template/grid-template.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
    selector: 'app-learning-metrics',
    templateUrl: './learning-metrics.component.html',
    styleUrls: ['./learning-metrics.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        GridTemplateComponent,
        SnackbarComponent
    ],
})
export class LearningMetricsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  disableflag: boolean = false;
  certificateQuota!: number;
  showZeroOverlay!: boolean;
  defaultSortColumn: string = 'dateAdded';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'fromDate', type: 'date', dateOffset: -365 },
      { name: 'To', formControlName: 'toDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Training', formControlName: 'trainingId', type: 'dropdown', lookup: this.lookupsQuery.trainings$ },
      { name: 'Course', formControlName: 'courseId', type: 'dropdown', lookup: this.lookupsQuery.courses$ },
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: [{ value: 'Complete', label: 'Complete' }, { value: 'In_Progress', label: 'In Progress' }, { value: 'Not_Started', label: 'Not Started' }] },
    ],
    openFilters: false,
    columns: [
      { header: 'First Name', controlName: 'firstName' },
      { header: 'Last Name', controlName: 'lastName' },
      { header: 'Email Address', controlName: 'email'},
      { header: 'Training', controlName: 'trainingType' },
      { header: 'Course', controlName: 'training' },
      { header: 'Added', controlName: 'dateAdded', displayPipe: 'date' },
      { header: 'End', controlName: 'dateCompleted', displayPipe: 'date' },
      { header: 'Status', controlName: 'trainingStatus' },
      { header: 'Total Time', controlName: 'totalTime' },
      { header: 'Certificate', controlName: 'certificateName',
        actionColumnitems: [
          { 
            buttonAction: 'download', disableOnColumnValue: { column: 'certificateName', values: [null]},
            text: { calculateText: (certificateName: any) => { return certificateName ? certificateName: '-'; }, textColumn: 'certificateName' },
            buttonClass: (certificateName: string) => certificateName === 'Action Required' ? 'btn-warning' : 'btn-default'
          },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    disableflag: false,
    isLoading: false,
    fetchData: false,
  };

  constructor(
    private snackBar: MatSnackBar,
    public lookupsQuery: LookupsQuery,
    public lookupsStore: LookupsStore,
    private learnerMetricsStateService: LearningMetricsService,
    private learenerMetricStateQuery: LearningMetricsQuery,
    private entityCoursesService: EntityCoursesService,
    private subscriptionQuery: SubscriptionQuery,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.lookupsStore.courses();
    this.lookupsStore.trainings();
    this.initGridData();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initGridData() {
    this.gridOptions.fetchData = true;
    var gridDatasource: any[] = [];
    this.learenerMetricStateQuery
      .selectAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe((entities: any) => {
        gridDatasource = [];
        if (entities.length > 0) {
          
          const { rows, totalNumberOfItems } = entities[0];
          this.gridOptions.isLoading = false;
          if (rows?.length > 0) {
            rows.forEach((learner: any) => {
              const datasource = gridDatasource;
              gridDatasource.push(learner);
              gridDatasource = datasource;
            });

          }
          this.gridOptions.data.data = gridDatasource;
          this.gridOptions.totalNumberOfItems = totalNumberOfItems;
        }
      });
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;
    if (!rawParams) {
      rawParams = { pageSize: 20, pageNumber: 1 };
    }
    const { search, fromDate, toDate,  trainingId, courseId, status, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: fromDate ? this.calculateDate(fromDate) : this.calculateDate(new Date(Number(new Date()) - 365 * 24 * 60 * 60 * 1000)),
      ToDate: toDate ? this.calculateToDate(toDate) : this.calculateToDate(new Date()),
      CourseIds: courseId ? [courseId] : [],
      TrainingIds: trainingId ? [trainingId] : [],
      Status: status ? status : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

    this.learnerMetricsStateService.getLearnerMetricsReport(params)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          this.gridOptions.isLoading = false;
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
          return throwError(error);
        })).subscribe();
  }

  sortField(sort?: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'firstName':
          activeSort = 'firstName';
          break;
        case sort.active === 'lastName':
          activeSort = 'lastName';
          break;
        case sort.active === 'email':
          activeSort = 'email';
          break;
        case sort.active === 'trainingType':
          activeSort = 'trainingType';
          break;
        case sort.active === 'training':
          activeSort = 'training';
          break;
        case sort.active === 'dateAdded':
          activeSort = 'dateAdded';
          break;
        case sort.active === 'dateCompleted':
          activeSort = 'dateCompleted';
          break;
        case sort.active === 'trainingStatus':
          activeSort = 'trainingStatus';
          break;
        case sort.active === 'totalTime':
          activeSort = 'totalTime';
          break;
        case sort.active === 'certificateName':
          activeSort = 'certificateName';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  handleButtonAction(element: any) {
    this.entityCoursesService.downloadCert(element?.certificateId);
  }

  calculateDate(val: any) {
    if (val) {
      return new Date(val).toISOString();
    }
  }

  calculateToDate(val: any) {
    if (val) {
      const date = new Date(val).toISOString();
      const newDate: any = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      return newDate.toISOString();
    }
  }

  exportCsv(rawParams: any) {
    this.disableflag = true;
    const { search, fromDate, toDate, trainingId, courseId, status, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: fromDate ? this.calculateDate(fromDate) : '',
      ToDate: toDate ? this.calculateToDate(toDate) : '',
      CourseIds: courseId ? [courseId] : [],
      TrainingIds: trainingId ? [trainingId] : [],
      Status: status ? status : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };
    this.learnerMetricsStateService
      .getExcelLearners(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "File is generated and emailed" }})
          this.disableflag = false;
        },
        (error) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
          this.disableflag = false;
        },
      );
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      this.exportCsv(event.data);
    } else if (event.action === 'formChanges') {
      this.fetchAll(event.data);
    } else if (event.action === 'clearFilters') {
      this.fetchAll();
    } else if (event.action === 'addButton') {
      // add button
    }
    //  'download' is specific to this component 
    else if (event.action === 'download') {
      if (event.data.certificateId == 0) {
        this.modalService.openOrgCertNotIssued();
      } else {
        this.handleButtonAction(event.data);
      }
    }
  }
}
