import { components } from 'src/app/api/interfaces/swagger-types';

export type CoopCert = components['schemas']['CoopCertsReportViewModel'];

export interface CoopCerts {
  coopCertsSignal: CoopCert | null;
  loadingSignal: boolean;
}

export const initialState: CoopCerts = {
  coopCertsSignal: null,
  loadingSignal: false,
};