import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { AuthQuery } from '../../../../routes/authentication/state/auth.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LookupsQuery } from 'src/app/api/services/lookups/lookups.query';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { ResourcesService } from '../../../../routes/org/training/resources/state/resources.service';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';

export type Mode = 'add' | 'edit' | 'delete';

export interface DialogData {
  mode: Mode;
  item?: any;
  canEdit?: boolean;
  deleteId?: any;
  itemName?: string;
}

@UntilDestroy()
@Component({
    selector: 'app-add-resources',
    templateUrl: './add-resources.component.html',
    styleUrls: ['./add-resources.component.scss'],
    standalone: true,
    imports: [
        ModalLayoutComponent,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatInputModule,
        NgFor,
        SnackbarComponent
    ],
})
export class AddResourcesComponent implements OnInit {
  entityGuid!: string;
  public addResources: UntypedFormGroup = Object.create(null);
  mode: Mode;
  uploadedFiles: any[] = [];
  resourceImage: boolean = false;
  resourcesImage = [];
  failImageFlag: boolean = false;
  failSizeFlag: boolean = false;
  failFlag: boolean = false;
  showDisabled: boolean = false;
  maxFileSize = 1024 * 1024;
  fileNameDisplay!: string | null;
  selectedFile: any;
  public myreg = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/;
  typevalue: any;
  viewButton: boolean = false;
  onLoadFile: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddResourcesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private authQuery: AuthQuery,
    public lookupsQuery: LookupsQuery,
    private lookupsStore: LookupsStore,
    private resourceService: ResourcesService
  ) {
    this.mode = data.mode || 'add';
  }

  formatBytes(bytes: any, decimals: any) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  urltoFile(url: string, filename: string, mimeType: string) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  status$ = this.lookupsQuery.entityUserStatus$.pipe(
    take(1),
    map((status) => {
      if (status.length > 0) {
        return status;
      } else {
        return this.lookupsStore.entityUserStatus();
      }
    }),
    switchMap((stat) => {
      if (stat === undefined) {
        return of(null);
      } else {
        return of(stat);
      }
    })
  );

  deleteDocument(item: any) {
    // @ts-ignore
    let index = this.resourcesImage.findIndex((x) => x.id === item.id);
    this.resourcesImage.splice(index, 1);
  }

  ngOnInit(): void {
    this.addResources = this.fb.group({
      status: this.fb.control(null, Validators.required),
      type: this.fb.control(null, Validators.required),
      name: this.fb.control(null, Validators.required),
      url: this.fb.control(null),
      file: this.fb.control(null),
      description: this.fb.control(
        null,
        Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(300)])
      ),
    });
    this.addResources.controls.type.valueChanges
      .pipe(
        tap((val) => {
          if (val === 'File') {
            this.addResources.controls.file.setValidators([Validators.required]);
            this.addResources.controls.file.updateValueAndValidity();
            this.addResources.controls.url.clearValidators();
            this.addResources.controls.url.updateValueAndValidity();
          } else if (val === 'Link') {
            this.addResources.controls.url.setValidators([Validators.required, Validators.pattern(this.myreg)]);
            this.addResources.controls.url.updateValueAndValidity();
            this.addResources.controls.file.clearValidators();
            this.addResources.controls.file.updateValueAndValidity();
          }
        })
      )
      .subscribe();

    if (this.mode === 'edit') {
      this.addResources.patchValue({
        ...this.data.item,
        Status: this.data.item.Statuses,
      });
      if (this.data?.item?.fileName) {
        this.fileNameDisplay = this.data?.item?.fileName;
        this.checkEditFileValidation();
      }
    }
    this.authQuery.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      const { orgAndRoles } = user;
      orgAndRoles.forEach((entity: any) => {
        this.entityGuid = entity.entityGuid;
      });
    });
  }

  checkEditFileValidation() {
    if (this.fileNameDisplay) {
      this.addResources.controls.file.clearValidators();
      this.addResources.controls.file.updateValueAndValidity();
    } else {
      this.addResources.controls.file.setValidators([Validators.required]);
      this.addResources.controls.file.updateValueAndValidity();
    }
  }

  onSubmitResources() {
    if (!this.addResources.valid) {
      this.addResources.markAllAsTouched();
      return;
    }

    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          const control = this.addResources.get(error.field);
          if (control) {
            this.addResources.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
           this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else if (validationErrors.error[''].length) {
        const control = this.addResources.get('email');
        if (control) {
          this.addResources.markAllAsTouched();
          control.setErrors({
            message: validationErrors?.error[''][0],
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${validationErrors?.error[''][0]}` }})
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
      }

      return of(null);
    };
    this.showDisabled = true;
    if (this.data.mode === 'add') {
      const photoFormData = new FormData();
      const formDataRequest = new FormData();

      const form = this.addResources.getRawValue();
      const { status, name, type, url, description, file } = form;
      const formValues = {
        entityGuid: this.entityGuid,
        Status: status,
        Name: name,
        Type: type,
        Url: url,
        Description: description,
        fileName: file,
      };

      formDataRequest.append('Status', formValues.Status);
      formDataRequest.append('Name', formValues.Name);
      formDataRequest.append('Type', formValues.Type);
      formDataRequest.append('Description', formValues.Description);

      if (formValues.Type === 'Link') {
        formDataRequest.append('Url', formValues.Url);
      } else {
        formDataRequest.append('File', this.selectedFile);

      }
      this.resourceService
        .addResources(formDataRequest)
        .pipe(catchError((error) => throwError(error)))
        .subscribe(
          () => {
            const entityGuid: any = window.localStorage.getItem('entityGuid');

            const requestBody = {
              SearchTerm: '',
              Types: [],
              Statuses: [],
            };
            this.resourceService.getAllResources(entityGuid, requestBody).subscribe();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Resource Added' }})
            this.showDisabled = false;
            this.closeModel();
          },
          (error) => {
            validationErrorHandler(error);
            this.showDisabled = false;
          }
        );
    } else if (this.data?.mode === 'edit') {
      const { status, name, type, url, description, file } = this.addResources.value;
      const id = this.data.item?.id;
      const formDataRequest = new FormData();
      const formValues = {
        entityGuid: this.entityGuid,
        id: id,
        Status: status,
        Name: name,
        Type: type,
        Url: url,
        Description: description,
      };
      formDataRequest.append('id', formValues.id);
      formDataRequest.append('Status', formValues.Status);
      formDataRequest.append('Name', formValues.Name);
      formDataRequest.append('Type', formValues.Type);
      formDataRequest.append('Description', formValues.Description);
      if (formValues.Type === 'Link') {
        formDataRequest.append('Url', formValues.Url);
      } else {
        if (!this.fileNameDisplay && !this.onLoadFile) {
          formDataRequest.append('Url', formValues.Url);
          formDataRequest.append('FileName', this.data?.item?.fileName);
        } else {
          formDataRequest.append('File', this.selectedFile);
        }
      }

      this.resourceService
        .addResources(formDataRequest)
        .pipe(catchError((error) => throwError(error)))
        .subscribe(
          () => {
            const entityGuid = window.localStorage.getItem('entityGuid');
            if (!entityGuid) {
              return;
            }

            const requestBody = {
              SearchTerm: '',
              Types: [],
              Statuses: [],
            };
            this.resourceService.getAllResources(entityGuid, requestBody).subscribe();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "Resource Updated" }})
            this.showDisabled = false;
            this.closeModel();
          },
          (error) => {
            validationErrorHandler(error);
            this.showDisabled = false;
          }
        );
    }
  }

  closeModel() {
    this.dialogRef.close();
  }

  removeFile(item: any) {
    this.fileNameDisplay = null;
    this.addResources.get('file')?.setValue(null);
    this.selectedFile = null;
    this.onLoadFile = true;
    this.checkEditFileValidation();
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      if (this.fileErrorCheck(event.target.files[0])) {
        const file = event.target.files[0];
        this.fileNameDisplay = file.name;
        this.addResources.get('file')?.setValue(file.name);
        this.selectedFile = file;
      }
    } else {
      this.fileNameDisplay = null;
      this.addResources.get('file')?.setValue(null);
      this.selectedFile = null;
    }
  }

  fileErrorCheck(file: any) {
    let fileType = file.type;
    let fileSize = file.size;
    if (!fileSize) {
      return false;
    }
    if (
      (fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'image/jpeg' ||
        fileType === 'application/pdf' ||
        fileType === 'application/msword' ||
        fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        fileType === 'application/vnd.ms-excel') &&
      fileSize <= this.maxFileSize
    ) {
      this.failImageFlag = false;
      this.failSizeFlag = false;
      this.failFlag = false;
      return true;
    } else {
      this.failImageFlag = true;
    }
    if (
      !(
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'image/jpeg' ||
        fileType === 'application/pdf' ||
        fileType === 'application/msword' ||
        fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        fileType === 'application/vnd.ms-excel'
      )
    ) {
      this.failFlag = true;
      this.failSizeFlag = false;
      this.failImageFlag = false;
    } else {
      this.failFlag = false;
    }
    if (!(fileSize <= this.maxFileSize)) {
      this.failSizeFlag = true;
      this.failFlag = false;
      this.failImageFlag = false;
    } else {
      this.failSizeFlag = false;
    }
    return false;
  }
}
