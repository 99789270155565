import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TrainingMetricsService } from './state/training-metrics.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LookupsQuery } from 'src/app/api/services/lookups/lookups.query';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { FilterIconComponent } from 'src/app/shared/components/filter-icon/filter-icon.component';

@Component({
    selector: 'app-training-metrics',
    templateUrl: './training-metrics.component.html',
    styleUrls: ['./training-metrics.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', display: 'block' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [
        NgIf,
        MatToolbarModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRippleModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatButtonModule,
        NgClass,
        MatTableModule,
        MatSortModule,
        AsyncPipe,
        SpinnerComponent,
        FilterIconComponent
    ],
})
export class TrainingMetricsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  displayedColumns: string[] = [
    'courseName',
    'trainingType',
    'state',
    'notStartedCount',
    'inProgressCount',
    'completeCount',
    'registrationCount',
    'certsIssuedCount',
  ];
  tableHeading: string[] = [
    'Course',
    'Training',
    'State',
    'Not Started',
    'In Progress',
    'Complete',
    'Total Registrations',
    'Certificates Issued',
  ];
  expandedElement: any | null;
  dataSource!: MatTableDataSource<any>;
  disableflag: boolean = false;
  clearBtnFlag: boolean = true;
  expandElements: any[] = [];
  displayData: any = [];
  noSearchData: boolean = false;
  searchData: any;
  @ViewChild(MatSort) sort!: MatSort;
  filtersForm!: UntypedFormGroup;
  openFilters = false;
  totalNotStartedCourse: number = 0;
  totalIncompleteCourse: number = 0;
  totalCompleteCourse: number = 0;
  totalRegistrations: number = 0;
  totalCertificatesIssued: number = 0;
  


  constructor(
    private fb: UntypedFormBuilder,
    private trainingMetricsService: TrainingMetricsService,
    public lookupsQuery: LookupsQuery,
    private lookupsStore: LookupsStore,
    private subscriptionQuery: SubscriptionQuery,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.spinnerService.whiteBackground();
    const today = new Date();
    const yearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

    this.lookupsStore.states();
    this.lookupsStore.trainings();
    this.lookupsStore.addedCourses();
    this.filtersForm = this.fb.group({
      search: this.fb.control(''),
      state: this.fb.control(''),
      fromDate: this.fb.control(yearAgo || null),
      toDate: this.fb.control(today || null),
      course: this.fb.control(null),
      trainingType: this.fb.control(null),
    });
    this.initGridData();
  }

  initGridData() {
    this.applyFilters();
    this.filtersForm.valueChanges.subscribe((changes) => {
      this.applyFilters();
    });
  }

  applyFilters() {
    this.spinnerService.show();
    this.spinnerService.overlayBackground();
    const criteria = {
      courseIds: this.filtersForm.get('course')?.value ?? [],
      trainingType: this.filtersForm.get('trainingType')?.value ?? '',
      state: this.filtersForm.get('state')?.value ?? [],
      fromDate: this.calculateFromDate(this.filtersForm.get('fromDate')?.value) ?? '',
      toDate: this.calculateToDate(this.filtersForm.get('toDate')?.value) ?? '',
    };

    this.trainingMetricsService
      .getTrainingMetricsReport(criteria)
      .pipe(takeUntil(this.destroy$))
      .subscribe((results) => {
        this.spinnerService.hide();
        if (this.filtersForm.get('search')?.value) {
          const searchResults = Object.values(results).filter((x: any) =>
            x.courseName.toLowerCase().includes(this.filtersForm.get('search')?.value.toLowerCase())
          );
          this.displayData = searchResults;
        } else {

          Object.keys(results).forEach((key) => {

            if (key === 'totalNotStartedCourse') {
              this.totalNotStartedCourse = results[key];
            } else if (key === 'totalIncompleteCourse') {
                this.totalIncompleteCourse = results[key];
            } else if (key === 'totalCompleteCourse') {
                this.totalCompleteCourse = results[key];
            } else if (key === 'totalRegistrations') {
                this.totalRegistrations = results[key];
            } else if (key === 'totalCertificatesIssued') {
                this.totalCertificatesIssued = results[key];
            }
  
          })

          this.displayData = results;
        }
      });
  }

  checkExpanded(element: any): boolean {
    let flag = true;
    this.expandElements.forEach((e: any) => {
      if (e === element) {
        flag = false;
      }
    });
    return flag;
  }

  pushPopElement(element: any) {
    const index = this.expandElements.indexOf(element);
    if (index === -1) {
      this.expandElements.push(element);
    } else {
      this.expandElements.splice(index, 1);
    }
  }

  displayFn(search: any): string {
    return search && search.name ? search.name : search?.length > 0 ? search : '';
  }

  exportCsv() {
    this.disableflag = true;
    const requestBody = {
      FromDate: this.filtersForm.controls?.startDate.value
        ? this.calculateFromDate(this.filtersForm.controls?.startDate.value)
        : '',
      ToDate: this.filtersForm.controls?.endDate?.value
        ? this.calculateToDate(this.filtersForm.controls?.endDate?.value)
        : '',
      CourseIds: [],
    };
  }

  createTabel(trainings: any) {
    let newElement = `
    <thead>
      <tr>  
          <th>
            Training
          </th>
          <th>
          Not Started
         </th>
         <th>
            In progress
          </th>
          <th>
           Complete 
          </th>
          
         
          <th>
           Total Registrations
          </th>
          <th>
            Certificates Issues
          </th>
          
      </tr>
    </thead>
    <tbody>

    ${this.displayData
      .map((data: any) => {
        return `
        <tr>

          <td>
            ${data.courseName}
             
          </td>
          <td>
              ${data.notStartedCount}
            </td>
            <td>
            ${data.inProgressCount}
          </td>
          <td>
            ${data.completeCount}
          </td>
          <td>
            ${data.registrationCount}
          </td>
          <td>
              ${data.certsIssuedCount}
          </td>   
                 
        </tr>  
           ${data?.versionCounts?.map((innerData: any) => {
             return `
               <tr>

               <td>
                 ${innerData?.version}
                  
               </td>
               <td>
                 ${innerData.notStartedCount}
               </td>
               <td>
                 ${innerData.inProgressCount}
               </td>
               <td>
                 ${innerData.completeCount}
               </td>
               <td>
                 ${innerData.registrationCount}
               </td>
               <td>
                   ${innerData.certsIssuedCount}
               </td>   
                      
             </tr>  
               `;
           })}              
        `;
      })
      .join('')}

    </tbody>
    
      `;

    let table = document.createElement('table');
    table.insertAdjacentHTML('afterbegin', newElement);
    return table;
  }

  calculateFromDate(val: any) {
    // convert local date to UTC date
    if (val) {
      const date = new Date(val);
      date.setUTCHours(0, 0, 0, 0);
      const isoDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      return isoDate.toISOString();
    }
  }
  
  calculateToDate(val: any) {
    // convert local date to UTC date
    if (val) {
      const date = new Date(val);
      date.setUTCHours(23, 59, 9, 999);
      const isoDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      return isoDate.toISOString();
    }
  }

  clearFilters() {
    this.filtersForm.reset();
  }

  closeFilter() {
    this.openFilters = !this.openFilters;
  }
}
