<!--
  All modals use this file for base layout.
-->
<div class="modal" [ngClass]="{ 'full-screen-modal': backgroundColor }">
  <div class="flex justify-end items-center">
    <div mat-dialog-close  class="flex items-center btn-exit" >
      <p class="strong mb-0 cursor-pointer">Close</p>
      <mat-icon *ngIf="!disableClose" >close</mat-icon>
    </div>
  </div>
 <div class="modal__content">
    <h2 class="modal__content-title">
      <ng-content select="[title]"></ng-content>
    </h2>
    <div class="modal__content-body">
      <ng-content></ng-content>
    </div>
    <div class="modal__content-buttons">
      <ng-content select="[#button-primary]"></ng-content>
      <ng-content select="[#button-secondary]"></ng-content>
      <ng-content select="[#button-small]"></ng-content>
    </div>
  </div>
</div>