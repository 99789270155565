import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CourseApiService } from 'src/app/api/services/courses/course-api.service';
import { OrganizationService } from '../../../../routes/org/organization/org-profile/state/organization.service';
import { NgIf } from '@angular/common';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';

export interface DialogData {
  buttonAction: 'add-training' | 'archive-training';
  course: any;
  entityGuid: string;
}

@Component({
    selector: 'app-org-course-modal',
    templateUrl: './org-course-modal.component.html',
    styleUrls: ['./org-course-modal.component.scss'],
    standalone: true,
    imports: [ModalLayoutComponent, NgIf, SnackbarComponent],
})
export class OrgCourseModalComponent implements OnInit {
  disableFlag = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<OrgCourseModalComponent>,
    private snackBar: MatSnackBar,
    private organizationService: OrganizationService,
    public courseApiService: CourseApiService,
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  submitButton() {
    this.disableFlag = true;
    if (this.data?.course.isActive) {
      this.organizationService
        .toggleActiveInactive(this.data?.course.id)
        .subscribe({
          next: () => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Training Status Updated' }})
            this.disableFlag = false;
            this.close();
          },
          error: (e) => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error Occured While Updating' }})
            this.disableFlag = false;
            this.close();
          }
      });
    } else {
      this.courseApiService
        .addOrgCourse(this.data?.course.id)
        .subscribe({
          next: () => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Training Added' }})
            this.disableFlag = false;
            this.close();
          },
          error: (e) => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error Occured While Adding Training' }})
            this.disableFlag = false;
            this.close();
          },
        });
    }
  }
}
