import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf, CommonModule } from '@angular/common';
import { FooterComponent } from '../../../shared/components/layouts/footer/footer.component';
// import { AnimationOptions } from "ngx-lottie";
import lottie from 'lottie-web';


@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    standalone: true,
    imports: [
      MatToolbarModule,
      NgIf,
      CommonModule,
      FooterComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ErrorComponent implements OnInit {
  title = "Sorry, we can't seem to find that page."
  errorType = "404 Error";
  joinOrg = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute
    ) {}

  ngOnInit(): void {
  
    this.route.queryParams.subscribe(params => {
      if (params.joinOrg) {
        this.joinOrg = true;
        this.title = "Page is Unavailable"
        this.errorType = "The public page for the Organization is not available."
      }
    });
  }

  ngAfterViewInit() {
    lottie.loadAnimation({
      container: document.getElementById('animation-container') as HTMLElement,
      path: 'assets/animations/animation404.json',
      renderer: 'svg',
      autoplay: true,
      loop: false
    });
    
  }
  goToHome() {
    if (this.joinOrg) {
    window.location.href = 'https://mandatedreportertraining.com/';
    } else {
      this.router.navigateByUrl('/').then();
    }
  }
}
