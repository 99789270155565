import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthQuery } from 'src/app/routes/authentication/state/auth.query';
import { Permissions } from '../../../../routes/authentication/components/services/permissions.service';
import { SwitchEntityProfileQuery } from '../header/state/switch-entity-profile.query';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface Separator {
  name: string;
  type?: string;
}

export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  requiredPermission?: Permissions;
  child?: SubChildren[];
  special?: string;
}

export interface Menu {
  state?: string | null;
  name: string;
  tourSelector: string;
  type: string;
  icon: string;
  expandOnLoad?: boolean;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
  requiredPermission?: Permissions;
  onlyRole?: string;
}

const learnerMenuItems: Menu[] = [
  {
    state: 'my-training',
    tourSelector: 'my-training',
    name: 'My Training',
    type: '',
    icon: 'collections_bookmark',
  },
  {
    tourSelector: 'my-certificates',
    state: 'my-certificates',
    name: 'My Certificates',
    type: '',
    icon: 'verified',
  },
  {
    tourSelector: 'my-history',
    state: 'my-history',
    name: 'My History',
    type: '',
    icon: 'restore',
  },
  {
    tourSelector: 'organization',
    state: 'org-details',
    name: 'Organization',
    type: '',
    icon: 'corporate_fare',
  },
];

const orgAdminMenuItems: Menu[] = [
  {
    tourSelector: '',
    state: 'dashboard',
    name: 'Dashboard',
    type: '',
    icon: 'dashboard',
  },
  { 
    tourSelector: '',
    state: 'groups', 
    name: 'Groups',
    type: '',
    icon: 'group',
     requiredPermission: 'orgAdmin:list'
  },
  { 
    tourSelector: '',
    state: 'learners',
    name: 'Learners',
    type: '',
    icon: 'person',
    requiredPermission: 'orgAdmin:list'
  },
  { 
    tourSelector: '',
    state: 'trainings',
    name: 'Trainings',
    type: '',
    icon: 'save',
    requiredPermission: 'orgAdmin:list'
  },
  {
    tourSelector: '',
    state: 'premium',
    name: 'Premium Features',
    type: 'sub',
    icon: 'home_repair_service',
    children: [
      { state: 'assign', name: 'Assign Training', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'import-learners', name: 'Import Learners', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'join-org', name: 'Join Org Web Page', type: 'link', requiredPermission: 'orgAdmin:list' },
    ],
  },
  {
    tourSelector: '',
    state: 'reports',
    name: 'Reports',
    type: 'sub',
    icon: 'assignment_turned_in',
    children: [
      { state: 'learner-reports', name: 'Learner Reports', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'training-reports', name: 'Training Reports', type: 'link', requiredPermission: 'orgAdmin:list' },
    ],
  },
  {
    tourSelector: '',
    state: 'organization',
    name: 'Organization',
    type: 'sub',
    icon: 'corporate_fare',
    children: [
      { state: 'profile', name: 'Profile', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'account-billing', name: 'Account & Billing', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'resources', name: 'Resources', type: 'link', requiredPermission: 'orgAdmin:list' },
    ],
  },
  {
    tourSelector: '',
    state: null,
    name: 'My Training',
    type: 'sub',
    icon: 'local_library',
    children: [
      { state: 'my-training', name: 'My Trainings', type: 'link' },
      { state: 'my-certificates', name: 'My Certificates', type: 'link' },
      { state: 'my-history', name: 'My History', type: 'link' },
    ],
  },
];

const coopAdminMenuItems: Menu[] = [
  {
    tourSelector: '',
    state: 'coop/dashboard',
    name: 'Dashboard',
    type: '',
    icon: 'dashboard',
  },
  {
    tourSelector: '',
    state: 'coop/profile',
    name: 'Organization Profile',
    type: '',
    icon: 'corporate_fare',
  },
  {
    tourSelector: '',
    state: 'coop/trainings',
    name: 'Trainings',
    type: '',
    icon: 'collections_bookmark',
  },
  {
    tourSelector: '',
    state: 'coop/team',
    name: 'Team',
    type: '',
    icon: 'groups',
  },
  {
    tourSelector: '',
    state: 'coop/certificates',
    name: 'Certificates',
    type: '',
    icon: 'assignment_turned_in',
  },
];

@Injectable({ providedIn: 'root' })
export class MenuItems {
  constructor(
    private authQuery: AuthQuery,
    private switchEntityProfileQuery: SwitchEntityProfileQuery,
  ) {}
  getMenuitem() {
    return combineLatest([this.authQuery.user$, this.switchEntityProfileQuery.activeOrg$]).pipe(
      map(([user, activeOrg]) => {
        if (user?.orgAndRoles) {
          let entityRoleNames: any[] = [];
          if (activeOrg) {
            entityRoleNames = activeOrg.roles;
            if (entityRoleNames.length > 0) {
              if (entityRoleNames.includes('EntityAdmin')) {
                if (activeOrg.isOffsite) {
                  var menuItemsWithOffsite = orgAdminMenuItems.map((item) => {
                    if (item.state === 'reports' && !item.children?.some(c => c.special === 'offsite')) {
                      item.children?.push({
                        state: 'offsite-certs',
                        name: 'Offsite LMS Certs',
                        type: 'link',
                        requiredPermission: 'orgAdmin:list',
                        special: 'offsite',
                      });
                    }
                    return item;
                  });
                  return menuItemsWithOffsite;
                }

                return orgAdminMenuItems;
              } else if (entityRoleNames.includes('CoopAdmin')) {
                return coopAdminMenuItems;
              } else {
                // remove the org details menu item if the user is in an Individual org
                if (activeOrg.entityType !== 'Organization') {
                  const learnerMenuItemsCopy = learnerMenuItems.map((item) => {
                    if (item.state !== 'org-details') {
                      return item;
                    }
                  });
                  return learnerMenuItemsCopy;
                }

                return learnerMenuItems;
              }
            }
          }
        }
        return [];
      }),
    );
  }
}
