import { patchState, signalStore, withMethods, withState, } from '@ngrx/signals';
// import { Login, initialState } from './login.model';
import { components } from "../../interfaces/swagger-types";

export type LoginModel = components['schemas']['UserProfileViewModel'];

export interface Login {
  loginSignal: LoginModel[] | null;
  loadingSignal: boolean;
  dontCallAgainSignal: boolean;
}

export const initialState: Login = {
  loginSignal: null,
  loadingSignal: false,
  dontCallAgainSignal: false,
};

export const LoginStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(loginSignal: Login["loginSignal"]) {
      patchState(store, {loginSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    setDontCallAgain(dontCallAgain: boolean) {
      patchState(store, {dontCallAgainSignal: dontCallAgain});
    },
    addLogin(login: LoginModel) {
      const currentLogins = store.loginSignal() || [];
      if (currentLogins.some((item) => item.id === login.id)) {
        return;
      }
      patchState(store, { loginSignal: [...currentLogins, login] });
    },
    update(id: number, login: Partial<LoginModel>) {
      patchState(store, {
        loginSignal: (store.loginSignal() || []).map((item) =>
          item.id === id ? { ...item, ...login } : item
        ),
      });
    },
    remove(id: number) {
      patchState(store, {
        loginSignal: (store.loginSignal() || []).filter((item) => item.id !== id),
      });
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)