// IMPORTS
import { Component, OnInit, OnDestroy, Input, effect, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LookupsQuery } from 'src/app/api/services/lookups/lookups.query';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { BatchInvitesService } from 'src/app/api/services/batch-invites/batch-invites.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { ImportUsersComponent } from 'src/app/routes/org/team/import-learners/import-learners-editor/import-users.component';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { ImportUsersModalComponent, DialogData } from 'src/app/shared/components/modals/import-users-modal/import-users-modal.component';
import { catchError, takeUntil, throwError, Subject } from 'rxjs';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { Router } from '@angular/router';
import { BoxyMessageComponent, BoxyMessageOptions } from 'src/app/shared/components/boxy-message/boxy-message.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { BatchInvitesStore } from 'src/app/api/services/batch-invites/batch-invites.store';


// COMPONENT
@UntilDestroy()
@Component({
    selector: 'app-batch-invites',
    templateUrl: './import-learners-grid.component.html',
    styleUrls: ['./import-learners-grid.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        MRTGridTemplateComponent,
        SnackbarComponent,
        BoxyMessageComponent
    ],
})
export class ImportLearnersGridComponent implements OnInit, OnDestroy {
  readonly batchInviteStore = inject(BatchInvitesStore);
  @Input() boxyMessage: BoxyMessageOptions = {
    title: '',
    message: '',
    linkText: '',
    link: '',
    buttonText: '',
    disableButton: false,
  }
  destroy$: Subject<boolean> = new Subject<boolean>();
  public upgradeRequired = true;
  isEnterprise: boolean = false;
  hasActiveSubscription: boolean = false;
  disableflag: boolean = false;
  defaultSortColumn: string = 'createDate';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsQuery?.batchProcessStatus$ },
    ],
    openFilters: false,
    columns: [
      { header: 'Create Date', controlName: 'createDate', displayPipe: 'date' },
      { header: 'Batch Name', controlName: 'batchName' },
      { header: 'Status', controlName: 'batchStatus' },
      { header: 'Number of Users', controlName: 'numberOfUsers' },
      { header: 'Send Date', controlName: 'dateSent', displayPipe: 'date' },
      { header: 'Action', controlName: 'action',
        actionColumnitems: [
          { icon: 'cancel', buttonAction: 'cancel',
            disableOnColumnValue: {column: 'batchStatus', values: ['Cancelled']},
            hideOnColumnValue: {column: 'batchStatus', values: ['Processed']}
          },
          { icon: 'download', buttonAction: 'download',
            hideOnColumnValue: {column: 'batchStatus', values: ['Confirmed', 'InProcess', 'Pending', 'Success', 'Failed', 'Omitted', 'Cancelled']}
          },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    frontEndPagination: false,
    displaySearch: false,
  };

  // CONSTRUCTOR
  constructor(
    private snackBar: MatSnackBar,
    public lookupsQuery: LookupsQuery,
    public lookupsStore: LookupsStore,
    private batchInvitesService: BatchInvitesService,
    private subscriptionQuery: SubscriptionQuery,
    private modalService: ModalService,
    private router: Router,
  ) {
    var gridDatasource: any[] = [];
    effect(() => {
      gridDatasource = [];
      const all = this.batchInviteStore.batchInvitesSignal() ?? {};
      if (all.totalNumberOfItems != null) {
        const { rows, totalNumberOfItems } = all;
        rows?.forEach((row: any) => {
          const datasource = gridDatasource;
          datasource.push(row);
          gridDatasource = datasource;
        });
        this.gridOptions.data.data = gridDatasource;
        this.gridOptions.totalNumberOfItems = totalNumberOfItems;
      }
    });
  }
  

  // ONINIT
  ngOnInit(): void {
    this.boxyMessage = {
      ... this.boxyMessage,
      title: 'Import Learners',
      message: "This premium feature allows you to bulk import learners and assign them to groups. Use this import feature to easily invite multiple new learners to your organization all at once. ",
      linkText: 'Find out more.',
      link: 'https://mandatedreportertraining.zendesk.com/hc/en-us/articles/29725816300443-Import-Learners',
      buttonText: 'View Pricing',
    }
    this.subscriptionQuery.select().subscribe((subscription) => {
      if (subscription?.usage) {
        if(subscription?.usage.isEnterprise) {
          this.isEnterprise = true;
        }
        if(subscription?.usage.hasActiveSubscription) {
          this.hasActiveSubscription = true;
        }

        this.gridOptions.isLoading = false;
        if (!subscription?.usage.requiresUpgrade) {
          this.upgradeRequired = false;
          this.initGridData();
        } else {
          this.upgradeRequired = true;
        }
      }
    });
  }

  // ONDESTROY
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // ACTIONS
  // DIALOG TO DISPLAY THE EXISTING IMPORT USERS COMPONENT
  openDialog(options: {
    mode: 'add' | 'edit' | 'delete';
    componentType: 'import-users';
    item?: any;
    deleteId?: any;
    itemName?: string;
    batchName?: string;
    sendDate?: any;
  }) {
    const { mode, componentType, item, deleteId, itemName } = options;
    const data: any = { mode, item, deleteId, itemName };

    if (componentType === 'import-users') {
      this.modalService.open(
        ImportUsersComponent,
        {
          modalType: ModalType.FullScreen,
          data,
        },
        () => {
          const entityGuid = window.localStorage.getItem('entityGuid');
          if (!entityGuid) {
            return;
          }
          this.fetchAll();
        },
      );
    }
  }

  // ACTIONS
  // MODAL TO DISPLAY THE CONFIRM CANCEL COMPONENT
  openModal(row: any, action: any) {
    if (action === 'confirm-cancel' && row.batchStatus !== 'Cancelled') {
      this.modalService.openConfirmModal(
        'Are you sure you want to cancel this batch invite? All invitiations will be cancelled and you will need to re-upload the file to invite learners.',
        () => {
          this.batchInvitesService.cancelBatchInvite(row.batchId).subscribe(() => {
            this.fetchAll();
            this.modalService.close();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "Batch invite cancelled" }})
            
          },
          (e) => {
            this.modalService.close();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: e.error.error }})
          },);
        },
      );
    }
  }

  openImport(options: {
    mode: 'add' | 'edit' | 'delete';
    componentType: 'users';
    item?: any;
    deleteId?: any;
    itemName?: string;
    activeSubscription?: boolean;
    modalType?: string;
  }) {
    if (this.upgradeRequired) {
      return;
    }
    const { mode, componentType, item, deleteId, itemName, activeSubscription, modalType } = options;
    const data: DialogData = { mode, item, deleteId, itemName, activeSubscription, modalType };
    if (componentType === 'users') {
      this.modalService.open(
        ImportUsersModalComponent,
        {
          modalType: ModalType.FullScreen,
          data,
        },
        () => this.gridOptions.fetchData = true,
      );
    }
  }

  // INIT GRID DATA
  initGridData() {
    this.lookupsStore.batchProcessStatus();
    this.fetchAll();
  }

  // FETCH GRID DATA
  fetchAll(rawParams?: any) {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }

    this.gridOptions.isLoading = true;
    if (!rawParams) {
      rawParams = { pageSize: 20, pageNumber: 1 };
    }
    const { search, status, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      BatchStatus: status ? status : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

    this.batchInvitesService.getBatchInvitesReport(params)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          this.gridOptions.isLoading = false;
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
          return throwError(error);
        })).subscribe();
  }

  sortField(sort?: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'orgName':
          activeSort = 'orgName';
          break;
        case sort.active === 'createDate':
          activeSort = 'createDate';
          break;
        case sort.active === 'batchName':
          activeSort = 'batchName';
          break;
        case sort.active === 'batchStatus':
          activeSort = 'batchStatus';
          break;
        case sort.active === 'sendDate':
          activeSort = 'dateSent';
          break;
        case sort.active === 'processedDate':
          activeSort = 'processedDate';
          break;
        case sort.active === 'numberOfUsers':
          activeSort = 'numberOfUsers';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  // EXPORT AND EMAIL
  exportCsv(row: any, action: any) {
    this.disableflag = true;
    this.batchInvitesService.emailbatchFile(row.batchId).subscribe(
      () => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "File is generated and emailed" }})
        this.disableflag = false;
      },
      (error) => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
        this.disableflag = false;
      },
    );
  }


  onBoxyButtonClick() {
    this.modalService.openPricingModal(this.hasActiveSubscription);
  }


  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      this.fetchAll(event.data);
    } else if (event.action === 'clearFilters') {
      this.fetchAll();
    } else if (event.action === 'addButton') {
      this.openImport({ mode: 'add', componentType: 'users', modalType: 'import' });
    }
    // 'cancel' 'download' is specific to this component 
    else if (event.action === 'cancel') {
      this.openModal(event.data, 'confirm-cancel');
    } else if (event.action === 'download') {
      this.exportCsv(event.data, 'batchInvite-download');
    }
  }
}
