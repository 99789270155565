import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api/services/api.service';
import { catchError } from 'rxjs/operators';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of, throwError } from 'rxjs';
import { BlockCopyPasteDirective } from '../../../../shared/directives/block-copy-paste/block-copy-paste.directive';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgClass } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { Location } from '@angular/common';

function equalTo(key: string, confirmationKey: string) {
  return (group: any) => {
    const input = group.controls[key];
    const confirmationInput = group.controls[confirmationKey];
    return confirmationInput.setErrors(input.value !== confirmationInput.value ? { equalTo: true } : null);
  };
}

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgClass,
    MatIconModule,
    BlockCopyPasteDirective,
    SnackbarComponent,
  ],
})
export class ResetComponent implements OnInit {
  public form: UntypedFormGroup = Object.create(null);
  disableFlag: boolean = false;
  hide = true;
  chide = true;
  private userEmail!: string;
  private totpGuid!: string;
  private confirmationToken!: string;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _api: ApiService,
    private snackBar: MatSnackBar,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        newPassword: this.fb.control(null, Validators.required),
        confirmPassword: this.fb.control(null, Validators.compose([Validators.required])),
      },
      { validator: [equalTo('newPassword', 'confirmPassword')] },
    );

    this.route.queryParams.subscribe(params => {
      this.confirmationToken = params['confirmationToken'];
      this.userEmail  = params['email']?.replace(' ', '+');

      if(Object.keys(params).length == 0) {
        const routeData = this.location.getState();
          if (routeData) {
            this.userEmail = routeData['email'];
            this.totpGuid = routeData['totpGuid'];

            if (!this.userEmail || !this.totpGuid) {
              this.router.navigateByUrl('/login');
            }
          } else {
            this.router.navigateByUrl('/login');
          }
      }
    
    })

  }

  onSubmit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      var responseErrors = validationErrors?.error?.expiredTOTP.length ? validationErrors?.error?.expiredTOTP : validationErrors?.error?.other.length ? validationErrors?.error?.other : validationErrors;
      if (responseErrors.length) {
        responseErrors.forEach((error: any) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else if (error?.field === 'password') {
            const control = this.form.get('newPassword');
            if (control) {
              this.form.markAllAsTouched();
              control.setErrors({
                message:
                  'The Password does not meet requirements: minimum of 6 characters and contain at least one of each of the following: uppercase letter, lowercase letter, special character, and number.',
              });
            }
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: `${error.message ?? error}` },
            });
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Error, message: 'Error occured' },
        });
      }
      return of(null);
    };
    if (this.form.valid) {
      const password = this.form.get('newPassword')?.value;

      if(this.confirmationToken ) {
        this._api.auth
        .resetPassword(this.userEmail, password, this.confirmationToken)
        .pipe(catchError((error) => throwError(error)))
        .subscribe(
          () => {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Success, message: 'Password Reset Successfully!' },
            });
            this.router.navigate(['/login']);
            this.disableFlag = false;
          },
          (error) => {
            this.disableFlag = false;
            validationErrorHandler(error);
          },
        );

      } else { 

        this._api.auth
          .totpResetPassword(this.userEmail, password, this.totpGuid)
          .pipe(catchError((error) => throwError(error)))
          .subscribe(
            () => {
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Success, message: 'Password Reset Successfully!' },
              });
              this.router.navigate(['/login']);
              this.disableFlag = false;
            },
            (error) => {
              this.disableFlag = false;
              validationErrorHandler(error);
              if (error.error?.expiredTOTP) {
                this.router.navigate(['/forgot']);
              }
            },
          );
      }

    }
  }
}
