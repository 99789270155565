import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TotpService, TotpType } from 'src/app/api/services/TOTP/totp.service';
import { AddUsersService } from 'src/app/routes/org/team/users/add-users/state/add-users.service';

export enum VerifyType {
  VerifyEmail = 1,
  VerifyForgotPassword = 2,
  VerifyJoinRequest = 3,
}

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxMaskDirective,
  ],
})
export class VerifyEmailComponent implements OnInit {
  public form: UntypedFormGroup = Object.create(null);
  public disableFlag: boolean = false;
  userEmail!: string;
  private verifyType!: VerifyType;
  private formData!: any;
  private totpGuid!: string;
  disableResend: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private location: Location,
    private totpService: TotpService,
    private snackBar: MatSnackBar,
    private addUsersService: AddUsersService,
  ) {}

  ngOnInit(): void {
    this.disableFlag = true;
    const routeData = this.location.getState();
    if (routeData) {
      this.userEmail = routeData['email'];
      this.totpGuid = routeData['totpGuid'];
      this.verifyType = routeData['verifyType'];
      this.formData = routeData['formData'];
    }

    this.form = this.fb.group({
      email: [this.userEmail],
      verificationCode: ['', [Validators.required, Validators.maxLength(6)]],
    });

    this.form.valueChanges.subscribe(() => {
      this.disableFlag = this.form.invalid;
    });
  }

  onSubmit(): void {
    this.disableFlag = true;
    if (this.form.valid) {
      switch (this.verifyType) {
        case VerifyType.VerifyForgotPassword:
          // for resetting password
          this.totpService.totpValidate(this.userEmail, this.form.value.verificationCode).subscribe({
            next: () => {
              this.router.navigateByUrl('/reset', { state: { email: this.userEmail, totpGuid: this.totpGuid } });
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: {
                  toastType: ToastType.Success,
                  message: 'Verification Complete. You can now reset your password',
                },
              });
            },
            error: () => {
              this.disableFlag = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Error, message: 'TOTP code is invalid, please try again.' },
              });
            },
          });
          break;
        case VerifyType.VerifyEmail:
          this.totpService.totpConfirmEmail(this.userEmail, this.form.value.verificationCode).subscribe({
            next: () => {
              this.router.navigateByUrl('/login');
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Success, message: 'Email Confirmed.' },
              });
            },
            error: () => {
              this.disableFlag = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Error, message: 'TOTP code is invalid, please try again.' },
              });
            },
          });
          break;
        case VerifyType.VerifyJoinRequest:
          const form = {
            email: this.userEmail,
            code: this.form.value.verificationCode,
            entityGuid: this.formData.entityGuid,
            groupIds: this.formData.groupIds,
          };
          this.addUsersService.joinOrgUser(form).subscribe({
            next: (res: any) => {
              this.router.navigate([`/accept-invitation-confirmed`], {
                queryParams: {
                  joinRequestGuid: res.joinRequestGuid,
                  email: this.userEmail,
                },
              });
            },
            error: (error: any) => {
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Error, message: 'Join request failed.' },
              });
              this.disableFlag = false;
            },
          });

          break;
      }
    }
  }

  resendTotp(): void {
    this.totpService.totpResend(this.userEmail, this.verifyType).subscribe({
      next: (totpGuid) => {
        if (typeof totpGuid === 'string' && totpGuid) {
          this.totpGuid = totpGuid;
        }
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Success, message: 'Verification Code Resent' },
        });

        this.disableResend = true;
        setTimeout(() => {
          this.disableResend = false;
        }, 3000);
      },
      error: () => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Error, message: 'Something went wrong, please try again.' },
        });
      },
    });
  }
}
