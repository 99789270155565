import { patchState, signalStore, withMethods, withState, } from '@ngrx/signals';
import { NewFeatures, initialState } from './new-feature.model';

export const NewFeatureStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(newFeatureSignal: NewFeatures["newFeatureSignal"]) {
      patchState(store, {newFeatureSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)