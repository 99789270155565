import { Component, Inject } from '@angular/core';
import { NgIf, NgFor } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterLink, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';

@UntilDestroy()
@Component({
  selector: 'app-new-features',
  templateUrl: './new-features.component.html',
  styleUrls: ['./new-features.component.scss'],
  standalone: true,
  imports: [
    ModalLayoutComponent,
    NgIf,
    NgFor,
    RouterLink
  ]
})
export class NewFeaturesComponent {
  currentFeatureIndex = 0;
  featureButtonText = 'Next Feature';
  features = [
    {
      imageUrl: '',
      title: '',
      description: '<p></p>',
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<NewFeaturesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {
    this.features = data[0].features;
   }
  
  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  nextFeature() {
    if (this.currentFeatureIndex < this.features.length - 1) {
      this.currentFeatureIndex++;
    } else {
      this.close();
    }
    if (this.currentFeatureIndex === this.features.length - 1) {
      this.featureButtonText = 'Close';
    }
  }
  
  isActiveDot(index: number): boolean {
    return index === this.currentFeatureIndex;
  }

  routeToLink(link: string) {
    if (link.includes('http')) {
      window.open(link, '_blank');
    } else {
      this.router.navigateByUrl(link);
      this.close();
    }
  }

}
