import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ApiService } from 'src/app/api/services/api.service';
import { catchError, tap } from 'rxjs/operators';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of, throwError, takeUntil, Subject } from 'rxjs';
import { RegistrationService } from '../components/register/registration.service';
import { environment } from 'src/environments/environment';
import { InviteAcceptConfirmedService } from 'src/app/api/services/invite-accept-confirmed/invite-accept-confirmed.service';
import { AuthService } from '../state/auth.service';
import { NgIf, NgClass } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GrecaptchaService } from 'src/app/shared/services/grecaptcha.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { VerifyType } from '../components/verify-email/verify-email.component';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgClass,
    RouterLink,
    SnackbarComponent,
  ],
})
export class ForgotComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public form: UntypedFormGroup = Object.create(null);
  verifyEmail: boolean = false;
  inviteEmail: boolean = false;
  disableFlag: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private _api: ApiService,
    private snackBar: MatSnackBar,
    private registrationService: RegistrationService,
    private inviteService: InviteAcceptConfirmedService,
    private authService: AuthService,
    private reCaptchaService: GrecaptchaService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: this.fb.control(null, Validators.compose([Validators.required, Validators.email])),
    });

    this.form
      .get('email')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.verifyEmail = false;
        this.inviteEmail = false;
      });
  }

  onSubmit() {
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      this.disableFlag = false;
      if (validationErrors.length) {
        validationErrors.forEach((error) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: `${error.message}` },
            });
          }
        });
      } else if (validationErrors.error) {
        const errors = Object.keys(validationErrors.error);
        if (errors.length) {
          if (errors[0] === 'reCaptcha') {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: {
                toastType: ToastType.Error,
                message:
                  'Error occurred during reCAPTCHA validation. If this problem persists, click the Help button for additional assistance.',
              },
            });
          } else {
            const control = this.form.get('email');
            if (control) {
              this.form.markAllAsTouched();
              if (validationErrors.error.user) {
                if (validationErrors.error.user[0] != 'User does not exist') {
                  this.verifyEmail = true;
                  control.setErrors({
                    message: validationErrors?.error.user[0],
                  });
                }

                this.snackBar.open(`${validationErrors?.error.user[0]}`, 'Dismiss', { duration: 3000 });
              }
              if (validationErrors.error.invite) {
                this.inviteEmail = true;
                control.setErrors({
                  message: validationErrors?.error.invite[0],
                });

                this.snackBar.open(`${validationErrors?.error.invite[0]}`, 'Dismiss', { duration: 3000 });
              }
            }
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Error, message: 'Error occured' },
          });
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Error, message: 'Error occured' },
        });
      }
    };

    const email = this.form.get('email')?.value;
    const resetPasswordUrl = window.origin + '/reset';

    this.registrationService.validEmail(email).subscribe((data: any) => {
      this.reCaptchaService.execute('forgotPassword').then((reCaptchaToken) => {
        this._api.auth
          .recoverPassword({ email, resetPasswordUrl, reCaptchaToken })
          .pipe(catchError((error) => throwError(error)))
          .subscribe({
            next: (totpGuid) => {
              const email = this.form.get('email')?.value;
              const forgot = true;
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Success, message: 'Verification Email Sent' },
              });
              this.router.navigateByUrl('/verify-email', {
                state: { email, forgot, totpGuid, verifyType: VerifyType.VerifyForgotPassword },
              });
            },
            error: (error) => validationErrorHandler(error),
          });
      });
    });
  }

  resendEmail() {
    const formValues = {
      email: this.form?.get('email')?.value?.trim(),
      fullName: '',
    };
    this.authService
      .verify(formValues)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.router.navigateByUrl(`/verify?email=${this.form?.get('email')?.value?.trim()}&forgot=false`);
        },
      });

    this.verifyEmail = false;
  }

  resendInvite(email: any) {
    const acceptUrl = `${environment?.domainName}/resend-invite`;
    this.inviteService.resendInvite(email, acceptUrl).subscribe({
      next: () => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Success, message: 'Email sent' },
        });
        this.router.navigateByUrl(`/verify?email=${this.form.get('email')?.value}&forgot=false`);
      },
      error: (err) => {
        this.form.reset();
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Error, message: 'Error occured' },
        });
      },
    });

    this.inviteEmail = false;
  }

  close() {
    this.router.navigateByUrl(`/login`);
  }
}
