import { patchState, signalStore, withMethods, withState, } from '@ngrx/signals';
import { LearnerMetrics, initialState } from './learner-metrics.model';

export const LearnerMetricsStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(learnerMetricsSignal: LearnerMetrics["learnerMetricsSignal"]) {
      patchState(store, {learnerMetricsSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)