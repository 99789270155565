import { Injectable } from '@angular/core';
import { NgxRolesService } from 'ngx-permissions';

export type Permissions =
  | 'learner:edit'
  | 'learner:view'
  | 'learner:list'
  | 'orgAdmin:edit'
  | 'orgAdmin:view'
  | 'orgAdmin:list'
  | 'orgAdmin:tour'
  | 'coopAdmin:edit'
  | 'admin';
export const adminPermissions: Permissions[] = ['admin'];
export const learnerPermissions: Permissions[] = ['learner:view', 'learner:list'];
export const entityAdminPermissions: Permissions[] = ['orgAdmin:edit', 'orgAdmin:view', 'orgAdmin:list', 'orgAdmin:tour'];
export const coopAdminPermissions: Permissions[] = ['coopAdmin:edit'];

export type UserRole = 'Learner' | 'OrgAdmin' | 'CoopAdmin' | 'Admin' | 'LeadSupport';

const permissionsByUserRole: { [Key in UserRole]: Permissions[] } = {
  Learner: learnerPermissions,
  OrgAdmin: entityAdminPermissions,
  CoopAdmin: coopAdminPermissions,
  Admin: adminPermissions,
  LeadSupport: adminPermissions,
};

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private ngxRolesService: NgxRolesService) {}

  applyRoles(roles: UserRole[]) {
    const rolesWithPermissions: any = {};
    roles.map((role) => {
      rolesWithPermissions[role] = permissionsByUserRole[role];
    });
    this.ngxRolesService.addRolesWithPermissions(rolesWithPermissions);
  }
  

  flushRoles() {
    this.ngxRolesService.flushRolesAndPermissions();
  }
}
