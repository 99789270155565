


<div class="w-full">
  <div class="w-full lg:w-auto">
      <h2 class="py-5">Annual Plans</h2>
      <ng-container *ngFor="let plan of planList">
        <ng-container *ngIf="plan.selected">
            <div class="payment-card">
              <form [formGroup]="form">
                <div>
                  <h3 >Select A Plan</h3>
                  <div class="mt-4">
                    <mat-label class="sr-only">Select A Plan</mat-label>
                    <mat-select formControlName="productId" (selectionChange)="selectPlan($event)" class="mat-select-payment">
                      <mat-option *ngFor="let plan of planList" [value]="plan.productId">
                        {{ plan.dropdownName }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="mt-4">
                    <h4 >{{ plan.productName }}</h4>
                    <p class="mb-4">{{ plan.productDescription }}</p>  
                  </div>
                </div>
                <div>
                  <div class="flex items-end pt-4">
                      <span class="h2">{{ plan.priceAmount / 100 | currency: 'USD' : 'symbol' : '1.2-2' }} </span>
                      <p class="pl-3 small">per year</p>

                      <span *ngIf="plan.savings != ''">
                        <span class="px-1">|</span>
                        <strong>{{ plan.savings }}</strong>
                    </span>
                  </div>
                  <div class="mt-4">
                      <button type="submit" class="btn-primary large" [id]="getBuyNowButtonId()" (click)="getPlanClicked()">Buy Now</button>
                  </div>
                </div>
              </form>
              </div>
          </ng-container>
      </ng-container>
  </div> 

  <div class="w-full lg:w-auto mt-4">
      <h3>Annual Plan Features</h3>
      <ul class="ul-reset checkmark-ul pt-3">
        <li>Certificates Roll Over Year to Year</li>
        <li>Unlimited Access to Course Catalog</li>
        <li>Reporting &amp; Metrics</li>
        <li>Downloadable Reports</li>
        <li>Assign Training to Your Learners</li>
        <li>Invite Users with Bulk Import</li>
        <li>Public Landing Page Enables Learners to Associate with Organization</li>
      </ul>
  </div>
</div>    
