import { components } from 'src/app/api/interfaces/swagger-types';

export type BatchInvite = components['schemas']['BatchInviteGridViewModel'];

export interface BatchInvites {
  batchInvitesSignal: BatchInvite | null;
  loadingSignal: boolean;
}

export const initialState: BatchInvites = {
  batchInvitesSignal: null,
  loadingSignal: false,
};
