<div [formGroup]="filtersForm">
  <mat-toolbar class="mb-5 pt-2 lg:pb-0 justify-center mat-toolbar-multi-row px-0" style="background: transparent;">
    <div class="flex flex-col w-full">
      <div [ngClass]="gridOptions.addButtonName ? 'flex justify-between' : ''" class="px-4">
        <div *ngIf="gridOptions.addButtonName">
          <a class="flex primary-link cursor-pointer items-center" (click)="addButton()">
            <mat-icon class="custom-mat-icon">add</mat-icon>
            <p class="btn btn-icon-text font-bold medium">{{gridOptions.addButtonName}}</p>
          </a>
        </div>
        <div class="flex flex-row justify-end items-center" *ngIf="hasFilters">
          <div class="flex flex-row flex-wrap">
            <div [ngStyle]="{ width: !smallScreen? 1/visibleFormControlData.length * 100 +'%': '100%' }"
            *ngFor="let filter of visibleFormControlData; let i = index"
            >
              <ng-container *ngIf="i === 0 && filter.type === 'date'">
                <mat-form-field class="datePicker-2 smaller-combo-box mr-4">
                  <mat-label>{{filter.name}}</mat-label>
                  <input matInput [formControlName]="filter.formControlName" [matDatepicker]="picker1" />
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="i === 1 && filter.type === 'date'">
                <mat-form-field class="datePicker-2 smaller-combo-box mr-4">
                  <mat-label>{{filter.name}}</mat-label>
                  <input matInput [formControlName]="filter.formControlName" [matDatepicker]="picker2" />
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                </mat-form-field>
              </ng-container>
            </div>
          </div>

          <a class="flex primary-link cursor-pointer ml-6 items-center" (click)="closeFilter()">
            <app-filter-icon></app-filter-icon>
          </a>

        </div>
      </div>
  
      <div class="flex justify-between items-center leading-none lg:gap-4 w-full flex-col lg:flex-row px-4 bg-color-lightBlue mt-4"  *ngIf="gridOptions.openFilters">
        <div class="lg:w-1/4 w-full text-[16px]" *ngIf="searchFormControlData">
          <mat-form-field class="w-full" hideRequiredMarker>
            <mat-label>{{searchFormControlData.name}}</mat-label>
            <input 
              matInput
              [placeholder]="searchFormControlData.name"
              #input
              [formControlName]="searchFormControlData.formControlName"
            />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>

        <div class="lg:w-3/4 flex justify-end lg:gap-4 w-full flex-col lg:flex-row">
          <div [ngStyle]="{ 'width.%': !smallScreen ? filter.type === 'checkBox' ? null : 1/(hiddenFormControlData.length )* 100: 100 }"
            [ngClass]=" filter.type === 'checkBox' ? 'flex items-center' : 'flex flex-col'"
            *ngFor="let filter of hiddenFormControlData"
          >
            <ng-container *ngIf="filter.type === 'dropdown'">
              <mat-form-field class="w-full">
                <mat-label>{{filter.name}}</mat-label>
                <mat-select [formControlName]="filter.formControlName" panelClass="mat-select-mt3">
                  <mat-option [value]="null">All</mat-option>
                  <ng-container *ngIf="!isArray(filter.lookup); else notObservable">
                    <mat-option *ngFor="let item of filter.lookup | async" [value]="item.value"
                      >{{ item.label }}
                    </mat-option>
                  </ng-container>
                  <ng-template #notObservable>
                    <mat-option *ngFor="let item of filter.lookup" [value]="item.value"
                      >{{ item.label }}
                    </mat-option>
                  </ng-template>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="filter.type === 'searchDropdown'">
              <mat-form-field class="w-full">
                <input type="text" [placeholder]="filter.name" matInput [formControlName]="filter.formControlName" [matAutocomplete]="auto" />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayOptions"
                  class="custom-autocomplete-size"
                >
                  <mat-option *ngFor="let option of filter.lookup" [value]="option">
                    {{ option?.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="filter.type === 'checkBox'">
              <mat-checkbox [formControlName]="filter.formControlName">{{filter.name}}</mat-checkbox>
            </ng-container>
          </div>
          <div class="flex items-center justify-center">
            <button *ngIf="!gridOptions.frontEndPagination && false" class="btn-outline-small" (click)="searchButton()">
              Search
            </button>
            <div class="lg:pl-3">
              <button type="button" class="primary-link opacity-100" (click)="clearFilters()">
                <mat-icon> highlight_off</mat-icon>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </mat-toolbar>

  <ng-template [ngIf]="smallScreen" [ngIfElse]="bigScreen">
    <ng-container *ngIf="mobileData.length > 0">
      <div class="mtable mt-5" *ngFor="let row of mobileData">
        <div class="mtable__body" *ngIf="gridOptions.columns.length > 0">
          <div class="mtable__flex" *ngFor="let column of gridOptions.columns">
            <div class="mtable__left">{{column.header}}</div>
            <div class="mtable__right" 
              *ngIf="!column.actionColumnitems && !column.displayPipe">
              {{ !row[column.controlName] ? '-' : row[column.controlName]}}
            </div>
            <div class="mtable__right" 
              *ngIf="!column.actionColumnitems && column.displayPipe ==='truncate'">
              {{ !row[column.controlName] ? '-' : (row[column.controlName] | truncateText: 25) }}
            </div>
            <div class="mtable__right" 
              *ngIf="!column.actionColumnitems && column.displayPipe ==='date'">
              {{ !row[column.controlName] ? '-' : (utcToLocalDate(row[column.controlName]) | date : 'MM/dd/yy') }}
            </div>
            <div class="mtable__right" 
              *ngIf="!column.actionColumnitems && column.displayPipe ==='currency'">
              {{ !row[column.controlName] ? '-' : (row[column.controlName] | currency) }}
            </div>
            <div class="mtable__right" 
              *ngIf="!column.actionColumnitems && column.displayPipe ==='joinList'">
              {{ (!row[column.controlName] || row[column.controlName].length === 0) ? '-' : row[column.controlName].join(', ') }}
            </div>
            <div class="mtable__right" 
              *ngIf="column.actionColumnitems"
              [ngClass]="column.actionColumnitems.length > 1 ?? 'flex gap-2'">
              <div class="flex items-center">
                <button
                  *ngFor="let action of column.actionColumnitems"
                  type="button"
                  class="btn-action"
                  [hidden]="!action.hideOnColumnValue ? false :
                    action.hideOnColumnValue.values.includes(row[action.hideOnColumnValue.column])"
                  [disabled]="!action.disableOnColumnValue ? false : 
                    action.disableOnColumnValue.values.includes(row[action.disableOnColumnValue.column])"
                  (click)="actionButton(row, action.buttonAction)"
                >
                  <mat-icon *ngIf="action.icon" class="disabled">{{action.icon}}</mat-icon>
                  <mat-icon *ngIf="!action.icon && !action.text && action.conditionalIcon" class="disabled">{{action.conditionalIcon.calculateIcon(row[action.conditionalIcon.iconColumn])}}</mat-icon>
                  <span *ngIf="!action.icon && action.text && !action.conditionalIcon" class="disabled">{{action.text.calculateText(row[action.text.textColumn])}}</span>
                  <span *ngIf="!action.icon && !action.text && !action.conditionalIcon && !action.imageOnColumnValue" class="disabled">{{column.controlName}}</span>
                 

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="flex my-4 w-full px-2 justify-end items-center custom-pagination" *ngIf="!gridOptions.hidepagination">
      <div class="flex items-center mat-paginator-page-size">
        <div class="custom-pagination-label">Items per page:</div>
        <div [formGroup]="paginationForm">
          <mat-form-field class="mat-paginator-page-size-select">
            <mat-select formControlName="pageSize">
              <mat-option *ngFor="let pageSize of pageSizeOptions" [value]="pageSize">
                {{ pageSize }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="flex custom-pagination-buttons">
        <div class="">
          <span>Items {{ itemsFrom }}-{{ itemsTo }} of {{ totalNumberOfItems }}</span>
        </div>
        <mat-icon class="cursor-pointer select-none" (click)="paginationLeft()">chevron_left</mat-icon>
        <div>
          <span>{{ currentPageNumber }}</span>
          <span>of</span>
          <span>{{ totalNumberOfPages }}</span>
        </div>
        <mat-icon class="cursor-pointer select-none" (click)="paginationRight()">chevron_right</mat-icon>
      </div>
    </div>
    <ng-container *ngIf="!gridOptions.data.data.length">
      <div class="mt-5 px-4">No data</div>
    </ng-container>
  </ng-template>

  <ng-template #bigScreen>
    <mat-card class="grid-table custom-mat-card p-0 sm:px-4  pb-0">
      <mat-card-content class="wrap-anywhere">
        <table mat-table (matSortChange)="sortData($event)" [dataSource]="gridOptions.frontEndPagination ? gridOptions.data : gridOptions.data.data" class="w-full table-responsive" matSort>
          <ng-container [matColumnDef]="column.controlName" *ngFor="let column of gridOptions.columns">
            <ng-template [ngIf]="!column.actionColumnitems && !column.displayPipe">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-bold">{{column.header}}</th>
              <td mat-cell *matCellDef="let row">
                {{ !row[column.controlName] ? '-' : row[column.controlName] }}
              </td>
            </ng-template>
            <ng-template [ngIf]="!column.actionColumnitems && column.displayPipe ==='truncate'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-bold">{{column.header}}</th>
              <td mat-cell *matCellDef="let row">
                {{ !row[column.controlName] ? '-' : (row[column.controlName] | truncateText: 25) }}
              </td>
            </ng-template>
            <ng-template [ngIf]="!column.actionColumnitems && column.displayPipe === 'date'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-bold">{{column.header}}</th>
              <td mat-cell *matCellDef="let row">
                {{ !row[column.controlName] ? '-' : (row[column.controlName] | date : 'MM/dd/yy') }}
              </td>
            </ng-template>
            <ng-template [ngIf]="!column.actionColumnitems && column.displayPipe === 'currency'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-bold">{{column.header}}</th>
              <td mat-cell *matCellDef="let row">
                {{ !row[column.controlName] ? '-' : (row[column.controlName] | currency) }}
              </td>
            </ng-template>
            <ng-template [ngIf]="!column.actionColumnitems && column.displayPipe === 'joinList'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-bold">{{column.header}}</th>
              <td mat-cell *matCellDef="let row">
                {{ (!row[column.controlName] || row[column.controlName].length === 0) ? '-' : row[column.controlName].join(', ') }}
              </td>
            </ng-template>
            <ng-template [ngIf]="column.actionColumnitems">
              <th mat-header-cell *matHeaderCellDef class="font-bold">{{column.header}}</th>
              <td mat-cell *matCellDef="let row" [ngClass]="column.actionColumnitems.length > 1 ?? 'flex gap-2'">
                <div class="flex items-center">
                  <button
                    *ngFor="let action of column.actionColumnitems"
                    type="button"
                    class="btn-action"
                    [ngClass]="action.buttonClass ? action.buttonClass(row.certificateName) : ''"
                    [hidden]="!action.hideOnColumnValue ? false :
                      action.hideOnColumnValue.values.includes(row[action.hideOnColumnValue.column])"
                    [disabled]="!action.disableOnColumnValue ? false : 
                      action.disableOnColumnValue.values.includes(row[action.disableOnColumnValue.column])"
                    (click)="actionButton(row, action.buttonAction)"
                  >
                    <mat-icon *ngIf="action.icon" class=" disabled">{{action.icon}}</mat-icon>
                    <mat-icon *ngIf="!action.icon && !action.text && action.conditionalIcon" class=" disabled">{{action.conditionalIcon.calculateIcon(row[action.conditionalIcon.iconColumn])}}</mat-icon>
                    <span *ngIf="!action.icon && action.text && !action.conditionalIcon"  class=" disabled">{{action.text.calculateText(row[action.text.textColumn])}}</span>
                    <span class="sr-only">{{action.icon}}</span>
                    <span *ngIf="!action.icon && !action.text && !action.conditionalIcon && !action.imageOnColumnValue" class=" disabled">{{column.controlName}}</span>
                    <img *ngIf="!action.imageOnColumnValue ? false : action.imageOnColumnValue.values.includes(row[action.imageOnColumnValue.column])" [src]="action.imageOnColumnValue.imgURL"/>

                  </button>
                </div>
              </td>
            </ng-template>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-pointer hover:bg-lightgrey"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data</td>
          </tr>
        </table>
        <div class="flex flex-col sm:flex-row w-full">
          <div class="flex items-center w-1/2">
            <a *ngIf="gridOptions.allowExportCSV" (click)="!disableCSV ? exportCsv() : $event.preventDefault()"  class="primary-link"  disabled="{{disableCSV}}" [ngClass]="{'disabled': disableCSV}">Export to CSV</a>
          </div>
          <div class="flex w-1/2 justify-end items-center custom-pagination p-0 gap-1" *ngIf="!gridOptions.hidepagination">
            <div class="flex items-center mat-paginator-page-size">
              <div class="custom-pagination-label">Items per page:</div>
              <div [formGroup]="paginationForm">
                <mat-form-field class="mat-paginator-page-size-select">
                  <mat-select formControlName="pageSize">
                    <mat-option *ngFor="let pageSize of pageSizeOptions" [value]="pageSize">
                      {{ pageSize }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="flex custom-pagination-buttons">
              <div class="">
                <span>Items {{ itemsFrom }}-{{ itemsTo }} of {{ totalNumberOfItems }}</span>
              </div>
              <mat-icon class="cursor-pointer select-none" (click)="paginationLeft()">chevron_left</mat-icon>
              <div>
                <span>{{ currentPageNumber }}</span>
                <span>of</span>
                <span>{{ totalNumberOfPages }}</span>
              </div>
              <mat-icon class="cursor-pointer select-none" (click)="paginationRight()">chevron_right </mat-icon>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>

</div>
<mat-paginator *ngIf="gridOptions.frontEndPagination" [length]="100" [pageSize]="20" [pageSizeOptions]="pageSizeOptions" aria-label="Select page" [disabled]="true" class="hidden">
</mat-paginator>