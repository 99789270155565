<app-modal-layout>
  <div title>
    {{
    data?.buttonAction === 'add-training'
    ? 'Reactivate Training'
    : data?.buttonAction === 'archive-training'
    ? 'Archive Training'
    : ''
    }}
  </div>

  <p *ngIf="data?.buttonAction === 'archive-training'">
    Archiving <strong>{{ data?.course.name }}</strong> will remove from the available trainings in your
    organization. Any learners that have previously added this training will retain their Training History and your
    reports will continue to display metrics associated with this training.
  </p>
  <p class="pt-4">
    Click the
    {{
    data?.buttonAction === 'add-training'
    ? 'Reactivate Training'
    : data?.buttonAction === 'archive-training'
    ? 'Archive Training'
    : ''
    }}
    button below to proceed
  </p>

  <div class="text-center mt-4">
    <button class="btn-primary large" (click)="submitButton()" [disabled]="disableFlag">
      {{
      data?.buttonAction === 'add-training'
      ? 'Reactivate Training'
      : data?.buttonAction === 'archive-training'
      ? 'Archive Training'
      : ''
      }}
    </button>
  </div>
</app-modal-layout>