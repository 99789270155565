import { patchState, signalStore, withMethods, withState, } from '@ngrx/signals';
import { CoopCerts, initialState } from './coop-certs.model';

export const CoopCertsStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(coopCertsSignal: CoopCerts["coopCertsSignal"]) {
      patchState(store, {coopCertsSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)