import { NgIf, NgFor, NgClass, AsyncPipe, KeyValuePipe } from '@angular/common';
import { Component, Input, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { LookupsQuery } from 'src/app/api/services/lookups/lookups.query';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';

@Component({
  selector: 'app-user-cascading-dropdowns',
  templateUrl: './user-cascading-dropdowns.component.html',
  styleUrls: ['./user-cascading-dropdowns.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    NgClass,
    MatOptionModule,
    MatInputModule,
    AsyncPipe,
    KeyValuePipe
  ]
})
export class UserCascadingDropdownsComponent implements OnInit {
  @Input() form!: UntypedFormGroup;
  inputValue: string = '';
  isSelectLanguage: boolean = false;
  isEntityAdmin!: boolean;
  isCoopAdmin!: boolean;
  isIndividual!: boolean;
  showfields!: boolean;
  notTrainingPage!: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public lookupsQuery: LookupsQuery,
    private lookupsStore: LookupsStore
  ) { }

  ngOnInit() {
    this.lookupsStore.states();
    this.lookupsStore.industries();
    this.lookupsStore.languages();
    if (this.form.get('language')) {
      this.isSelectLanguage = true;
    }
    if (!window.location.pathname.includes('training')) {
      this.notTrainingPage = true;
      this.isIndividual = false;
      this.isEntityAdmin = false;
      this.isCoopAdmin = false;
    } else {
      this.isIndividual = localStorage.getItem('entityType') === 'Individual' && !this.data.forcedLearner;
      this.isEntityAdmin = localStorage.getItem('roleType') === 'EntityAdmin' && !this.data.forcedLearner;
      this.isCoopAdmin = localStorage.getItem('roleType') === 'CoopAdmin' && !this.data.forcedLearner;
    }
    this.showfields = this.isEntityAdmin || this.isIndividual || this.isCoopAdmin || this.notTrainingPage;
    this.lookupsStore.states();
    this.lookupsStore.industries();

    // professions are based on industry selection
    this.form.get('industryId')?.valueChanges.subscribe((industryId) => {
      this.form.get('professionId')?.reset();
      if (industryId) {
        this.lookupsStore.professions(industryId);
      }
    });

    // job titles are based on profession selection
    this.form.get('professionId')?.valueChanges.subscribe((professionId) => {
      if (professionId) {
        this.lookupsStore.jobTitles(professionId);
      } else {
        this.lookupsStore.update({ jobTitles: [] });
      }
    });

    this.lookupsQuery.professionsHasValue$.subscribe((hasValue) => {
      if (hasValue) {
        this.form.get('professionId')?.enable();
      }
    });
  }

}
