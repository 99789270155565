import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { LocalDateFormatPipe } from 'src/app/shared/pipes/local-date-format/local-date-format.pipe';
import { LearnerMetricsStore } from './learner-metrics.store';

@Injectable({ providedIn: 'root' })
export class LearnerMetricsService {
  learnerMetricsStore = inject(LearnerMetricsStore);
  constructor(
    private apiService: ApiService,
    private localDateFormatPipe: LocalDateFormatPipe
  ) {}

  getLearnerMetricsReport(requestParams: any) {
    this.learnerMetricsStore.setLoading(true);
    return this.apiService.learnerMetricsService.getLearningMetricsReport(requestParams).pipe(
      tap((entities: any) => {
        const { rows, totalNumberOfItems } = entities;
        const newRows = rows?.map((row: any) => {
          const { dateAdded, dateCompleted, ...remaining } = row;
          const splitDateAdded = dateAdded.split('T')[0];
          const splitDateCompleted = dateCompleted?.split('T')[0];
          return {
            dateAdded: this.localDateFormatPipe.transform(splitDateAdded),
            dateCompleted: dateCompleted ? this.localDateFormatPipe.transform(splitDateCompleted) : '',
            ...remaining,
          };
        });
        const newEntities = {
          rows: newRows,
          totalNumberOfItems,
        };
        this.learnerMetricsStore.setAll(newEntities);
        this.learnerMetricsStore.setLoading(false);
      })
    );
  }
  getExcelLearners(requestedBody: any) {
    return this.apiService.learnerMetricsService.exportLearningMetricsReport(requestedBody);
  }
}