import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { HttpClient } from '@angular/common/http';
import { NewFeatureStore } from './newFeature.store';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewFeatureService extends BaseAPI {
  constructor(
    http: HttpClient,
    private newFeatureStore: NewFeatureStore,
  ) {
    super(http);
  }

  getNewFeatures() {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.get(`/NewFeature/${entityGuid}`).pipe(
      tap((newFeature) => {
        if (newFeature)
         this.newFeatureStore.set([newFeature]);
      }),
    );
  }
}
