import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, AfterContentInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  Validators,
  ValidatorFn,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxPermissionsService } from 'ngx-permissions';
import { of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsQuery } from 'src/app/api/services/lookups/lookups.query';
import { OrganizationService } from './state/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { SwitchEntityProfileQuery } from 'src/app/shared/components/layouts/header/state/switch-entity-profile.query';
import { FileUploader, FileUploadModule } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMaskDirective  } from 'ngx-mask';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';

export function WhiteSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value && value.trim().length === 0) {
      return { whiteSpace: true };
    }
    return null;
  };
}

interface Product {
  value: string;
  viewValue: string;
}
@UntilDestroy()
@Component({
  selector: 'app-organization',
  templateUrl: './org-profile.component.html',
  standalone: true,
  imports: [
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatIconModule,
    MatCheckboxModule,
    FileUploadModule,
    AsyncPipe,
    NgxMaskDirective,
    SpinnerComponent,
    SnackbarComponent,
    MatRadioModule,
    MatTabsModule
  ],
})
export class OrganizationComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  entityId!: number;
  public hasSubscription = false;
  disableFlag: boolean = false;
  isSuperAdmin: boolean = false;
  isIndividual: boolean = false;
  showProfileImagePic: boolean = false;
  requiredImage: boolean = false;
  failImageFlag: boolean = false;
  failSizeFlag: boolean = false;
  failFlag: boolean = false;
  localImageUrl: any;
  profileImagePic: any;
  maxFileSize = 1024 * 1024;
  loader: boolean = false;
  logoURL: any;

  @ViewChild('selectedPicture') selectedPicture: any;
  clearSelectedPicture() {
    this.selectedPicture.nativeElement.value = '';
    this.showProfileImagePic = true;
    this.localImageUrl = this.profileImagePic;
  }
  public photoUploader: FileUploader = new FileUploader({
    url: '',
    allowedMimeType: ['image/png', 'image/jpeg'],
    maxFileSize: this.maxFileSize,
  });

  constructor(
    private fb: UntypedFormBuilder,
    private organizationService: OrganizationService,
    public lookupsQuery: LookupsQuery,
    public lookupsService: LookupsService,
    private lookupsStore: LookupsStore,
    private snackBar: MatSnackBar,
    public permissionsService: NgxPermissionsService,
    public switchEntityProfileQuery: SwitchEntityProfileQuery,
    public sanitizer: DomSanitizer,
    private spinnerService: SpinnerService,
    private subscriptionQuery: SubscriptionQuery,
    private modalService: ModalService
  ) {}

  industries$ = this.lookupsQuery.industries$.pipe(
    map((industries) => {
      if (industries.length > 0) {
        return industries;
      } else {
        return this.lookupsStore.industries();
      }
    }),
    switchMap((indus) => {
      if (indus === undefined) {
        return of(null);
      } else {
        return of(indus);
      }
    }),
  );
  states$ = this.lookupsQuery.states$.pipe(
    map((states) => {
      if (states.length > 0) {
        return states;
      } else {
        return this.lookupsStore.states();
      }
    }),
    switchMap((state) => {
      if (state === undefined) {
        return of(null);
      } else {
        return of(state);
      }
    }),
  );
  permissions$ = this.permissionsService.permissions$;
  URLreg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  form = this.fb.group({
    name: this.fb.control('', Validators.compose([Validators.required, WhiteSpaceValidator()])),
    website: this.fb.control(null, [Validators.pattern(this.URLreg)]),
    industryId: this.fb.control(null),
    stateId: this.fb.control(null, Validators.required),
    hrContact: this.fb.control(null),
    hrEmail: this.fb.control(null, Validators.email),
    hrPhone: this.fb.control(null),
    shareableCertificate: this.fb.control(null),
    publicLandingPageEnabled: this.fb.control(null),
    landingPageShortUrl: this.fb.control(null),
    slug: this.fb.control(null, [Validators.maxLength(50)]),
    whiteListedDomains: this.fb.control([]),
    dueDate: this.fb.control(null),
    setAsDefault: this.fb.control(null),
    autoAssignCourses: this.fb.control(false),
    multiFactorEnabled: this.fb.control(null),
  });
  
  sharedExternalCertificatesDate: any[] = [
    {
      value: true,
      name: 'Enabled',
    },
    {
      value: false,
      name: 'Disabled',
    },
  ];

  openDialog(options: { componentType: 'delete-item' }) {
    const { componentType } = options;
    const data: any = {};
    if (componentType === 'delete-item') {
    }
  }

  loadOrg() {
    this.spinnerService.whiteBackground();
    this.spinnerService.show();
    let entityGuid: any = '';
    if (window.localStorage.getItem('entityGuid')) {
      entityGuid = window.localStorage.getItem('entityGuid');
    }
    return this.switchEntityProfileQuery.activeOrg$.pipe(
      untilDestroyed(this),
      tap((activeOrg) => {
        if (activeOrg) {
          if (activeOrg.setActive) {
            const entityRoleNames = activeOrg.roles.map((entity: any) => {
              return entity;
            });

            if (activeOrg.entityType === 'Individual') {
              this.isIndividual = true;
              this.form.enable();
            } else if (activeOrg.entityType === 'Organization') {
              if (!entityRoleNames.includes('EntityAdmin')) {
                this.form.disable();
              } else {
                this.form.enable();
              }
            }
          }
        }
      }),
      switchMap((user) => {
        return this.organizationService.getOrg(entityGuid);
      }),
      tap((data: any) => {
        this.entityId = data.id;
        const { industryId, stateId, ...remaining } = data;
        const items = [industryId, stateId];

        const result = items.map((item) => {
          if (item === 0 || item === '0') {
            return null;
          } else {
            return item;
          }
        });

        this.logoURL = data?.logoUrl;

        if (
          data?.logoUrl?.includes('.pjp') ||
          data?.logoUrl?.includes('.jpg') ||
          data?.logoUrl?.includes('.jpeg') ||
          data?.logoUrl?.includes('.jfif') ||
          data?.logoUrl?.includes('.pjpeg') ||
          data?.logoUrl?.includes('.png')
        ) {
          this.localImageUrl = data?.logoUrl?.trim();
          this.profileImagePic = data?.logoUrl?.trim();
          this.showProfileImagePic = true;
        } else {
          this.showProfileImagePic = false;
        }
        
        const autoAssignCourses = Boolean(data?.autoAssignCourses);
        const formValues = {
          industryId: result[0],
          stateId: result[1],
          ...remaining,
          autoAssignCourses
        };
        this.form.patchValue(formValues);
        this.spinnerService.hide();
      }),
    );
  }

  ngOnInit(): void {
    this.subscriptionQuery.select().subscribe((subscription) => {
      if (subscription?.usage) {
        if (!subscription?.usage.hasActiveSubscription) {
          this.hasSubscription = false;
        } else {
          this.hasSubscription = true;
        }
      }
    });
    this.loadOrg().pipe(takeUntil(this.destroy$)).subscribe();
    this.photoUploader.onAfterAddingFile = (fileItem) => {
      this.requiredImage = false;
      if (this.photoUploader.queue.length > 1) {
        this.photoUploader.queue.splice(0, 1);
      }

      let url = window.URL
        ? window.URL.createObjectURL(fileItem._file)
        : (window as any).webkitURL.createObjectURL(fileItem._file);
      this.localImageUrl = this.sanitizer.bypassSecurityTrustUrl(url);

      this.failImageFlag = false;
      this.failSizeFlag = false;
      this.failFlag = false;
      this.showProfileImagePic = true;

      const imageFromData = new FormData();
      this.photoUploader.queue.map((file) => {
        imageFromData.append('file', file._file, file.file.name);
      });
      this.form.markAsDirty();
      this.loader = true;
      this.organizationService
        .uploadImage(imageFromData)
        .pipe(
          catchError((e) => throwError(() => e)),
          tap((url: string) => {
            if (
              url?.includes('.pjp') ||
              url?.includes('.jpg') ||
              url?.includes('.jpeg') ||
              url?.includes('.jfif') ||
              url?.includes('.pjpeg') ||
              url?.includes('.png')
            ) {
              this.showProfileImagePic = true;
              this.localImageUrl = url;
            }
          }),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: () => {
            this.loader = false;
          },
          error: (e) => {
            this.loader = false;
            this.showProfileImagePic = false;
          },
        });
    };

    this.photoUploader.onWhenAddingFileFailed = (fileItem) => {
      this.form.markAsDirty();
      let fileType = fileItem.type;
      let fileSize = fileItem.size;
      if (
        (fileType === 'image/png' ||
          fileType === 'image/jpeg' ||
          fileType === 'image/pjp' ||
          fileType === 'image/jpg' ||
          fileType === 'image/jfif' ||
          fileType === 'image/pjpeg') &&
        fileSize <= this.maxFileSize
      ) {
        this.failImageFlag = false;
        this.failSizeFlag = false;
        this.failFlag = false;
      } else {
        this.failImageFlag = true;
        this.photoUploader.clearQueue();
        if (this.showProfileImagePic) {
          this.localImageUrl = this.profileImagePic;
        }
      }

      if (
        !(
          fileType === 'image/png' ||
          fileType === 'image/jpeg' ||
          fileType === 'image/pjp' ||
          fileType === 'image/jpg' ||
          fileType === 'image/jfif' ||
          fileType === 'image/pjpeg'
        )
      ) {
        this.failFlag = true;
        this.failSizeFlag = false;
        this.failImageFlag = false;
        this.photoUploader.clearQueue();
        if (this.showProfileImagePic) {
          this.localImageUrl = this.profileImagePic;
        }
      } else {
        this.failFlag = false;
      }

      if (!(fileSize <= this.maxFileSize)) {
        this.failSizeFlag = true;
        this.failFlag = false;
        this.failImageFlag = false;
        this.photoUploader.clearQueue();
        if (this.showProfileImagePic) {
          this.localImageUrl = this.profileImagePic;
        }
      } else {
        this.failSizeFlag = false;
      }
    };
    this.permissions$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  update() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.disableFlag = true;

    const validationErrorHandler = (validationErrors: ValidationErrors) => {
      if (validationErrors.length) {
        validationErrors.forEach((error) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
           this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } 
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
      }

      return of(null);
    };
    
    let entityGuid: any = '';
    if (window.localStorage.getItem('entityGuid')) {
      entityGuid = window.localStorage.getItem('entityGuid');
    }

    const formValues = {
      ...this.form.value,
      entityGuid: entityGuid,
      id: this.entityId,
      logoUrl: this.localImageUrl,
    };
    this.organizationService
    .updateOrg(formValues)
    .pipe(
      catchError((error) => throwError(error)),
      takeUntil(this.destroy$),
    )
    .subscribe(
      () => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "Organization Updated" }})
        this.disableFlag = false;
        this.loadOrg().pipe(takeUntil(this.destroy$)).subscribe();
      },
      (error) => {
        validationErrorHandler(error);
        this.disableFlag = false;
      },
    );
  }
}
