<app-modal-layout>
  <div class="lg:mx-24 md:mx-16">
    <h1 class="learner text-center">Assign Trainings</h1>
    <h2 class="learner font-bold text-center py-3.5">{{ data.name }} | {{ data.email }}</h2>

    <h2 class="learner font-bold text-left py-2.5">Training</h2>
    <p class="text-left">Select from available organization trainings below.</p>
    <div class="lg:p-5">
      <div class="grid grid-rows-columns lg:ml-auto lg:mr-auto justify-center gap-8">
        <div
          class="w-full h-full transform transition lg:hover:scale-110"
          *ngFor="let course of organizationQuery.activeCourses$ | async"
        >
          <div class="flex flex-col h-full">
            <div
              class="p-3 flex-1 sm:transform sm:transition mobile-choose-course flex items-center justify-center bg-color-lightBlue py-lg text-center cursor-pointer relative choose-course h-full mx-auto sm:mx-0 w-full"
              [ngClass]="
                course?.alreadyUserCourses
                  ? 'course-active-element disabled'
                  : course?.editCourse
                  ? 'course-active-element'
                  : ''
              "
              (click)="selectCourse(course)"
            >
            <div [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : 'text-primary'">
              <div>
                <div *ngIf="course?.isCE" class="flex text-left justify-start items-center">
                  <p class="small">
                    <mat-icon class="round-active-text font-weight-bold leading-none"
                      [ngClass]="course?.alreadyUserCourses || course?.editCourse ? 'check-icon-text-outline text-secondary': 'check-icon-text-filled text-primary'"
                    >done</mat-icon>
                    <span [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : 'text-primary'">CE Approved</span>
                  </p>
                </div>
              </div>
              <div class="flex">
                <p class="bold"
                [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : ''">
                  {{ course?.trainingHeader}}
                  {{ course?.state !== 'NATL' ? ' | ' + course?.state : ''}}
                </p>
              </div>
              <h2 class="learner leading-none font-semibold capitalize text-left"
                [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : ''">{{ course.name }}</h2>
              <p class="text-left mb-0 mt-3" [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : ''"
                >{{course?.moduleNames.length}} {{course?.moduleNames.length !== 1 ? 'Modules:': 'Module:'}} {{course?.moduleNames.join(', ')}}</p>
              <mat-icon
                class="check-icon-text-filled round-active-icon-text font-weight-bold leading-none text-primary"
              >
                done
              </mat-icon>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="learner font-bold text-left py-2.5">Due Date</h2>
    <p class="text-left">Select the date you would like the training to be due. (Optional).</p>
    <form [formGroup]="form">
      <mat-form-field class="datePicker-2 smaller-combo-box max-w-xs">
      <mat-label>Due Date</mat-label>
      <input matInput formControlName="dueDate" [matDatepicker]="picker1" [matDatepickerFilter]="futureDatesFilter"/>
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1 disabled="false"></mat-datepicker>
      <mat-error *ngIf="form.get('dueDate')?.hasError('invalidDate')">Please select a future date.</mat-error>
      </mat-form-field>
      <h2 class="learner font-bold text-left py-2.5">Email Notification</h2>
      <p class="text-left">Would you like to notify the user by email of this session assignment?</p>
      <mat-radio-group formControlName="email">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </form>

    <div class="text-center">
      <button
        type="button"
        [disabled]="addCoursesBtn || disabled"
        class="btn-primary medium"
        (click)="assignCourses()"
      >
        Assign Training
      </button>
    </div>
  </div>
</app-modal-layout>

