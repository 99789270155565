import { Injectable } from '@angular/core';
import { ApiModule } from '../api.module';
import { AuthApiService } from './auth/auth-api.service';
import { LookupsService } from './lookups/lookups.service';
import { RegistrationService } from './registration/registration.service';
import { OrganizationsApiService } from './organizations/organizations-api.service';
import { CoursePlayerService } from './course-player/course-player.service';
import { UserGroupsService } from './userGroups/user-groups.service';
import { CourseApiService } from './courses/course-api.service';
import { ResourcesService } from './resources/resources.service';
import { LearnerMetricsApiService } from './learner-metrics/learner-metrics-api.service';
import { TrainingMetricsService } from './training-metrics/training-metrics.service';
import { InviteAcceptConfirmedService } from './invite-accept-confirmed/invite-accept-confirmed.service';
import { UserStateService } from './user-state/user-state.service';
import { UserInvitesService } from './user-invites/user-invites.service';
import { ImportUserService } from './import-user/import-user.service';
import { BatchInvitesApiService } from './batch-invites/batch-invites-api.service';
import { CoopCertsApiService } from './coop/coop-certs-api.service';
import { DashboardApiService } from './dashboard/dashboard-api.service';

@Injectable({ providedIn: ApiModule })
export class ApiService {
  constructor(
    public auth: AuthApiService,
    public lookups: LookupsService,
    public registration: RegistrationService,
    public organization: OrganizationsApiService, //
    public coursePlayer: CoursePlayerService,
    public userGroup: UserGroupsService,
    public courseApiService: CourseApiService,
    public resourcesService: ResourcesService,
    public learnerMetricsService: LearnerMetricsApiService, // Done
    public trainingMetricsService: TrainingMetricsService,
    public inviteAcceptConfirmedService: InviteAcceptConfirmedService,
    public userStateService: UserStateService,
    public userInvitesService: UserInvitesService,
    public importUserService: ImportUserService,
    public batchInvitesService: BatchInvitesApiService, // Not working
    public coopCertsService: CoopCertsApiService, // Maybe Working
    public dashboardService: DashboardApiService,
  ) {}
}
