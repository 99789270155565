import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DashboardStore } from './dashboard.store';
import { ApiService } from 'src/app/api/services/api.service';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(
    private dashboardStore: DashboardStore,
    private api: ApiService,
  ) {}

  getDashboardData(criteria: any) {
    return this.api.dashboardService.getDashboardData(criteria).pipe(
      tap((data: any) => {
        this.dashboardStore.update({entityAdminDashboard: data});
      }),
    );
  }

  getLearnerInviteAcceptRatio(criteria: any) {
    return this.api.dashboardService.getLearnerInviteAcceptRatio(criteria).pipe(
      tap((data: any) => {
        this.dashboardStore.update({learnerInviteAcceptRatio: data});
      }),
    );
  }

  getLearnerProgressCertificates(criteria: any) {
    return this.api.dashboardService.getLearnerProgressCertificates(criteria).pipe(
      tap((data: any) => {
        this.dashboardStore.update({learnerProgressCertificates: data});
      }),
    );
  }
}
