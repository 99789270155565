import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, of, throwError } from 'rxjs';
import { catchError, take, tap, map, switchMap, takeUntil } from 'rxjs/operators';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { EditUsersService } from '../state/edit-users.service';
import { CoursesQuery } from 'src/app/routes/learner/courses/state/courses.query';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DialogData, UserResetPasswordComponent } from '../../../../../shared/components/modals/user-reset-password/user-reset-password.component';
import { LookupsQuery } from 'src/app/api/services/lookups/lookups.query';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { OrganizationQuery } from 'src/app/routes/org/organization/org-profile/state/organization.query';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CourseApiService } from 'src/app/api/services/courses/course-api.service';
import { UserGroupsService } from '../../user-groups/state/user-groups.service';
import { AddUsersService } from '../add-users/state/add-users.service';
import { PaymentService } from 'src/app/api/services/payment/payment.service';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { FormSelectComponent } from '../../../../../forms/components/form-select/form-select.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BlockCopyPasteDirective } from '../../../../../shared/directives/block-copy-paste/block-copy-paste.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet, NgIf, NgFor, NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { SwitchEntityProfileQuery } from 'src/app/shared/components/layouts/header/state/switch-entity-profile.query';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { EntityCoursesService } from 'src/app/routes/learner/courses/components/state/entity-courses.service';
import { AssignTrainingComponent } from 'src/app/shared/components/modals/assign-training/assign-training.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { LearnerMetricsStore } from 'src/app/api/services/learner-metrics/learner-metrics.store';

@Component({
    selector: 'app-edit-users',
    templateUrl: './edit-users.component.html',
    styleUrls: ['./edit-users.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        MatTabsModule,
        NgTemplateOutlet,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        BlockCopyPasteDirective,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        FormSelectComponent,
        NgClass,
        MatIconModule,
        MatDatepickerModule,
        MatButtonModule,
        MatTableModule,
        AsyncPipe,
        DatePipe,
        SnackbarComponent,
        MRTGridTemplateComponent,
    ],
})
export class EditUsersComponent implements OnInit, OnDestroy {
  learnerMetricsStore = inject(LearnerMetricsStore);
  destroy$: Subject<boolean> = new Subject<boolean>();
  userId!: string;
  smallScreen!: boolean;
  disableFlag: boolean = false;
  hasActivityInSubscriptionYear: boolean = true;
  userName!: string;
  intMax: number = 2147483647;
  gridOptions: GridOptions = {
    filterData: [],
    openFilters: false,
    columns: [
      { header: 'Training', controlName: 'trainingType' },
      { header: 'Course', controlName: 'training' },
      { header: 'Added', controlName: 'dateAdded', displayPipe: 'date' },
      { header: 'End', controlName: 'dateCompleted', displayPipe: 'date' },
      { header: 'Total Time', controlName: 'totalTime' },
      { header: 'Certificate', controlName: 'certificateName',
        actionColumnitems: [
          { 
            buttonAction: 'download', disableOnColumnValue: { column: 'certificateName', values: [null]},
            text: { calculateText: (certificateName: any) => { return certificateName ? certificateName: '-'; }, textColumn: 'certificateName' },
          },
          { 
            buttonAction: 'download', disableOnColumnValue: { column: 'certificateName', values: [null]},
            imageOnColumnValue: { imgURL: 'assets/images/features-unavailable/triangle-exclamation.svg', column: 'certificateId', values: [0] }
          },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    addButtonName: 'Assign Training',
    disableflag: false,
    isLoading: false,
    fetchData: false,
    hidepagination: true,
    displaySearch: false,
  };

  groups$ = this.lookupsQuery.groups$.pipe(
    map((groups) => {
      if (groups.length > 0) {
        return groups;
      } else {
        return this.lookupsStore.groups();
      }
    }),
    switchMap((group) => {
      if (group === undefined) {
        return of(null);
      } else {
        return of(group);
      }
    }),
    take(2)
  );
  status$ = this.lookupsQuery.entityUserStatus$.pipe(
    map((status) => {
      if (status.length > 0) {
        return status;
      } else {
        return this.lookupsStore.entityUserStatus();
      }
    }),
    switchMap((sta) => {
      if (sta === undefined) {
        return of(null);
      } else {
        return of(sta);
      }
    })
  );
  roles$ = this.lookupsQuery.roles$.pipe(
    map((roles) => {
      if (roles.length > 0) {
        return roles;
      } else {
        return this.lookupsStore.roles();
      }
    }),
    switchMap((role) => {
      if (role === undefined) {
        return of(null);
      } else {
        const entityType = window.localStorage.getItem('entityType');
        if (entityType === 'Coop') {
          return of(role.filter((x) => x.label !== 'BetaLearner' && x.label !== 'EntityAdmin'));
        } else {
          return of(role.filter((x) => x.label !== 'BetaLearner' && x.label !== 'CoopAdmin'));
        }
      }
    })
  );

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private editUsersService: EditUsersService,
    public lookupsQuery: LookupsQuery,
    public lookupsStore: LookupsStore,
    public organizationQuery: OrganizationQuery,
    public organizationService: OrganizationService,
    private breakpointObserver: BreakpointObserver,
    private userGroupsService: UserGroupsService,
    private addUsersService: AddUsersService,
    private paymentService: PaymentService,
    private modalService: ModalService,
    public switchOrgQuery: SwitchEntityProfileQuery,
    private entityCoursesService: EntityCoursesService,
  ) {}

  public addUsers: UntypedFormGroup = Object.create(null);

  openDialog(options: {
    mode: 'add' | 'edit' | 'delete';
    componentType: 'reset-password';
    item?: any;
    deleteId?: any;
    itemName?: string;
  }) {
    const { mode, componentType } = options;
    const data: DialogData = { mode, email: this.addUsers?.controls?.email?.value };
    if (componentType === 'reset-password') {
      this.modalService.open(UserResetPasswordComponent, {
        modalType: ModalType.Medium,
        data,
      });
    }
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.smallScreen = !result.matches;
      });
    this.addUsers = this.fb.group({
      firstName: this.fb.control({ value: null, disabled: true }, Validators.required),
      lastName: this.fb.control({ value: null, disabled: true }, Validators.required),
      email: this.fb.control(
        { value: null, disabled: true },
        Validators.compose([Validators.required, Validators.email])
      ),
      status: this.fb.control(null),
      entityGroupIds: this.fb.control(null),
      roles: this.fb.control(null),
    });

    this.activatedRoute.queryParams
      .pipe(
        map((params) => {
          this.userId = params['userId'];
          this.fetchAll();
          return params['userId'];
        }),
        switchMap((params) => {
          return this.editUsersService.getUser(params);
        }),
        tap((data: any) => {
          this.loadDetails(data);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.hasActivityInSubscriptionYear = data.hasActivityInSubscriptionYear;
        this.userName = data.firstName + ' ' + data.lastName;
      });
  }

  loadDetails(data: any) {
    let roleResult: any[] = [];
    let { roles, statusId, coursesAvailableForAssignment, ...remaining } = data;

    roles.forEach((role: any) => {
      roleResult.push(role.id);
    });
    const formValues = {
      roles: roleResult,
      status: statusId,
      ...remaining,
    };
    this.addUsers.patchValue({ ...formValues });
  }

  update() {
    if (!this.addUsers.valid) {
      this.addUsers.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const entityGuid: any = window.localStorage.getItem('entityGuid');

    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          const control = this.addUsers.get(error.field);
          if (control) {
            this.addUsers.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
           this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else if (validationErrors?.error['roleIds']?.length > 0) {
        const control = this.addUsers.get('roles');
        if (control) {
          this.addUsers.markAllAsTouched();
          control.setErrors({
            // message: validationErrors?.error['roleIds'][0],
            message: 'Entity Admin self Inactivation is not allowed',
          });
        }
      } else {
       if(validationErrors.error?.activityInSubscriptionYear.length > 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Cannot be archived at this time. Training activity in current subscription period." }})
       } else {
         this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
       }
      }

      return of(null);
    };
    const { status, entityGroupIds, roles, ...remaining } = this.addUsers.getRawValue();
    const formValues = {
      status: status,
      entityUserId: this.userId,
      groupIds: entityGroupIds,
      entityGuid: entityGuid,
      roleIds: roles,
      ...remaining,
    };

    this.editUsersService
      .update(formValues)
      .pipe(
        catchError((error) => throwError(error)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "User Updated" }})
          this.userGroupsService
            .getAll(entityGuid, { pageNumber: 1, pageSize: 20 })
            .pipe(takeUntil(this.destroy$))
            .subscribe();

          const initialRequestedBody = {
            searchTerm: '',
            groupIds: [],
            fromDate: '',
            toDate: '',
            status: '',
            pageSize: 20,
            pageNumber: 1,
            entityGuid: entityGuid,
          };
          this.addUsersService.getUsers(initialRequestedBody).pipe(takeUntil(this.destroy$)).subscribe();
          this.disableFlag = false;
          this.paymentService.getSubscriptionUsage(entityGuid)?.pipe(takeUntil(this.destroy$)).subscribe();
        },
        (error) => {
          validationErrorHandler(error);
          this.disableFlag = false;
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // initTrainingQuery() {

  //   this.learenerMetricStateQuery
  //     .selectAll()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((entities: any) => {
  //       gridDatasource = [];
  //       if (entities.length > 0) {
  //         const { rows, totalNumberOfItems } = entities[0];
  //         this.gridOptions.isLoading = false;
  //         if (rows?.length > 0) {
  //           rows.forEach((learner: any) => {
  //             const datasource = gridDatasource;
  //             gridDatasource.push(learner);
  //             gridDatasource = datasource;
  //           });
  //         }
  //         this.gridOptions.data.data = gridDatasource;
  //         this.gridOptions.totalNumberOfItems = totalNumberOfItems;
  //       }
  //     });
  // }

  fetchAll() {
    var gridDatasource: any[] = [];
    if (this.userId === undefined || this.userId === null) {
      return;
    }
    this.editUsersService.getUser(this.userId)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
          return throwError(error);
        })).subscribe((result: any) => {
          gridDatasource = [];
          if (result.courseHistory.rows.length > 0) {
            const { rows, totalNumberOfItems } = result.courseHistory
            this.gridOptions.isLoading = false;
            if (rows?.length > 0) {
              rows.forEach((chRow: any) => {
                const datasource = gridDatasource;
                gridDatasource.push(chRow);
                gridDatasource = datasource;
              });
            }
            this.gridOptions.data.data = gridDatasource;
            this.gridOptions.totalNumberOfItems = totalNumberOfItems;
          }
        });
  }

  downloadCert(element: any) {
    this.entityCoursesService.downloadCert(element?.certificateId);
  }

  assignTraining() {
    const data = { 
      entityUserId: this.userId,
      email: this.addUsers?.controls?.email?.value,
      name: this.addUsers?.controls?.firstName?.value + ' ' + this.addUsers?.controls?.lastName?.value
    };
      this.modalService.open(AssignTrainingComponent, {
        modalType: ModalType.FullScreen,
        data,
      },
      (result) => {
        if (result) {
          this.fetchAll();
        }
      });
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      //this.fetchAll();
    } else if (event.action === 'formChanges') {
      //this.fetchAll();
    } else if (event.action === 'clearFilters') {
      //this.fetchAll();
    } else if (event.action === 'addButton') {
      this.assignTraining();
    }
    //  'download' is specific to this component 
    else if (event.action === 'download') {
      if (event.data.certificateId == 0) {
        this.modalService.openOrgCertNotIssued();
      } else {
        this.downloadCert(event.data);
      }
    }
  }
}
