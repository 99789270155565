import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { PaymentService } from 'src/app/api/services/payment/payment.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { JsonReaderService } from 'src/app/shared/services/json-reader.service';

@Component({
  selector: 'app-pay-as-you-go',
  templateUrl: './pay-as-you-go.component.html',
  styleUrls: ['./pay-as-you-go.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
  ],
})
export class PayAsYouGoComponent implements OnInit {
  numberOfCertsString!: string;
  numberOfCerts: number = 50;
  singleCertPrice?: number;
  totalCertPrice?: number;
  buttonIds!: any;
  public form: UntypedFormGroup = Object.create(null);
  
  constructor(
    private fb: UntypedFormBuilder,
    private paymentService: PaymentService,
    private jsonReaderService: JsonReaderService,
  ) {
    this.jsonReaderService.getJSON().subscribe((data: any) => {
      this.buttonIds = data;
    });
   }

  ngOnInit(): void {
    this.form = this.fb.group({
      numberOfCertsString: [this.numberOfCerts  + " Certificate Credits"],
    });

    this.paymentService.getCertPrice().subscribe((price: any) => {
      this.singleCertPrice = price;
      this.totalCertPrice = price * this.numberOfCerts;
    });

    this.form.get('numberOfCerts')?.valueChanges.subscribe(value => {
      this.numberOfCerts = value;
      this.totalCertPrice = this.singleCertPrice ? this.singleCertPrice * this.numberOfCerts : 0;
    });
  }


  decrementCount() {
    if(this.numberOfCerts > 0) {
      this.numberOfCerts--; 
      this.totalCertPrice = this.singleCertPrice! * this.numberOfCerts;
    }

    this.numberOfCertsString = this.numberOfCerts + " Certificate Credits";

    this.form.patchValue({
      numberOfCertsString: this.numberOfCertsString
    });
  }

  incrementCount() {
    this.numberOfCerts++;
    this.totalCertPrice = this.singleCertPrice! * this.numberOfCerts;

    this.numberOfCertsString = this.numberOfCerts + " Certificate Credits";

    console.log(this.numberOfCertsString)

    this.form.patchValue({
      numberOfCertsString: this.numberOfCertsString
    });
  }

  submit() {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    this.paymentService
    .createPayAsYouGoSession(entityGuid, this.numberOfCerts)
    .subscribe((res: any) => {
      window.location.href  = res;
    })
  }

  clear(event: any) {
    event.target.value = '';
  }

  updateNumberOfCerts(event: any) {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[a-zA-Z]/g, '');
    if(inputValue === '') {
      inputValue = 0;
    } 
    this.numberOfCerts  = inputValue; 
    this.totalCertPrice = this.singleCertPrice! * this.numberOfCerts;
    this.numberOfCertsString = inputValue + " Certificate Credits"; 
    this.form.patchValue({
      numberOfCertsString: this.numberOfCertsString
    });
  }

  getBuyNowButtonId() {
    if (!this.buttonIds) {
      return '';
    }

    return this.buttonIds.payAsYouGo;
  }
}
