<div *ngIf="!isMobile; else mobileLayout">
  <div class="flex">
    <mat-checkbox
      [disabled]="disabled"
      [checked]="checked"
      (change)="onCheckboxChange($event)" 
    ></mat-checkbox>
    <div 
      class="pl-3 flex"
      cdkOverlayOrigin
      #iconRef="cdkOverlayOrigin"
      >
      <p class="learner text-color-dark-grey bold flex items-center" [ngClass]="{'disabled': disabled}">{{ course.name }}
        <span class="flex items-center" *ngIf="course?.selectedState !== 'NATL'">&nbsp;| {{ course?.selectedState }}
        <mat-icon
          class="ml-2 relative"
          [class.open]="_infoToolTipOpen"
          (mouseenter)="onTooltipMouseEnter()"
          (mouseleave)="hideTooltip()"
        >info_outline</mat-icon>
        </span>
      </p>
    </div>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="iconRef"
    [cdkConnectedOverlayOpen]="infoToolTipOpen"
    [cdkConnectedOverlayPositions]="toolTipPosition"
    cdkConnectedOverlayPush="true"
    [cdkConnectedOverlayViewportMargin]="25"
  >
  <div class="custom-tooltip-body">
    <div class="custom-tooltip-triangle"></div>
    <div class="flex text-left justify-start items-center" *ngIf="course.isCE">
      <p class="small flex items-center">
        <mat-icon class="tooltip-icon">check_circle</mat-icon>
        <span>CE Approved</span>
      </p>
    </div>
  
    <div>
      <p>
        {{ course.moduleNames.length }} Modules:
        {{ course.moduleNames.join(', ') }}
      </p>
    </div>
  </div>
  </ng-template>
</div>

<!-- Small Screen Layout -->
<ng-template #mobileLayout>
  <div class="flex">
    <mat-checkbox
      [disabled]="disabled"
      [checked]="checked"
      (change)="onCheckboxChange($event)"
    ></mat-checkbox>
    <div class="flex pl-3">
      <p class="learner text-color-dark-grey bold" [ngClass]="{'disabled': disabled}">{{ course.name }}
        <span *ngIf="course?.selectedState !== 'NATL'"> | {{ course?.selectedState }}</span>
      </p>
    </div>
  </div>
<div class="flex flex-col pl-8">
    <div class="flex text-left justify-start items-center w-full" *ngIf="course.isCE">
      <p class="small flex items-center ">
        <mat-icon class="tooltip-icon">check_circle</mat-icon>
        <span>CE Approved</span>
      </p>
    </div>
    
    <div>
      <p class="text-left ">
        {{ course.moduleNames.length }} Modules:
        {{ course.moduleNames.join(', ') }}
      </p>
    </div>
</div>
</ng-template>