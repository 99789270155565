import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class UserStateService extends BaseAPI {
  getNotificationTypes() {
    return this.http.get(`user/notification-types`);
  }

  getProfileImage() {
    return this.http.get(`user/profile-image`);
  }

  userState(body:any){
    return this.http.post(`user/user-state`, body)
  }

  uploadProfileImage(body:any) {
    return this.http.post(`user/upload-image`, body)
  }

  setUsersNotificationPreferences(body: any) {
    return this.http.post(`user/notification-preferences`, body);
  }
  
  removeKeyFromUserState(key: string) {
    return this.http.delete(`user/user-state/${key}`)
  }

}
