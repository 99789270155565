import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class JsonReaderService {

  // json must be in the assets folder
  private jsonURL = 'assets/MRT-button-ids.json';

  constructor(private http: HttpClient) { }

  getJSON(JsonUrl?: string): any {
    if (JsonUrl) {
      this.jsonURL = JsonUrl;
    }
    return this.http.get(this.jsonURL);
  }
}

