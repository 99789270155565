import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-modal-layout',
    templateUrl: './modal-layout.component.html',
    styleUrls: ['./modal-layout.component.scss'],
    standalone: true,
    imports: [
      MatIconModule, 
      MatDialogModule, 
      NgIf,
      NgClass
    ]
})
export class ModalLayoutComponent implements OnInit {
  @Input() disableClose: boolean = false;
  @Input() backgroundColor: boolean = false;

  constructor() { }

  ngOnInit() {}

}
