import { Component, OnInit, OnDestroy } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { LookupsQuery } from 'src/app/api/services/lookups/lookups.query';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { OrganizationQuery } from 'src/app/routes/org/organization/org-profile/state/organization.query';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { AddUserGroupsComponent, DialogData } from '../../../../shared/components/modals/add-user-groups/add-user-groups.component';
import { UserGroupsQuery } from './state/user-groups.query';
import { UserGroupsService } from './state/user-groups.service';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';

@Component({
    selector: 'app-user-groups',
    templateUrl: './user-groups.component.html',
    styleUrls: ['./user-groups.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        MRTGridTemplateComponent,
        SnackbarComponent
    ],
})
export class UserGroupsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  showZeroOverlay!: boolean;
  defaultSortColumn: string = 'groupName';
  defaultSortDirection: string = 'ASCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Course', formControlName: 'courseId', type: 'dropdown', lookup: this.lookupsQuery.courses$ },
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsQuery.entityGroupStatus$ },
    ],
    openFilters: false,
    columns: [
      { header: 'Group Name', controlName: 'name' },
      { header: 'Course', controlName: 'courses', displayPipe: 'joinList' },
      { header: 'Number of Learners', controlName: 'numberOfUsers',
        actionColumnitems: [
          { buttonAction: 'view', text: { calculateText: (numberOfUsers: any) => { return numberOfUsers > 0 ? numberOfUsers : '0'; }, 
          textColumn: 'numberOfUsers' }, disableOnColumnValue: {column: 'numberOfUsers', values: [0]} },
        ]
      },
      { header: 'Status', controlName: 'status' },
      { header: 'Edit', controlName: 'actions',
        actionColumnitems: [
          { icon: 'edit', buttonAction: 'edit' },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    displaySearch: false,
  };

  constructor(
    private userGroupsService: UserGroupsService,
    private userGroupsQuery: UserGroupsQuery,
    public lookupsQuery: LookupsQuery,
    public lookupsStore: LookupsStore,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private router: Router,
    private modalService: ModalService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.lookupsStore.courses();
    this.lookupsStore.entityGroupStatus()
    this.fetchAll();
    this.reloadOrgCourses();

    var gridDatasource: any[] = [];
    this.userGroupsQuery
      .selectAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        gridDatasource = [];
        if (data?.length > 0) {
          const rows = data[0];
          const totalNumberOfItems = data[1];
          const showZeroOverlay = data[2];
          this.showZeroOverlay = showZeroOverlay;
          this.gridOptions.isLoading = false;
          if (!showZeroOverlay) {
            this.gridOptions.addButtonName = 'Add Group';
          }
          rows.forEach((group: any) => {
            const datasource = gridDatasource
            gridDatasource.push(group);
            gridDatasource = datasource;
          });
          this.gridOptions.data.data = gridDatasource;
          this.gridOptions.totalNumberOfItems = totalNumberOfItems;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fetchAll(rawParams?: any) {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
    this.gridOptions.isLoading = true;
    if (!rawParams) {
      rawParams = { pageSize: 20, pageNumber: 1 };
    }

    const { status, search, courseId, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      Status: status ? status : '',
      CourseId: courseId ?? '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };
    this.userGroupsService.getAll(entityGuid, params)
    .pipe(
      takeUntil(this.destroy$),
      catchError(error => {
        this.gridOptions.isLoading = false;
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
        return throwError(error);
      })).subscribe();
  }

  openDialog(options: {
    mode: 'add' | 'edit' | 'delete';
    componentType: 'users-group';
    item?: any;
    deleteId?: any;
    itemName?: string;
  }) {
    const { mode, componentType, item, deleteId, itemName } = options;
    const data: DialogData = { mode, item, deleteId, itemName };
    if (componentType === 'users-group') {
      this.modalService.open(AddUserGroupsComponent, {
        modalType: ModalType.FullScreen,
        data,
      }, () => {
        const entityGuid = window.localStorage.getItem('entityGuid');
        if (!entityGuid) {
          return;
        }
        this.gridOptions.fetchData = true;
        this.lookupsStore.courses();
      });
    }
  }

  goToGroup(row: any) {
    if (row.numberOfUsers > 0) {
      this.router.navigateByUrl(`/team/all-users?groupId=${row?.id}`);
    }
  }

  sortField(sort?: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'name':
          activeSort = 'groupName';
          break;
        case sort.active === 'courses':
          activeSort = 'courseName';
          break;
        case sort.active === 'numberOfUsers':
          activeSort = 'numberOfUsers';
          break;
        case sort.active === 'status':
          activeSort = 'status';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  reloadOrgCourses() {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
    const coursesSub = this.organizationService.getOrganizationCourses(entityGuid);

    this.organizationQuery
      .selectAll()
      .pipe(
        take(1),
        map((data) => {
          return data;
        }),
        switchMap((data) => {
            return coursesSub;
        })
      )
      .subscribe();
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      this.fetchAll(event.data);
    } else if (event.action === 'clearFilters') {
      this.fetchAll();
    } else if (event.action === 'addButton') {
      this.openDialog({ mode: 'add', componentType: 'users-group' });
    }
    // 'edit' 'view' is specific to this component 
    else if (event.action === 'edit') {
      this.openDialog({ mode: 'edit', componentType: 'users-group', item: event.data });
    } else if (event.action === 'view') {
      this.goToGroup(event.data);
    }
  }
}
