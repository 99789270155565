<h1 class="learner pl-4 pt-4">Resources</h1>
<div class="content-container no-data-overlay-parent">
  <div *ngIf="showZeroOverlay" class="no-data-overlay-child">
    <h2>0 Resources</h2>
    <p>You currently have no resources.<br />Start adding them by clicking the button below.</p>
    <a class="btn-light medium cursor-pointer items-center text-center" (click)="this.openDialog({ mode: 'add', componentType: 'resources' })"
      >Add Resources</a
    >
  </div>
  <app-grid-template [gridOptions]="gridOptions" (actionEvent)="actionEvent($event)"></app-grid-template>
</div>