import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({ providedIn: 'root' })
export class LearnerMetricsApiService extends BaseAPI {
  getLearningMetricsReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    return this.http.get(`entityAdminReports/learner-metrics/${entityGuid}`, {
      params: requestParams,
    });
  }
  exportLearningMetricsReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    return this.http.get(`entityAdminReports/learner-metrics/export/${entityGuid}`, {
      params: requestParams,
    });
  }
}
