import { inject, Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { HttpClient } from '@angular/common/http';
import { AdvertisementStore } from './advertisement.store';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementService extends BaseAPI {
  advertisementStore = inject(AdvertisementStore); 
  constructor(
    http: HttpClient,
  ) {
    super(http);
  } 

  getAdvertisements() {
    this.advertisementStore.setLoading(true);
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.get(`/advertisement/${entityGuid}`).pipe(
      tap((adverts: any) => {
        this.advertisementStore.setAll(adverts);
        this.advertisementStore.setLoading(false);
      }),
    );
  }

  getAdvertisementLogin() {
    return this.http.get(`/advertisement/login`);
  }
}
