<div>
    <app-modal-layout>
        <div title> {{ title }} </div>
        <p *ngIf="message1" [ngStyle]="{'margin-top': message1 ? '1rem' : '0'}">{{ message1 }}</p>
        <p *ngIf="message2" [ngStyle]="{'margin-top': message2 ? '1rem' : '0'}">{{ message2 }}</p>
        <div *ngIf="customHtmlMessage" [innerHTML]="customHtmlMessage" [ngStyle]="{'margin-top': customHtmlMessage ? '1rem' : '0'}"></div>
        <div class="text-center mt-4">
            <button class="btn-primary large mr-4" (click)="button1Clicked()" [disabled]="disableFlag">
                {{ button1Text }}
            </button>
            <button class="primary-link" *ngIf="button2Text" [disabled]="disableFlag" (click)="button2Clicked()">
                {{ button2Text }}
            </button>
        </div>
    </app-modal-layout>
    <div *ngIf="boxster" class="modal-image">
        <img src="/assets/images/setup-wizard/boxster-congratulations.svg" alt="" [ngClass]="{ 'active': isActive }">
    </div>
</div>