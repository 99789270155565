import { SubscriptionRequiredComponent } from './shared/components/subscription-required/subscription-required.component';
import { PricingComponent } from './shared/components/pricing/pricing.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './routes/authentication/guards/auth/auth.guard';
import { LearnerOrganizationRouteGuard } from './routes/authentication/guards/conditional-routes/learner-organization-route.guard';
import { ErrorComponent } from './shared/components/error/error.component';
import { AppBlankComponent } from './shared/components/layouts/blank/blank.component';
import { FullComponent } from './shared/components/layouts/full/full.component';
import { AdminRouteGuard } from './routes/admin/guards/admin-route.guard';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { AcceptInvitationConfirmedComponent } from './routes/authentication/components/accept-invitation-confirmed/accept-invitation-confirmed.component';
import { CheckinboxComponent } from './routes/authentication/components/checkinbox/checkinbox.component';
import { EmailConfirmedComponent } from './routes/authentication/components/email-confirmed/email-confirmed.component';
import { LoginComponent } from './routes/authentication/components/login/login.component';
import { LogoutComponent } from './routes/authentication/components/logout/logout.component';
import { RegisterComponent } from './routes/authentication/components/register/individual/register.component';
import { OrgRegisterComponent } from './routes/authentication/components/register/organization/org-register.component';
import { ResetComponent } from './routes/authentication/components/reset/reset.component';
import { WelcomeComponent } from './routes/authentication/components/welcome/welcome.component';
import { ForgotComponent } from './routes/authentication/forgot/forgot.component';
import { InviteRegisterComponent } from './routes/invite/register/register.component';
import { OrganizationComponent } from './routes/org/organization/org-profile/org-profile.component';
import { LearningMetricsComponent } from './routes/org/reports/learning-metrics/learning-metrics.component';
import { TrainingMetricsComponent } from './routes/org/reports/training-metrics/training-metrics.component';
import { UserGroupsComponent } from './routes/org/team/user-groups/user-groups.component';
import { EditUsersComponent } from './routes/org/team/users/edit-users/edit-users.component';
import { UsersComponent } from './routes/org/team/users/users.component';
import { CoursesComponent } from './routes/org/training/courses/courses.component';
import { ResourcesComponent } from './routes/org/training/resources/resources.component';
import { ImportLearnersGridComponent } from './routes/org/team/import-learners/import-learners-grid/import-learners-grid.component';
import { SeoOptions } from './shared/interfaces/seo-options.interface';
import { LearnerCertsComponent } from './routes/learner/certificates/learner-certs/learner-certs.component';
import { OffsiteCertsComponent } from './routes/org/reports/offsite-certs/offsite-certs.component';
import { HistoryComponent } from './routes/learner/history/history/history.component';
import { subscriptionGuard } from './routes/authentication/guards/subscription/subscription.guard';
import { VerifyEmailComponent } from './routes/authentication/components/verify-email/verify-email.component';
import { AccountBillingComponent } from './routes/org/organization/account-billing/overview/account-billing.component';
import { BatchAssignTrainingComponent } from './routes/org/training/batch-assign-training/batch-assign-training.component';
import { SetupWizardComponent } from './routes/org/setup-wizard/setup-wizard.component';
import { PublicLandingComponent } from './routes/org/organization/public-landing/public-landing.component';


const REGISTER_ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'individual' },
  {
    path: 'individual',
    component: RegisterComponent,
    title: 'Register to Take Mandated Reporter Training',
    data: {
      seoOptions: {
        title: 'Register to Take Mandated Reporter Training',
        description:
          'Create a free account for immediate access to online mandated reporter training, and complete your training today. Continuing education credits available!',
        robots: 'index, follow',
        canonicalUrl: 'https://account.mandatedreportertraining.com/register/individual',
        og: {
          title: 'Register to Take Mandated Reporter Training',
          description:
            'Create a free account for immediate access to online mandated reporter training, and complete your training today. Continuing education credits available!',
          image: 'https://mandatedreportertraining.com/images/assets/brand/MandatedReporterTraining-share.png',
          url: 'https://account.mandatedreportertraining.com/register/individual',
          site_name: 'The Mandated Reporter Training Platform',
          type: 'website',
        },
        twitter: {
          title: 'Register to Take Mandated Reporter Training',
          description:
            'Create a free account for immediate access to online mandated reporter training, and complete your training today. Continuing education credits available!',
          url: 'https://account.mandatedreportertraining.com/register/individual',
          card: 'summary_large_image',
          image: 'https://mandatedreportertraining.com/images/assets/brand/MandatedReporterTraining-share.png',
        },
      } as SeoOptions,
    },
  },
  {
    path: 'organization',
    component: OrgRegisterComponent,
    title: 'Register to Start Your Mandated Reporter Training Program',
    data: {
      seoOptions: {
        title: 'Register to Start Your Mandated Reporter Training Program',
        description:
          'Manage your training program with one intuitive platform. Onboard your entire organization easily, no tech experience required. Register to start training.',
        robots: 'index, follow',
        canonicalUrl: 'https://account.mandatedreportertraining.com/register/organization',
        og: {
          title: 'Register to Start Your Mandated Reporter Training Program',
          description:
            'Manage your training program with one intuitive platform. Onboard your entire organization easily, no tech experience required. Register to start training.',
          image: 'https://mandatedreportertraining.com/images/assets/brand/MandatedReporterTraining-share.png',
          url: 'https://account.mandatedreportertraining.com/register/organization',
          site_name: 'MandatedReporterTraining.com',
          type: 'website',
        },
        twitter: {
          title: 'Register to Start Your Mandated Reporter Training Program',
          description:
            'Manage your training program with one intuitive platform. Onboard your entire organization easily, no tech experience required. Register to start training.',
          url: 'https://account.mandatedreportertraining.com/register/organization',
          card: 'summary_large_image',
          image: 'https://mandatedreportertraining.com/images/assets/brand/MandatedReporterTraining-share.png',
        },
      } as SeoOptions,
    },
  },
];

export const AUTH_ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'forgot',
    component: ForgotComponent,
    title: 'Reset Your Password for Mandated Reporter Training',
    data: {
      robots: 'noindex, nofollow',
    },
  },
  {
    path: 'welcome',
    redirectTo: 'cdss',
  },
  {
    path: 'cdss',
    redirectTo: 'login',
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Log In to Access Mandated Reporter Training',
    data: {
      seoOptions: {
        title: 'Log In to Access Mandated Reporter Training',
        description:
          'Log in to your account to access mandated reporter training courses, get training certificates, and earn continuing education credits.',
        robots: 'index, follow',
        canonicalUrl: 'https://account.mandatedreportertraining.com/login',
        og: {
          title: 'Log In to Access Mandated Reporter Training',
          description:
            'Log in to your account to access mandated reporter training courses, get training certificates, and earn continuing education credits.',
          image: 'https://mandatedreportertraining.com/images/assets/brand/MandatedReporterTraining-share.png',
          url: 'https://account.mandatedreportertraining.com/login',
          site_name: 'The Mandated Reporter Training Platform',
          type: 'website',
        },
        twitter: {
          title: 'Log In to Access Mandated Reporter Training',
          description:
            'Log in to your account to access mandated reporter training courses, get training certificates, and earn continuing education credits.',
          url: 'https://account.mandatedreportertraining.com/login',
          card: 'summary_large_image',
          image: 'https://mandatedreportertraining.com/images/assets/brand/MandatedReporterTraining-share.png',
        },
      } as SeoOptions,
    },
  },
  {
    path: 'verify',
    component: CheckinboxComponent,
    data: {
      title: 'setManually',
      seoOptions: {
        setTitleManually: true,
        title: 'Check Your Email for Password Reset Instructions',
        title2: 'Verify Your Email Address to Begin Training',
        robots: 'noindex, nofollow',
      } as SeoOptions,
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'register',
    children: REGISTER_ROUTES,
  },
  {
    path: 'email-confirmed',
    component: EmailConfirmedComponent,
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
  },
  {
    path: 'invite/register',
    component: InviteRegisterComponent,
  },
  {
    path: 'accept-invitation-confirmed',
    component: AcceptInvitationConfirmedComponent,
  },
  {
    path: 'reset',
    component: ResetComponent,
  },
];

const ROUTES: Routes = [
  {
    path: '',
    component: AppBlankComponent,
    loadChildren: () => AUTH_ROUTES,
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'login-as',
        loadComponent: () => import('./routes/admin/components/login-as/login-as.component').then((c) => c.LoginAsComponent),
        canActivate: [AdminRouteGuard],
      },
    ],
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [ngxPermissionsGuard],
        loadChildren: () => LEARNER_ROUTES,
        data: {
          permissions: {
            only: ['Learner'],
            redirectTo: '/dashboard', // if training is gaurded, move onto checking OrgAdmin routes below
          },
        },
      },
      {
        path: '',
        canActivate: [ngxPermissionsGuard],
        loadChildren: () => ORG_ROUTES,
        data: {
          permissions: {
            only: ['OrgAdmin'],
            redirectTo: '/coop',
          },
        },
      },
      {
        path: 'coop',
        canActivate: [ngxPermissionsGuard],
        loadChildren: () => COOP_ROUTES,
        data: {
          permissions: {
            only: ['CoopAdmin'],
            redirectTo: '/training',
          },
        },
      },
      {
        path: 'my-training',
        canActivate: [ngxPermissionsGuard],
        loadComponent: () => import('./routes/take-courses/take-courses.component').then((c) => c.TakeCoursesComponent),
        data: {
          permissions: {
            only: ['Learner', 'OrgAdmin'],
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'my-certificates',
        canActivate: [ngxPermissionsGuard],
        loadComponent: () =>
          import('./routes/learner/certificates/learner-certs/learner-certs.component').then((c) => c.LearnerCertsComponent),
        data: {
          permissions: {
            only: ['Learner', 'OrgAdmin'],
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'certificates/certificate/:id',
        canActivate: [ngxPermissionsGuard],
        loadComponent: () =>
          import('./routes/learner/certificates/learner-certs/certificate/certificate.component').then((c) => c.CertificateComponent),
        data: {
          title: 'Certificates > Certificate of Completion',
          permissions: {
            only: ['Learner', 'OrgAdmin'],
            redirectTo: '/dashboard',
          },
        }
      },
      {
        path: 'my-history',
        canActivate: [ngxPermissionsGuard],
        loadComponent: () =>
          import('./routes/learner/history/history/history.component').then((c) => c.HistoryComponent),
        data: {
          permissions: {
            only: ['Learner', 'OrgAdmin'],
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'groups',
        component: UserGroupsComponent,
      },
      {
        path: 'learners',
        component: UsersComponent,
      },
      {
        path: 'trainings',
        component: CoursesComponent,
      },
      {
        path: 'profile',
        loadComponent: () => import('./routes/profile/profile/profile.component').then((c) => c.ProfileComponent),
      },
      {
        path: 'privacy-request',
        loadComponent: () =>
          import('./routes/privacy-request/privacy-request.component').then((c) => c.PrivacyRequestComponent),
      },
      {
        path: 'subscribe',
        loadComponent: () =>
          import('./shared/components/subscription-required/subscription-required.component').then(
            (c) => c.SubscriptionRequiredComponent,
          ),
      },
    ],
  },
  {
    path: 'join-org',
    loadComponent: () => import('./routes/invite/join-org/join-org.component').then((c) => c.JoinOrgComponent),
  },
  {
    path: 'authentication/login',
    redirectTo: 'login',
  },
  {
    path: 'authentication/register',
    redirectTo: 'register',
  },
  {
    path: 'authentication/invite/register',
    redirectTo: 'invite/register',
  },
  {
    path: 'authentication/accept-invitation-confirmed',
    redirectTo: 'accept-invitation-confirmed',
  },
  {
    path: 'authentication/accept-invitaion-confirmed',
    redirectTo: 'accept-invitation-confirmed',
  },
  {
    path: 'authentication/welcome',
    redirectTo: 'welcome',
  },
  {
    path: 'authentication/cdss',
    redirectTo: 'cdss',
  },
  {
    path: 'authentication/checkinbox',
    redirectTo: 'verify',
  },
  {
    path: 'team/groups',
    pathMatch: 'full',
    redirectTo: 'groups',
  },
  {
    path: 'user-invites',
    pathMatch: 'full',
    redirectTo: 'learners',
  },
  {
    path: 'team/all-users',
    pathMatch: 'full',
    redirectTo: 'learners',
  },
  {
    path: 'trainings/trainings',
    pathMatch: 'full',
    redirectTo: 'trainings',
  },
  {
    path: 'trainings/assign',
    pathMatch: 'full',
    redirectTo: 'premium/assign',
  },
  {
    path: 'team/user-import',
    pathMatch: 'full',
    redirectTo: 'premium/import-learners',
  },
  {
    path: 'training',
    pathMatch: 'full',
    redirectTo: 'my-training',
  },
  {
    path: 'certificates',
    pathMatch: 'full',
    redirectTo: 'my-certificates',
  },
  {
    path: 'history',
    pathMatch: 'full',
    redirectTo: 'my-history',
  },
  {
    path: 'sitemap.xml',
    redirectTo: 'sitemap.xml',
  },
  {
    path: 'robots.txt',
    redirectTo: 'robots.txt',
  },
  {
    path: '404',
    component: ErrorComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

const ORG_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadComponent: () =>
       import('./routes/org/dashboard/dashboard/dashboard.component').then((c) => c.DashboardComponent),
  },
  {
    path: 'premium',
    loadChildren: () => PREMIUM_ROUTES,
  },
  {
    path: 'team',
    loadChildren: () => TEAM_ROUTES,
  },
  {
    path: 'organization',
    loadChildren: () => ORGANIZATION_ROUTES,
  },
  {
    path: 'reports',
    loadChildren: () => REPORTS_ROUTES,
  },
];

const COOP_ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'dashboard',
    loadComponent: () => import('./routes/coop/dashboard/dashboard.component').then((c) => c.DashboardComponent),
  },
  {
    path: 'profile',
    loadComponent: () =>
      import('./routes/org/organization/org-profile/org-profile.component').then((c) => c.OrganizationComponent),
  },
  {
    path: 'trainings',
    loadComponent: () => import('./routes/org/training/courses/courses.component').then((c) => c.CoursesComponent),
  },
  {
    path: 'certificates',
    loadComponent: () => import('./routes/coop/certificates/coop-certs.component').then((c) => c.CoopCertsComponent),
  },
  {
    path: 'team',
    loadComponent: () => import('./routes/org/team/users/users.component').then((c) => c.UsersComponent),
  },
  {
    path: 'edit-user',
    component: EditUsersComponent,
  },
];

const LEARNER_ROUTES: Routes = [
  {
    path: 'org-details',
    canActivate: [LearnerOrganizationRouteGuard],
    loadComponent: () =>
      import('./routes/learner/learner-organization/learner-organization/learner-organization.component').then(
        (c) => c.LearnerOrganizationComponent,
      ),
  },
];

const PREMIUM_ROUTES: Routes = [
  {
    path: 'assign',
    component: BatchAssignTrainingComponent,
    canActivate: [subscriptionGuard],
  },
  
  {
    path: 'import-learners',
    component: ImportLearnersGridComponent,
    canActivate: [subscriptionGuard],

  },

  {
    path: 'join-org',
    component: PublicLandingComponent,
    canActivate: [subscriptionGuard],
  },
];

const TEAM_ROUTES: Routes = [
  {
    path: 'edit-users',
    canActivate: [subscriptionGuard],
    component: EditUsersComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/team/import-learners',
  },
];

const REPORTS_ROUTES: Routes = [
  {
    path: 'learner-reports',
    canActivate: [subscriptionGuard],
    component: LearningMetricsComponent,
  },
  {
    path: 'training-reports',
    canActivate: [subscriptionGuard],
    component: TrainingMetricsComponent,
  },
 {
     path: 'offsite-certs',
    canActivate: [ngxPermissionsGuard],
     data: {
      permissions: {
        only: [ 'OrgAdmin'],
        redirectTo: '/dashboard',
      },
    },
     component: OffsiteCertsComponent,
    
  },
  {
    path: 'learner-metrics',
    pathMatch: 'full',
    redirectTo: 'learner-reports',
  },
  {
    path: 'training-metrics',
    pathMatch: 'full',
    redirectTo: 'reports/training-reports',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'learner-reports',
  },
];

const ORGANIZATION_ROUTES: Routes = [
  {
    path: 'profile',
    component: OrganizationComponent,
  },
  {
    path: 'account-billing',
    component: AccountBillingComponent,
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [subscriptionGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/organization/profile',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
