<div  *ngFor="let menuitem of sideMenuitems$ | async; let i = index" class="nav-link-wrapper">
  <ng-container *ngIf="menuitem?.type == '' else submenu">
    <a
    class="flex items-center w-full sidenav-link"
    appAccordionToggle
    *ngIf="menuitem?.type === ''"
    [routerLink]="menuitem?.state"
    routerLinkActive="selected"
  >
    <mat-icon>{{ menuitem?.icon }}</mat-icon>
    <span class="pl-2">{{ menuitem?.name }}</span>
    <span class="flex-1"></span>
    <span class="label label-{{ badge?.type }}" *ngFor="let badge of menuitem?.badge">{{ badge?.value }}</span>
    </a>
  </ng-container>

   <ng-template #submenu>
    <mat-accordion>
      <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="mr-sm">{{ menuitem?.icon }}</mat-icon>
            <span>{{ menuitem?.name }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>


        <div *ngIf="menuitem?.type === 'sub'" class="nested-menu sidenav-dropdown">
          <a
            *ngFor="let childitem of menuitem.children"
            [routerLink]="menuitem?.state ? ['/', menuitem?.state, childitem?.state] : ['/', childitem?.state]"
            class="nested-link block"
            routerLinkActive="selected"
            (click)="scrollToTop();"
          >
            {{ childitem?.name }}
          </a>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-template> 
</div>
