<h1 class="learner pl-4 pt-4">Learners</h1>
<div class="content-container no-data-overlay-parent">
  <div *ngIf="showZeroOverlay && switchEntityProfileQuery.entityType$.value === 'Coop'" class="no-data-overlay-child"> 
    <h2>0 Learners</h2>
    <p>You currently have no associated learners.<br>Start adding them by clicking the button below.
    </p>
    <a
    class="btn-light medium cursor-pointer items-center text-center"
    (click)="openCoopAssociationModal()"
  >Invite Learners</a>
  </div>
  <mrt-grid-template [gridOptions]="gridOptions" (actionEvent)="actionEvent($event)"></mrt-grid-template>
</div>
